import { ColorBlueBrand } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useEntitlement, useFeatures } from '../../hooks/useEntitlements'
import { Avatar, Icon } from '../../local-core-ui'
import { RootState, useAppSelector } from '../../store'
import styles from './user-menu.module.scss'

export interface UserMenuProps {
	onSignOut(): void
	testId: string
}

export const UserMenu = ({ onSignOut, testId }: UserMenuProps): ReactElement => {
	const { t } = useTranslation()
	const [showMenu, setShowMenu] = useState(false)
	const menuRef = useRef(null)
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const history = useHistory()
	const showManageUser = useEntitlement('PLS_Users', ['MayEdit'])
	const showRumUsage = session.user?.AccessLevel.includes('ADMIN')
	const currentTenantArray = session.tenants?.find((tenant) => tenant.Identifier === session.tenant)
	const validAccessLevelsToSettings = ['INTERNAL_ADMIN', 'ADMIN', 'SUPER_ADMIN', 'EXTERNAL_ADMIN']
	const userAccessLevel = session.user?.AccessLevel
	const showSettings = validAccessLevelsToSettings.includes(userAccessLevel)
	const EnableInternationalContacts = useFeatures(['InternationalContacts'])

	const onMouseDown = (e: MouseEvent) => {
		if (menuRef.current && !e.composedPath().find((pathElement) => pathElement === menuRef.current)) {
			setShowMenu(false)
			document.removeEventListener('mousedown', onMouseDown)
		}
	}

	const onMenuClick = () => {
		setShowMenu(true)
		document.addEventListener('mousedown', onMouseDown)
	}

	useEffect(() => {
		return () => {
			document.removeEventListener('mousedown', onMouseDown)
		}
		/**
		 * We only want to run this effect once, which is why we are leaving the dependency array.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={styles.userMenuContainer}>
			<Avatar
				onClick={onMenuClick}
				userData={[{ label: 'Name', value: session.user?.DisplayName || '' }]}
				testId={testId + '-um-avatar'}
			/>
			{showMenu ? (
				<span className={styles.userMenu} ref={menuRef}>
					<div className={styles.content}>
						<div className={styles.name}>{session.user?.DisplayName}</div>
						<div className={styles.tenant}>{currentTenantArray?.DisplayName}</div>
					</div>
					<div className={styles.bottom}>
						{showSettings && (
							<div
								className={styles.optionMenu}
								onClick={() => {
									history.push('/settings')
								}}
								data-testid="settings"
							>
								{t('text.option.settings')}
							</div>
						)}
						{showManageUser && (
							<div
								className={styles.optionMenu}
								onClick={() => {
									history.push('/all-users')
								}}
								data-testid="manage-users"
							>
								{t('text.option.manageUsers')}
							</div>
						)}
						{showRumUsage && (
							<div
								className={styles.optionMenu}
								onClick={() => {
									history.push('/rum-usage')
								}}
								data-testid="rum-usage"
							>
								{t('text.option.rumUsage')}
							</div>
						)}
						{
							<div
								className={styles.optionMenu}
								onClick={() => {
									history.push('/my-datablocks')
								}}
								data-testid="my-datablocks"
							>
								{t('text.option.viewMyDataBlocks')}
							</div>
						}
						{EnableInternationalContacts && (
							<div
								className={styles.optionMenu}
								onClick={() => {
									history.push('/compliance-insights')
								}}
								data-testid="compliance-insights"
							>
								{'D&B Compliance Insights'}
							</div>
						)}
						<div className={styles.signOutButton} onClick={onSignOut} data-testid={testId + '-signout-btn'}>
							<div>
								<Icon
									testId="chevron-left-user-menu"
									type="chevron-left"
									size="mini"
									color={ColorBlueBrand}
								/>
							</div>
							{t('signout')}
						</div>
					</div>
				</span>
			) : (
				''
			)}
		</div>
	)
}
