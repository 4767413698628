import { ColorSlate } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { convertTo3CountryCode } from '../../helpers'
import { CountrySelectWithMap } from '../../local-core-ui'
import { MatchRule, UserDetail } from '../../types'
import { TemplateTileContainer } from '../template-tile-container/template-tile-container'
import styles from './match-template-tile.module.scss'

interface MatchTemplateTileProps {
	templateId: string
	name: string
	createdBy: UserDetail
	date: number
	matchRules: MatchRule[]
	isOwnerOrAdmin?: boolean
	testId: string
	flipped?: boolean
	functionApplyTemplate(templateId: string): void
	functionPreviewTemplate(templateId: string): void
	functionRemoveTemplate?(templateId: string, name: string): void
}

export const MatchTemplateTile = ({
	templateId,
	name,
	createdBy,
	date,
	matchRules,
	isOwnerOrAdmin,
	testId,
	flipped,
	functionApplyTemplate,
	functionPreviewTemplate,
	functionRemoveTemplate
}: MatchTemplateTileProps): ReactElement => {
	const { t } = useTranslation()
	const numberOfRules = matchRules.length
	const numRulesMinusBase = numberOfRules - 1
	const isBaseRule = numberOfRules === 1

	const mapSelectedCountries = (): string[] => {
		return matchRules?.reduce((countriesAccumulator, matchRule) => {
			const ruleCountries: string[] = matchRule.allowedValues || []
			return [...countriesAccumulator, ...ruleCountries]
		}, [] as string[])
	}

	return (
		<TemplateTileContainer
			name={name}
			createdBy={createdBy}
			date={date}
			canRemove={isOwnerOrAdmin}
			testId={testId}
			templateId={templateId}
			functionApplyTemplate={functionApplyTemplate}
			functionPreviewTemplate={functionPreviewTemplate}
			functionRemoveTemplate={functionRemoveTemplate}
			textPreview={t('match.template.tile.preview')}
			textRemove={t('match.template.tile.remove')}
			flipped={flipped}
		>
			{isBaseRule ? (
				<div className={styles.matchTemplateTileDetailContainer}>
					<div className={styles.matchTemplateTileConfidenceCodeContainer}>
						<div className={styles.matchTemplateTileConfidenceBar} />
						<span className={styles.matchTemplateTileConfidenceCode}>
							{matchRules[0].acceptCriterion?.LowestConfidenceCode || 0}
						</span>
					</div>
					<span className={styles.matchTemplateTileRuleTxt}>{t('match.template.tile.base.rule')}</span>
				</div>
			) : (
				<div className={styles.matchTemplateTileDetailContainer}>
					<div className={styles.matchTemplateTileMapContainer}>
						<CountrySelectWithMap
							readOnly
							width={140}
							value={convertTo3CountryCode(mapSelectedCountries())}
							translationFunction={t}
							testId={testId + '-cswm'}
							color={ColorSlate}
						/>
					</div>
					<span
						className={styles.matchTemplateTileRuleTxt}
						title={
							t('match.template.tile.advanced') +
							' ' +
							t('match.template.tile.plus.rules', { numberOfRules: numRulesMinusBase })
						}
					>
						{t('match.template.tile.advanced')}
						<br />
						<span className={styles.matchTemplateTileRuleTxtItalic}>
							{t('match.template.tile.plus.rules', { numberOfRules: numRulesMinusBase })}
						</span>
					</span>
				</div>
			)}
		</TemplateTileContainer>
	)
}
