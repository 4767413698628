import { AxiosInstance } from 'axios'
import {
	flattenElements,
	getCurrentEntitlement,
	getNotEntitledElements,
	mappingElementsToStructureForBlock,
	requiredFamilyTreeElements
} from '../../helpers'
import { Element, PurposeOfUse } from '../../types'
import { getDataBlocksElements } from './getDataBlocksElements'
import { getDataBlocksEntitlements } from './getDataBlocksEntitlements'

export interface RequiredElementsForFamilyTree {
	entitled: Array<Element>
	notEntitled: Array<Element>
}

export const getRequiredElementsForFamilyTree = async (
	apiClient: AxiosInstance,
	purposeOfUse: PurposeOfUse
): Promise<RequiredElementsForFamilyTree> => {
	const dataBlockElements = await getDataBlocksElements(apiClient)
	const dataBlockEntitlements = await getDataBlocksEntitlements(apiClient, undefined, undefined, true)
	const elementDetailList = getCurrentEntitlement(purposeOfUse, dataBlockEntitlements)
	const dataBlocks = mappingElementsToStructureForBlock(elementDetailList, requiredFamilyTreeElements)
	const entitled = flattenElements(dataBlocks)

	const notEntitledElements = getNotEntitledElements(dataBlocks, requiredFamilyTreeElements)
	const notEntitled = flattenElements(mappingElementsToStructureForBlock(dataBlockElements, notEntitledElements))

	return { entitled, notEntitled }
}
