import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../components/report-table/report-table'
import { ReportTile } from '../../../components/report-tile/report-tile'
import { PieChart } from '../../../local-core-ui'
import { DataReport, MatchRule } from '../../../types'
import styles from '../report.module.scss'
// TODO: Switch to color tokens from UUX design-tokens
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportDonutChart } from '../../../components/report-half-donut-chart/report-donut-chart'
import { ReportSnackbar } from '../../../components/report-snackbar/report-snackbar'
import { abbreviateNumber } from '../../../helpers'
import { BaseReportSection } from '../types/BaseReportSection'
import { EnrichedDataList } from '../types/EnrichedDataList'
import { chartColorsMap } from './chartColorsMap'

interface ParallelPassMatchingProps extends BaseReportSection {
	matchRules: MatchRule[]
}

type SummaryCodeList = DataReport['matchSummaryCodeReport']['summaryCodeList']
type StewardshipValueList = DataReport['stewardshipValueReport']['stewardshipValueList']

type Profiles = {
	precedence: string
	stewardship: string
}

const NO_MATCH_CODE = 'No Match'
const DEFAULT_PROFILE = 'Standard'

const stewardshipColorMap = new Map([
	['Accept', chartColorsMap.get('category-1')],
	['Review', chartColorsMap.get('category-2')],
	['Reject', chartColorsMap.get('category-3')],
	[NO_MATCH_CODE, chartColorsMap.get('uncategory-1')]
])

const ParallelPassMatching = ({ uploadReport, matchRules }: ParallelPassMatchingProps) => {
	const { t } = useTranslation()
	const [profiles, setProfiles] = useState<Profiles>({
		precedence: DEFAULT_PROFILE,
		stewardship: DEFAULT_PROFILE
	})

	const matchSummaryCodeReport = uploadReport?.matchSummaryCodeReport ?? null
	const matchedCount = matchSummaryCodeReport ? matchSummaryCodeReport.total : 0
	const precision = matchedCount > 1000 ? 1 : 0
	const abbreviateMatchedCount = abbreviateNumber(matchedCount, precision)

	const rowHeight = '2rem'
	let summaryCodeList: SummaryCodeList = []

	useEffect(() => {
		if (matchRules.length > 0) {
			setProfiles({
				precedence: matchRules[0].precedenceProfile ?? DEFAULT_PROFILE,
				stewardship: matchRules[0].stewardshipProfile ?? DEFAULT_PROFILE
			})
		}
	}, [matchRules])

	if (uploadReport && uploadReport.matchSummaryCodeReport) {
		summaryCodeList = uploadReport.matchSummaryCodeReport.summaryCodeList
	}

	const displaySecondTable = summaryCodeList.length > 10

	const summaryColumns: ColDef[] = [
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column1') as string,
			field: 'code'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column2') as string,
			field: 'count',
			align: displaySecondTable ? 'right' : 'left'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column3') as string,
			field: 'rate',
			width: displaySecondTable ? 54 : 160,
			align: displaySecondTable ? 'right' : 'left'
		}
	]

	const sortedSummaryCodeList: EnrichedDataList[] = [...summaryCodeList]
		.sort((a, b) => b.rate - a.rate)
		.map(({ code, rate, count }, idx) => {
			let codeColor = chartColorsMap.get(`category-${idx + 1}`) ?? ''

			if (code === 'DUN') {
				codeColor = chartColorsMap.get('success') ?? ''
			}

			if (code === 'UNC') {
				codeColor = chartColorsMap.get('uncategory-1') ?? ''
			}

			if (code === NO_MATCH_CODE) {
				codeColor = chartColorsMap.get('uncategory-3') ?? ''
			}

			return {
				field: code,
				color: codeColor,
				count,
				rate: Math.round(rate)
			}
		})

	const summaryCodeListDataset1 = sortedSummaryCodeList.slice(0, 10)
	const summaryCodeListDataset2 = sortedSummaryCodeList.slice(10, 20)

	const getSummaryRows = (matchSummaryData: EnrichedDataList[]): RowDef[] => {
		let rows: RowDef[] = []

		if (matchSummaryData) {
			rows = matchSummaryData.map(({ field, count, rate, color }, idx) => [
				<ChartKey key={`key-${idx}`} label={field} color={color} />,
				new Intl.NumberFormat().format(count),
				<span key={`key-${idx}`} className={styles.boldText}>
					{rate + '%'}
				</span>
			])
		}

		return rows
	}

	const stewardshipCountColumns: ColDef[] = [
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column1') as string,
			field: 'stewardship'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.parallelPassMatching.tile.stewardshipCount.table.column3') as string,
			field: 'percentage',
			width: 160
		}
	]

	let stewardshipValueList: StewardshipValueList = []

	if (uploadReport && uploadReport?.stewardshipValueReport) {
		stewardshipValueList = uploadReport.stewardshipValueReport.stewardshipValueList
	}

	const stewardshipValueChartData = stewardshipValueList
		.sort((a, b) => b.rate - a.rate)
		.map((item) => ({
			label: item.value,
			value: item.count,
			rate: item.rate
		}))
		.reduce(
			(data, { label, value }) => ({
				...data,
				[label]: value
			}),
			{}
		)

	const stewardshipValueChartColors = stewardshipValueList.reduce((data, { value }) => {
		const label = value ? t('label.graph.' + value) : ''
		return {
			...data,
			[label]: stewardshipColorMap.get(value)
		}
	}, {})

	const getStewardshipCountColumns = (): RowDef[] => {
		let rows: RowDef[] = []

		if (stewardshipValueList) {
			rows = stewardshipValueList.map(({ value, count, rate }, idx) => [
				<ChartKey key={`key-${idx}`} label={value} color={stewardshipColorMap.get(value) as string} />,
				new Intl.NumberFormat().format(count),
				<span key={`key-${idx}`} className={styles.boldText}>
					{rate + '%'}
				</span>
			])
		}

		return rows
	}

	return (
		<ReportTile
			header={t('report.page.parallelPassMatching.header') as string}
			className={styles.parallelPassMatching}
		>
			<>
				<ReportTile
					title={t('report.page.parallelPassMatching.tile.matchSummaryCodeCount.title') as string}
					subtitle={
						t('report.page.parallelPassMatching.tile.matchSummaryCodeCount.subtitle', {
							precedenceProfile: profiles.precedence
						}) as string
					}
				>
					{summaryCodeList.length > 0 ? (
						<div className={styles.matchSummaryCountContainer}>
							<ReportDonutChart
								data={sortedSummaryCodeList}
								size={240}
								statLabel={
									t(
										'report.page.parallelPassMatching.tile.matchSummaryCodeCount.chart.stat'
									) as string
								}
								statValue={abbreviateMatchedCount}
							/>
							<ReportTableWrapper>
								<div className={styles.tablesContainer}>
									<ReportTable
										rowHeight={rowHeight}
										columns={summaryColumns}
										rows={getSummaryRows(summaryCodeListDataset1)}
										hideBorders
										hideHeader={displaySecondTable}
									/>
									{displaySecondTable && (
										<ReportTable
											rowHeight={rowHeight}
											columns={summaryColumns}
											rows={getSummaryRows(summaryCodeListDataset2)}
											hideBorders
											hideHeader
										/>
									)}
								</div>
							</ReportTableWrapper>
						</div>
					) : (
						<ReportSnackbar />
					)}
				</ReportTile>
				<ReportTile
					title={t('report.page.parallelPassMatching.tile.stewardshipCount.title') as string}
					subtitle={
						t('report.page.parallelPassMatching.tile.stewardshipCount.subtitle', {
							stewardshipProfile: profiles.stewardship
						}) as string
					}
				>
					{stewardshipValueList.length > 0 ? (
						<div className={styles.stewardshipCountContainer}>
							<div className={styles.pieChartContainer}>
								<PieChart
									diameter={225}
									colors={stewardshipValueChartColors}
									data={stewardshipValueChartData}
									describeWith={'none'}
									showKeys={false}
									showStrokes
								/>
							</div>

							<ReportTableWrapper>
								<ReportTable
									rowHeight={rowHeight}
									columns={stewardshipCountColumns}
									rows={getStewardshipCountColumns()}
									hideBorders
								/>
							</ReportTableWrapper>
						</div>
					) : (
						<ReportSnackbar />
					)}
				</ReportTile>
			</>
		</ReportTile>
	)
}

export { ParallelPassMatching }
