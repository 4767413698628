import { UploadDiagnostics } from './UploadDiagnostics'

export type UploadStatus =
	| 'NEW'
	| 'IMPORT_STARTED'
	| 'IMPORT_FINISHED'
	| 'MATCH_STARTED'
	| 'MATCH_FINISHED'
	| 'FINISHED'
	| 'ERROR'
	| 'CLEANED_UP'

export interface UploadStats {
	import: {
		failedIngested: number
		submitted: number
		successfullyIngested: number
		received?: number
		processed?: number
	}
	match?: {
		matched: number
		pendingReviewCnt: number
		unmatched: number
	}
	refresh?: {
		enriched: number
		failed: number
		submitted: number
	}
	enrichOnly?: {
		enriched: number
		failed: number
		submitted: number
	}
}

export interface Upload {
	uploadId: string
	displayName: string
	sourceId: string
	status: UploadStatus
	uploadConfig?: {
		dropFilePath?: string
		uploadTsPrefix?: string
		uploadRawFilePath?: string
		uploadMatchResultPrefix?: string
		sourceOnHdfs?: boolean
		usageReportFilePath?: string
		downloadableFiles?: Array<string>
		suppressKnownMatchErrors?: boolean
		initialUploadId?: string
	}
	uploadDiagnostics: UploadDiagnostics
	uploadStats: UploadStats
	createdBy: string
	dropFileTime: number
	progressPercentage: number
	uploadCreatedTime: number
	uploadConfiguration?: {
		isApiAggregation?: boolean
		aggregationFrom?: number
		aggregationTo?: number
	}
	isApiAggregation?: boolean
	readyToSteward?: boolean
	type: UploadType
	expires: number
}

export enum UploadType {
	REFRESH = 'REFRESH',
	IMPORT = 'IMPORT',
	UPLOAD_REFRESH = 'UPLOAD_REFRESH',
	ENRICH_ONLY = 'ENRICH_ONLY',
	PP_MATCH_ENRICH = 'PP_MATCH_ENRICH'
}
