import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { DNBButton, DNBInputLabel, DNBSelect, DNBSelectOption, DNBSnackbar } from '@dnb-uux-design-system/react'
import { isEmpty } from 'lodash-es'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { C4SFailConnectionModal } from '../../../../../components/c4s-fail-connection-modal/c4s-fail-connection-modal'
import { storageEventListener } from '../../../../../components/new-connection-modal/new-connection-modal'
import {
	NewC4SConnectionModal,
	SalesforceEnvironment
} from '../../../../../components/salesforce-modal/salesforce-modal'
import { Snackbar, SnackType } from '../../../../../components/snackbar/snackbar'
import { Spinner } from '../../../../../components/spinner/spinner'
import { UploadCRMSourceTile } from '../../../../../components/upload-crm-source-tile/upload-crm-source-tile'
import { useAccessLevel, useFeatures, usePlatform } from '../../../../../hooks/useEntitlements'
import { useOrgId } from '../../../../../hooks/useOrgId'
import { usePlatformName } from '../../../../../hooks/usePlatformName'
import { Button } from '../../../../../local-core-ui'
import { ConnectionStatus, CreateQueryStatus } from '../../../../../queries/api/getC4SActiveConnection'
import { Connection, System } from '../../../../../queries/api/getConnections'
import { useConnections } from '../../../../../queries/useConnections'
import { useConnectionsStatus } from '../../../../../queries/useConnectionsStatus'
import {
	useCrmAvailableSourceTypes,
	useMutationCrmAvailableSourceTypes
} from '../../../../../queries/useCrmAvailableSourceTypes'
import { authenticateAgainst3rdParty, RootState, useAppDispatch, useAppSelector } from '../../../../../store'
import {
	clearAuthOrigin,
	clearCreateQueryStatus,
	setAuthOrigin,
	setCurrentConnectionStatus
} from '../../../../../store/connection/connectionSlice'
import { updateCurrentProjectAction } from '../../../../../store/projectWizard/actions'
import { CRMSourceType } from '../../../../../store/projectWizard/types'
import { AuthOrigins } from '../../../../../store/session/types/AuthOrigins'
import { CrmSource } from './crm-source'
import styles from './select-crm-source.module.scss'

export const SelectCrmSource: FC = () => {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const selectConnection = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnection)
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const connectionsQuery = useConnections(enableC4SAuthMgmt, connection.createQueryStatus)
	const [connectionNames, setConnectionNames] = useState<string[]>([])
	const connectionsStatusQuery = useConnectionsStatus(connectionNames)
	const getCRMSourceTypesMutation = useMutationCrmAvailableSourceTypes()
	const { TokenSizing } = designTokens
	const matchType = projectWizardState.currentProject.source.entityType?.toLowerCase() || 'accounts'
	const sourceTypeOptions: Array<CRMSourceType> =
		matchType === 'accounts'
			? [CRMSourceType.account, CRMSourceType.lead]
			: [CRMSourceType.contact, CRMSourceType.lead]
	const [crmSourceOptions, setCrmSourceOptions] = useState<Array<CrmSource>>([])
	const [isAvailableSources, setIsAvailableSources] = useState<boolean>()
	const history = useHistory()
	const disabled =
		projectWizardState.currentProject.source.id !== undefined ||
		projectWizardState.currentProject.fileInfo?.uploadInfo !== undefined
	const isAdmin = useAccessLevel(['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'])
	const disableC4SOAuth = useFeatures(['DisableC4SOAuth'])
	const enableManagedConnectedApp = useFeatures(['C4SEnableManagedConnectedApp'])
	const orgId = useOrgId()
	const platformName = usePlatformName()
	const isSalesforce = usePlatform('salesforce')
	const dispatch = useAppDispatch()
	const [selectedSourceType, setSelectedSourceType] = useState(
		projectWizardState.currentProject.thirdPartyIntegration?.crmSource || CRMSourceType.account
	)
	const [showButtonContainer, setShowButtonContainer] = useState(false)
	const [showNewC4SConnectionModal, setShowNewC4SConnectionModal] = useState(false)
	const [failC4SConnectionModal, setFailC4SConnectionModal] = useState(false)
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)
	const [errors, setErrors] = useState<string[]>()
	const [selectedConnectionName, setSelectedConnectionName] = useState('')
	const [salesforceConnections, setSalesforceConnections] = useState<Connection[]>([])
	const [connectionOptions, setConnectionOptions] = useState<Connection[]>([])
	const [loadingSources, setLoadingSources] = useState(false)
	const [noConnections, setNoConnections] = useState(false)

	const onSelectedSourceChanged = (selectedSource: CrmSource) => {
		setSelectedSourceType(selectedSource.type)
		dispatch(updateCurrentProjectAction({ thirdPartyIntegration: { crmSource: selectedSource.type } }))
	}

	const onGoToProjects = () => {
		history.push(`/dashboard`)
	}
	const onGoToHealthDashboard = () => {
		history.push(`/dashboard/overview`)
	}

	const availableCrmSourceTypes = useCrmAvailableSourceTypes(
		platformName || '',
		orgId || '',
		projectWizardState.currentProject.source.id || '',
		matchType,
		!enableC4SAuthMgmt
	)

	const identifyAPISourceTypes = (availableSources: Array<string>) => {
		//API response can have 'Accounts', 'Leads', both or neither
		return availableSources.map((apiType) => apiType.toLowerCase().substring(0, apiType.length - 1))
	}

	const findFirstAvailable = (sourceOptions: Array<CrmSource>): CRMSourceType | undefined => {
		const firstAvailable = sourceOptions.find((opt) => opt.available)
		return firstAvailable?.type
	}

	const getSources = (availableCrmSourceTypes: Array<string>): [Array<CrmSource>, CRMSourceType | undefined] => {
		const availableSourceTypes = identifyAPISourceTypes(availableCrmSourceTypes)

		const newSourceOptions: Array<CrmSource> = sourceTypeOptions.map((type) => {
			const available = !!availableSourceTypes.find((availableType) => availableType === type)
			return {
				type: type,
				available: available,
				onlyThis: false
			} as CrmSource
		})
		//Order by available first
		newSourceOptions.sort((a: CrmSource, b: CrmSource) => {
			return Number(b.available) - Number(a.available)
		})

		let currentlySelectedCrmSource: CRMSourceType | undefined =
			projectWizardState.currentProject.thirdPartyIntegration?.crmSource

		if (!disabled) {
			// If we are disabled that means we should show whatever was selected and is being returned by the BE, if not, apply the logic
			if (currentlySelectedCrmSource) {
				const currentlySelectedOption = newSourceOptions.find((opt) => opt.type === currentlySelectedCrmSource)
				if (!currentlySelectedOption?.available) {
					currentlySelectedCrmSource = findFirstAvailable(newSourceOptions)
				}
			} else {
				currentlySelectedCrmSource = findFirstAvailable(newSourceOptions)
			}
		}
		return [newSourceOptions, currentlySelectedCrmSource]
	}

	const openSalesforceAuthenticator = (environment: SalesforceEnvironment) => {
		dispatch(setAuthOrigin(AuthOrigins.Wizard))
		dispatch(
			authenticateAgainst3rdParty(
				'',
				disableC4SOAuth,
				enableManagedConnectedApp,
				enableC4SAuthMgmt,
				'',
				environment
			)
		)
		setShowNewC4SConnectionModal(false)
	}

	const getC4SConnections = (connections: Connection[]): Connection[] => {
		if (isSalesforce) {
			const currentConnection = connections.filter((connection) => connection.displayName === orgId)
			if (isEmpty(currentConnection)) {
				dispatch(setCurrentConnectionStatus(ConnectionStatus.Failed))
			}
			return currentConnection
		} else {
			return connections.filter((connection) => connection.system === System.Salesforce)
		}
	}

	const getC4SConnectionsNames = (connections: Connection[]): string[] =>
		connections.map((connection) => connection.name)

	const updateSources = (data: string[]) => {
		const [availableSources, selectedCRMSource] = getSources(data)

		const setupComplete = projectWizardState.currentProject.fileInfo?.uploadInfo !== undefined
		if (setupComplete) {
			//In case source selection and upload has been completed, we disable all selection
			setCrmSourceOptions(
				availableSources.map((source) => {
					source.disabled = true
					if (source.type === projectWizardState.currentProject.thirdPartyIntegration?.crmSource)
						source.available = true
					return source
				})
			)
		} else {
			setCrmSourceOptions(availableSources)

			if (selectedCRMSource) {
				setSelectedSourceType(selectedCRMSource)
				setShowButtonContainer(false)

				dispatch(
					updateCurrentProjectAction({
						thirdPartyIntegration: {
							crmSource: selectedCRMSource
						}
					})
				)
			} else {
				setShowButtonContainer(true)
			}
		}
		setIsAvailableSources(availableSources.some((source) => source.available))
	}

	const onSelectConnection = (selectedConnection: string) => {
		setCrmSourceOptions([])
		setIsAvailableSources(true)
		setShowButtonContainer(false)
		dispatch(updateCurrentProjectAction({ thirdPartyIntegration: { crmSource: undefined } }))
		if (!isEmpty(selectedConnection)) {
			setLoadingSources(true)
			getCRMSourceTypesMutation.mutateAsync(
				{
					platformName: 'salesforce',
					orgId: selectedConnection,
					sourceId: projectWizardState.currentProject.source.id || '',
					matchType: matchType
				},
				{
					onSuccess: (response) => {
						updateSources(response)
						setLoadingSources(false)
					},
					onSettled: () => setLoadingSources(false)
				}
			)
		}
		if (connectionOptions.length === 1) {
			setSelectedConnectionName(connectionOptions[0].displayName)
		} else {
			setSelectedConnectionName(selectedConnection)
		}
	}

	const getBannerMessage = (): string => {
		if (isAdmin) {
			if (isSalesforce) {
				return `${t('connection.missing.admin')} ${t('connection.missing.admin.question')}`
			} else {
				return t('connection.missing.admin')
			}
		} else {
			return t('connection.missing.message')
		}
	}

	const isLoadingQueries = (): boolean =>
		loadingSources ||
		connectionsQuery.isFetching ||
		connectionsStatusQuery.isFetching ||
		connection.createQueryStatus === CreateQueryStatus.Loading

	useEffect(() => {
		if (!availableCrmSourceTypes.isFetching && !enableC4SAuthMgmt) {
			updateSources(availableCrmSourceTypes.data || [])
		}
		/**
		 * getSources is not added as a dependency because it would cause the other dependencies to change with each render.
		 */

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, availableCrmSourceTypes.isFetching])

	useEffect(() => {
		const addStorageEventListener = () =>
			storageEventListener(dispatch, (display) => setFailC4SConnectionModal(display))

		window.addEventListener('storage', addStorageEventListener, false)

		return () => {
			window.removeEventListener('storage', addStorageEventListener)
		}
		/**
		 * We want to run this effect when the salesforce oAuth returns an error
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (connection.createQueryStatus === CreateQueryStatus.Success) {
			setShowSuccessMessage(true)
			dispatch(clearCreateQueryStatus())
			dispatch(clearAuthOrigin())
		} else if (connection.createQueryStatus === CreateQueryStatus.Error) {
			setFailC4SConnectionModal(true)
			setErrors(connection.connectionErrors)
			dispatch(clearCreateQueryStatus())
			dispatch(clearAuthOrigin())
		}

		/**
		 * We want run this effect when create C4S connection query response successfully or with errors
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connection.createQueryStatus])

	useEffect(() => {
		if (connectionsQuery.isFetched && connectionsQuery.data && enableC4SAuthMgmt) {
			const c4sConnections = getC4SConnections(connectionsQuery.data || [])
			setNoConnections(!c4sConnections.length)
			setSalesforceConnections(c4sConnections)
			setConnectionNames(getC4SConnectionsNames(c4sConnections || []))
		}
		/**
		 * We only want to run this effect when the data, length from query changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionsQuery.data, connectionsQuery.isFetched, connectionsQuery.isSuccess])

	useEffect(() => {
		if (
			!connectionsQuery.isFetching &&
			!connectionsStatusQuery.isFetching &&
			connectionsStatusQuery.isFetched &&
			connectionsStatusQuery.data &&
			salesforceConnections.length &&
			enableC4SAuthMgmt
		) {
			const options = salesforceConnections.map<Connection>((c4sConnection) => {
				const status =
					connectionsStatusQuery.data.find((con) => con.name === c4sConnection.name)?.status ||
					ConnectionStatus.Unknown

				return { ...c4sConnection, status: status }
			})
			if (isSalesforce) {
				const currentConnection = options.find((conn) => conn.displayName === orgId)
				dispatch(setCurrentConnectionStatus(currentConnection?.status || ConnectionStatus.Failed))
				if (currentConnection?.status === ConnectionStatus.Ready) {
					onSelectConnection(orgId || currentConnection?.displayName)
				}
			} else {
				const readyConnections = options.filter((conn) => conn.status === ConnectionStatus.Ready)
				if (!readyConnections.length) {
					setNoConnections(true)
				}
				if (!selectedConnectionName && readyConnections.length === 1) {
					onSelectConnection(options[0].displayName)
				}

				setConnectionOptions(readyConnections)
			}
		}

		/**
		 * We only want to run this effect when the data, length from query changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		connectionsStatusQuery.data,
		connectionsStatusQuery.isFetched,
		connectionsStatusQuery.isFetching,
		connectionsQuery.isFetching
	])

	return (
		<div className={styles.selectCrmSourceContainer}>
			<NewC4SConnectionModal
				open={showNewC4SConnectionModal}
				onCloseModal={() => setShowNewC4SConnectionModal(false)}
				onContinue={(environment) => openSalesforceAuthenticator(environment)}
				testId={'new-salesforce-modal-wizard'}
			/>
			<C4SFailConnectionModal
				open={failC4SConnectionModal}
				onClose={() => setFailC4SConnectionModal(false)}
				onRetry={() => {
					setFailC4SConnectionModal(false)
					setShowNewC4SConnectionModal(true)
				}}
				errors={errors}
				testId={'new-salesforce-modal'}
			/>
			<DNBSnackbar
				transitionDuration={1000}
				variant="success"
				isOpen={showSuccessMessage}
				handleClose={() => {
					setShowSuccessMessage(false)
				}}
				snackbarText={t('create.salesforce.success.message')}
				duration={10000}
				hasCloseIcon={false}
			/>
			{enableC4SAuthMgmt &&
				(isSalesforce ? connection.currentConnectionStatus !== ConnectionStatus.Ready : noConnections) && (
					<div className={styles.snackbar}>
						<Snackbar
							title={t('connection.missing')}
							message={getBannerMessage()}
							linkText={(isAdmin ? t('connection.missing.establish') : '') || ''}
							onClickLink={() => setShowNewC4SConnectionModal(true)}
							type={SnackType.warning}
							isBanner
						/>
					</div>
				)}
			{enableC4SAuthMgmt &&
				(isSalesforce ? (
					isLoadingQueries() && (
						<div className={styles.spinnerContainer}>
							<Spinner />
						</div>
					)
				) : (
					<div className={styles.selectConnectionContainer}>
						<div className={styles.selectConnection}>
							<DNBInputLabel title="Select Salesforce Environment" size="default" required>
								{t('connection.wizard.select.label')}
							</DNBInputLabel>
							<DNBSelect
								id={`children-selector`}
								size="default"
								minWidth={TokenSizing.SizingWidthModal}
								value={selectedConnectionName}
								listMaxHeight={TokenSizing.SizingWidthInputSmall}
								onChange={(_event: any, selection: string) => onSelectConnection(selection)}
								placeholder={t('connection.wizard.select.placeholder') || ''}
								disabled={!connectionOptions?.length || noConnections}
							>
								{connectionOptions &&
									connectionOptions.map((option) => (
										<DNBSelectOption
											key={option.name}
											value={option.displayName}
											disabled={option.status !== ConnectionStatus.Ready}
										>
											{option.displayName}
										</DNBSelectOption>
									))}
							</DNBSelect>
						</div>
						{isAdmin && !isEmpty(connectionOptions) && (
							<DNBButton size="compact" variant="text" onClick={() => setShowNewC4SConnectionModal(true)}>
								{t('SBC.createNewBtn')}
							</DNBButton>
						)}
						{isLoadingQueries() && <Spinner />}
					</div>
				))}
			{isAvailableSources !== undefined && !isAvailableSources && (
				<div className={styles.warningContainer}>
					<div>{t('select.crm.source.objects.configured.tile')}</div>
					<div className={styles.body}>{t('select.crm.source.objects.configured.body')}</div>
				</div>
			)}
			<div className={styles.optionsContainer}>
				{crmSourceOptions.length
					? crmSourceOptions.map((crmSourceOption, key) => (
							<UploadCRMSourceTile
								key={key}
								item={crmSourceOption}
								onSelected={onSelectedSourceChanged}
								radioGroup="crmSource"
								checked={selectedSourceType === crmSourceOption.type}
								disabled={disabled}
								selectedConnectionName={selectedConnectionName}
							/>
					  ))
					: enableC4SAuthMgmt &&
					  sourceTypeOptions.map((sourceType, key) => (
							<UploadCRMSourceTile
								key={`${key}-${sourceType}`}
								item={{
									type: sourceType,
									available: true,
									onlyThis: false,
									disabled: true
								}}
								onSelected={() => ''}
								radioGroup="crmSource"
								checked={false}
								disabled={true}
							/>
					  ))}
			</div>
			{showButtonContainer && (
				<div className={styles.buttonContainer}>
					<div className={styles.buttonItem}>
						<Button
							type={'primary'}
							text={t('upload.crm.source.health.dashboard')}
							onClick={() => onGoToHealthDashboard()}
							testId={''}
						/>
					</div>
					<div className={styles.buttonItem}>
						<Button
							type={'secondary'}
							text={t('upload.crm.source.projects')}
							onClick={() => onGoToProjects()}
							testId={''}
							size={'large'}
						/>
					</div>
				</div>
			)}
		</div>
	)
}
