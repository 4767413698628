import { ReactElement } from 'react'
import { Divider } from '../../local-core-ui'
import { Element } from '../../types'
import { EnrichmentPreviewPanelItem } from '../enrichment-preview-panel-item/enrichment-preview-panel-item'
import styles from './enrichment-preview-panel.module.scss'

export interface EnrichmentPreviewPanelElement extends Element {
	isChecked?: boolean
	disabled: boolean
}

export interface EnrichmentPreviewPanelProps {
	elements: Array<EnrichmentPreviewPanelElement>
	notEntitledElements?: Array<Element>
	title: string
	displayCheckBoxes: boolean
	onAddElement?: (element: Element) => void
	onRemoveElement?: (element: Element) => void
}

export const EnrichmentPreviewPanel = ({
	elements,
	notEntitledElements,
	title,
	displayCheckBoxes,
	onAddElement,
	onRemoveElement
}: EnrichmentPreviewPanelProps): ReactElement => {
	const onChange = (element: Element) => {
		const newSelection = [...elements]
		const selectedElement = newSelection.find((selectedElement) => selectedElement.elementId === element.elementId)
		if (selectedElement) {
			selectedElement.isChecked = !selectedElement.isChecked
			if (selectedElement.isChecked) {
				if (onAddElement) onAddElement(element)
			} else {
				if (onRemoveElement) onRemoveElement(element)
			}
		}
	}

	return (
		<div>
			<div className={styles.enrichingPreviewPanelContainer}>
				<div className={styles.enrichingModalPanelTitle}>{title}</div>
				<Divider color={'lightgray'} />
				<div>
					{notEntitledElements &&
						notEntitledElements
							.sort((a, b) => {
								return a.displayName > b.displayName ? 1 : -1
							})
							.map((dataElement, key) => (
								<EnrichmentPreviewPanelItem
									key={key}
									item={dataElement}
									isChecked={false}
									isDisabled={true}
									displayCheckBox={displayCheckBoxes}
								/>
							))}
					{elements
						.sort((a, b) => {
							return a.displayName > b.displayName ? 1 : -1
						})
						.map((dataElement, key) => (
							<EnrichmentPreviewPanelItem
								key={key}
								item={dataElement}
								isChecked={dataElement.isChecked}
								displayCheckBox={displayCheckBoxes}
								onAdd={onChange}
								onRemove={onChange}
							/>
						))}
				</div>
			</div>
		</div>
	)
}
