import { AxiosInstance, AxiosResponse } from 'axios'

export interface ConnectionSource {
	name: string
	schemaTYpe: string
	projectName: string
	created: number
	object: string
	createdBy: string
	archived?: boolean
}

export const getConnectionSources = async (
	apiClient: AxiosInstance,
	connectionName: string
): Promise<ConnectionSource[]> => {
	const url = `/pls/integration/connection/${connectionName}/sources`
	return apiClient.get(url).then((response: AxiosResponse<ConnectionSource[]>) => response.data)
}
