import { MatchRuleInfo } from '../../../store/projectWizard/types'
import { generateArrayFromMatchRegex } from '../../../utils'

const transformToCode = (code: string): string => {
	switch (code) {
		case '[AB]':
		case 'B':
			return 'B'
		case 'A':
			return 'A'
		case '.':
		case 'Z':
			return 'Z'
		default:
			return 'F'
	}
}

export const getMatchGradePatterns = (rule: MatchRuleInfo): Array<string> => {
	const patterns: Array<string> = []
	rule.matchRule?.acceptCriterion?.MatchGradePatterns?.map((matchPattern) => {
		const arrayFromMatchRegex = generateArrayFromMatchRegex(matchPattern)
		let pattern = ''
		if (rule.matchQualityType === 'MANUAL' && matchPattern.length) {
			pattern = arrayFromMatchRegex.join(' ')
		} else {
			arrayFromMatchRegex.forEach((code) => {
				pattern = pattern.concat(transformToCode(code) + ' ')
			})
		}
		patterns.push(pattern)
	})
	return patterns
}
