import designTokens from '@dnb-uux-design-system/design-tokens/build/index'
import { t } from 'i18next'
import { ReportSnackbar } from '../../../components/report-snackbar/report-snackbar'
import { ReportTile } from '../../../components/report-tile/report-tile'
import { HalfDonutChart } from '../../../local-core-ui'
import styles from '../report.module.scss'
import { BaseReportSection } from '../types/BaseReportSection'

type MatchSummaryProps = BaseReportSection

const MatchSummary = ({ uploadReport }: MatchSummaryProps) => {
	const { TokenChartColors, TokenColors } = designTokens
	const basicStats = uploadReport?.basicStats
	const duplicationReport = uploadReport?.duplicationReport
	const centerTextColor = TokenColors.ColorGrayPrimary
	const zeroStateColor = TokenChartColors.ColorChartUncategory3

	const getMatchResults = () => {
		const chartColors = Object()
		const chartData = Object()
		const key = t('dashboard.contactMatchOverview.matched') as string

		chartColors[t('dashboard.contactMatchOverview.matched')] = TokenChartColors.ColorChartCategory1
		chartColors[t('dashboard.contactMatchOverview.unmatched')] = TokenChartColors.ColorChartCategory1Alpha2

		if (basicStats) {
			chartData[t('dashboard.contactMatchOverview.matched')] = basicStats.matchedCnt
			chartData[t('dashboard.contactMatchOverview.unmatched')] = basicStats.unmatchedCnt
		}

		return { data: chartData, colors: chartColors, key }
	}

	const getUniqueBusinesses = () => {
		const chartColors = Object()
		const chartData = Object()
		const key = t('report.page.matchSummary.uniqueBusinessesChart.centerText') as string

		chartColors[t('report.page.matchSummary.uniqueBusinessesChart.centerText')] =
			TokenChartColors.ColorChartCategory1
		chartColors[t('dashboard.duplicates')] = TokenChartColors.ColorChartCategory1Alpha2

		if (duplicationReport) {
			chartData[t('report.page.matchSummary.uniqueBusinessesChart.centerText')] = duplicationReport.uniqueRecords
			chartData[t('dashboard.duplicates')] = duplicationReport.duplicateRecords
		}

		return { data: chartData, colors: chartColors, key }
	}

	const { data: matchResultsData, colors: matchResultsColors, key: matchResultsKey } = getMatchResults()
	const {
		data: uniqueBusinessesData,
		colors: uniqueBusinessesColors,
		key: uniqueBusinessesKey
	} = getUniqueBusinesses()

	return (
		<ReportTile
			className={styles.matchSummary}
			title={t('report.page.matchSummary.title') as string}
			subtitle={t('report.page.matchSummary.subtitle') as string}
		>
			<div className={styles.tilesContainer}>
				<ReportTile
					className={styles.chartContainer}
					title={t('report.page.matchSummary.matchResultsTile.title') as string}
					subtitle={t('report.page.matchSummary.matchResultsTile.subtitle') as string}
				>
					{basicStats ? (
						<div className={styles.chartWrapper}>
							<HalfDonutChart
								centerTextColor={centerTextColor}
								colors={matchResultsColors}
								data={matchResultsData}
								defaultKey={matchResultsKey}
								disableHiddenBar
								legendOrientation="horizontal"
								testId="match-results-half-donut-chart"
								zeroStateColor={zeroStateColor}
							/>
						</div>
					) : (
						<ReportSnackbar />
					)}
				</ReportTile>
				<ReportTile
					className={styles.chartContainer}
					title={t('report.page.matchSummary.uniqueBusinessesTile.title') as string}
					subtitle={t('report.page.matchSummary.uniqueBusinessesTile.subtitle') as string}
				>
					{duplicationReport ? (
						<div className={styles.chartWrapper}>
							<HalfDonutChart
								centerTextColor={centerTextColor}
								colors={uniqueBusinessesColors}
								data={uniqueBusinessesData}
								defaultKey={uniqueBusinessesKey}
								disableHiddenBar
								legendOrientation="horizontal"
								testId="unique-businesses-half-donut-chart"
								zeroStateColor={zeroStateColor}
							/>
						</div>
					) : (
						<ReportSnackbar />
					)}
				</ReportTile>
			</div>
		</ReportTile>
	)
}

export { MatchSummary }
