import { DNBButton, DNBPopover } from '@dnb-uux-design-system/react'
import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import confidenCodeImg from '../../../../assets/images/ConfidenceCode.png'
import information from '../../../../assets/images/i-grey.svg'
import summaryGradeImage from '../../../../assets/images/MatchSummaryGrade.png'
import { MatchTemplatePicker } from '../../../../components/match-template-picker/match-template-picker'
import { useApi } from '../../../../hooks'
import { Button, Modal } from '../../../../local-core-ui'
import { useMatchTemplates } from '../../../../queries/useMatchTemplates'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import {
	clearCurrentMatchConfigTemplate,
	setMatchingApproach,
	updateCurrentProjectAction
} from '../../../../store/projectWizard/actions'
import { createOrUpdateMatchRules } from '../../../../store/projectWizard/thunks'
import { MatchingApproach, MatchStyle } from '../../../../types'
import { deletePreviousMatchRules } from '../deletePreviousMatchRules'
import { AlternateFieldsWarningModal } from './alternate-fields-warning-modal/alternate-fields-warning-modal'
import styles from './recommendation-match.module.scss'

export interface RecommendationMatchProps {
	onSelect?: () => void
}

export const RecommendationMatch: FC<RecommendationMatchProps> = ({ onSelect }) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [showMatchTemplate, setShowMatchTemplate] = useState(false)
	const [onProceedWithTemplate, setonProceedWithTemplate] = useState(false)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const [templateApproachButtonDisabled, setTemplateApproachButtonDisabled] = useState<boolean>(true)
	const [isAlternateFieldsWarningModal, setIsAlternateFieldsWarningModal] = useState<boolean>(false)
	const [showModalChooseMatch, setShowModalChooseMatch] = useState<boolean>(false)
	const [matchingSelected, setMatchingSelected] = useState<MatchingApproach | undefined>(undefined)
	const [isOpen, setIsOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [isOpenConfidence, setIsOpenConfidence] = useState(false)
	const [anchorElConfidence, setAnchorElConfidence] = useState<HTMLButtonElement | null>(null)
	const sourceEntityType = projectWizardState.currentProject.source.entityType

	const RESTRICTED_ENTITY_TYPE_TO_RECOMMENDATIONS = 'Contacts'

	const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
		setIsOpen(!isOpen)
	}
	const handleOnClickConfidence = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorElConfidence(event.currentTarget)
		setIsOpenConfidence(!isOpenConfidence)
	}
	const matchingApproach = projectWizardState.currentProject.matchingApproach
	const existingMap = Object.keys(
		projectWizardState.currentProject?.mappingInfo?.mapping?.existingFieldDefinitionsMap
	)
	const alternateCompanyMap = existingMap.length
		? existingMap.filter((column) => {
				return column.toLowerCase().includes('alternate') && column.toLowerCase().includes('company')
		  })
		: []
	const alternateAdrsMap = existingMap.length
		? existingMap.filter((column) => {
				return column.toLowerCase().includes('alternate') && !column.toLowerCase().includes('company')
		  })
		: []
	const isCompanyExpanded = alternateCompanyMap.length > 0
	const isAddressExpanded = alternateAdrsMap.length > 0
	const alternateCountryValidation =
		projectWizardState.currentProject.mappingInfo.mapping.existingFieldDefinitionsMap?.Alternate_Country

	const alternateCompanyAddress =
		isAddressExpanded && alternateCountryValidation !== undefined
			? projectWizardState.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap?.companyInformation.filter(
					(row) => {
						return (
							row.fieldName?.toLowerCase().includes('alternate') &&
							!row.fieldName?.toLowerCase().includes('company')
						)
					}
			  )
			: []
	const alternateCompanyAddressFields = (alternateCompanyAddress ?? []).filter((row) => row.columnName !== undefined)
	const alternateCompanyName = isCompanyExpanded
		? projectWizardState.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap?.companyInformation.filter(
				(row) => {
					return (
						row.fieldName?.toLowerCase().includes('alternate') &&
						row.fieldName?.toLowerCase().includes('company')
					)
				}
		  )
		: []
	const alternateCompanyNameFields = (alternateCompanyName ?? []).filter((row) => row.columnName !== undefined)
	const alternateFields = [...alternateCompanyNameFields, ...alternateCompanyAddressFields]

	const dispatch: TDispatch = useAppDispatch()
	const apiClient = useApi()
	const matchTemplatesQuery = useMatchTemplates()
	const onCloseAlternateFieldsWarningModal = () => {
		setIsAlternateFieldsWarningModal(false)
	}

	//TODO: Review and unify functions repeated here and in choose-match.tsx
	const resetMatchRules = () => {
		if (matchingApproach !== MatchingApproach.CHOOSE_TEMPLATE) {
			dispatch(clearCurrentMatchConfigTemplate())
			if (projectWizardState.currentProject.source.id)
				deletePreviousMatchRules(apiClient, projectWizardState.currentProject.source.id)
		}
		clearMatchRules()
		if (onSelect) onSelect()
	}

	const clearMatchRules = async () => {
		const baseRuleIdx = projectWizardState.currentProject.matchRules.findIndex(
			(matchRule) => matchRule.matchRule.ruleType === 'BASE_RULE'
		)
		const rulesCopy = JSON.parse(JSON.stringify(projectWizardState.currentProject.matchRules))
		const baseRule = rulesCopy[baseRuleIdx]
		rulesCopy[0].matchRule = {
			...rulesCopy[0].matchRule,
			displayName: t('matching.step.base.rule') as string,
			acceptCriterion: {
				LowestConfidenceCode: 6,
				HighestConfidenceCode: 10
			},
			allowedValues: [],
			exclusionCriterion: [],
			ruleType: 'BASE_RULE',
			matchStyle:
				matchingSelected === MatchingApproach.START_SCRATCH_MR
					? MatchStyle.MATCH_RECOMMENDATION
					: MatchStyle.MATCH_QUALITY
		}
		if (matchingSelected !== MatchingApproach.START_SCRATCH_MR) {
			delete rulesCopy[0].matchRule.precedenceCriterion
			delete rulesCopy[0].matchRule.precedenceProfile
			delete rulesCopy[0].matchRule.stewardshipProfile
			delete rulesCopy[0].matchRule.passProfile
		}
		if (rulesCopy[0].matchRule.acceptCriterion.MatchGradePatterns) {
			delete rulesCopy[0].matchRule.acceptCriterion.MatchGradePatterns
		}
		rulesCopy[0] = { ...rulesCopy[0], advancedSettingsCollapsed: true }
		if (baseRule) {
			dispatch(
				updateCurrentProjectAction({
					matchRules: [baseRule]
				})
			)
		}
		if (matchingSelected !== MatchingApproach.START_SCRATCH_MR) {
			const updatedMatchRule = await createOrUpdateMatchRules()
			dispatch(updatedMatchRule)
		}
	}

	const proceedWithTemplate = () => {
		dispatch(updateCurrentProjectAction({ source: { company_match_type: 'Traditional' } }))
		setShowMatchTemplate(true)
	}

	const proceedWithScratch = (isMatchRecommendation?: boolean) => {
		dispatch(
			updateCurrentProjectAction({
				source: { company_match_type: isMatchRecommendation ? 'MatchRecommendation' : 'Traditional' }
			})
		)
		resetMatchRules()
		dispatch(
			setMatchingApproach(
				isMatchRecommendation ? MatchingApproach.START_SCRATCH_MR : MatchingApproach.START_SCRATCH
			)
		)
	}

	const action2MatchingApproach = (matching: MatchingApproach | undefined) => {
		if (matching === MatchingApproach.CHOOSE_TEMPLATE) {
			setonProceedWithTemplate(true)
			alternateFields.length > 0 ? setIsAlternateFieldsWarningModal(true) : proceedWithTemplate()
		} else if (matching === MatchingApproach.START_SCRATCH) {
			localStorage.setItem('chooseType', 'traditional')
			setonProceedWithTemplate(false)
			alternateFields.length > 0 ? setIsAlternateFieldsWarningModal(true) : proceedWithScratch()
		} else {
			localStorage.setItem('chooseType', 'recommendation')
			queryClient.invalidateQueries([
				'getMatchSummaryCodes',
				'getMatchRecommendationProfile',
				'getMatchPrecedenceProfiles'
			])
			setonProceedWithTemplate(false)
			proceedWithScratch(true)
		}
	}

	const isBaseRuleChanged = () => {
		if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId) {
			return true
		} else {
			const baseRule = projectWizardState.currentProject.matchRules[0]
			return (
				(baseRule.matchRule.versionId !== undefined && baseRule.matchRule.versionId > 1) ||
				baseRule?.advancedSettingsCollapsed === false ||
				projectWizardState.currentProject.matchRules.length > 1 ||
				baseRule?.matchRule.displayName !== 'Global Rule' ||
				baseRule?.matchRule.acceptCriterion.LowestConfidenceCode !== 6 ||
				baseRule?.matchRule.exclusionCriterion !== undefined ||
				baseRule?.matchRule.acceptCriterion.MatchGradePatterns !== undefined ||
				baseRule?.matchRule.precedenceProfile !== undefined
			)
		}
	}

	useEffect(() => {
		if (matchTemplatesQuery.data) setTemplateApproachButtonDisabled(matchTemplatesQuery.data.length === 0)
		/**
		 * We only want to run this effect when the flag isSuccess from matchTemplatesQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchTemplatesQuery.isSuccess])

	return (
		<div>
			<Modal
				open={showModalChooseMatch}
				showButtonClose={true}
				onClose={() => setShowModalChooseMatch(false)}
				isContainer={false}
				testId="choose-match-modal-mr"
			>
				<div data-testid="choose-matching-description" className={styles.descriptionChoose}>
					{t('modal.choose.matching')}
				</div>
				<div className={styles.containerButtons}>
					<Button
						text={t('user.tile.button.yes')}
						type="primary"
						size="small"
						onClick={() => {
							action2MatchingApproach(matchingSelected)
							setShowModalChooseMatch(false)
						}}
						testId={'choose-match-ok'}
					/>
					<Button
						text={t('matching.step.modal.cancel')}
						type="primary"
						size="small"
						onClick={() => {
							setShowModalChooseMatch(false)
							if (onSelect) onSelect()
						}}
						testId={'choose-match-no'}
					/>
				</div>
			</Modal>
			{showMatchTemplate ? (
				<MatchTemplatePicker
					open={showMatchTemplate}
					onClose={() => {
						setShowMatchTemplate(false)
					}}
					onSelect={() => {
						if (projectWizardState.currentProject.source.id)
							deletePreviousMatchRules(apiClient, projectWizardState.currentProject.source.id)
						setShowMatchTemplate(false)
						dispatch(setMatchingApproach(MatchingApproach.CHOOSE_TEMPLATE))
						if (onSelect) onSelect()
					}}
					testId="temporal-match-picker"
					sourceId={projectWizardState.currentProject.source.id}
					onTemplatesClear={() => {
						setShowMatchTemplate(false)
						setTemplateApproachButtonDisabled(true)
					}}
				/>
			) : undefined}
			<h2 data-testid="matching-header" className={styles.title}>
				{t('choose.match.step.title')}
			</h2>
			<p data-testid="matching-header-description" className={styles.description}>
				{t('choose.match.step.span')}
			</p>
			<div data-testid="matching-approach-container" className={styles.matchingContainer}>
				<div data-testid="matching-recommendation-approach-container" className={styles.popupCont}>
					<div className={styles.imageWrapper}>
						<img
							src={summaryGradeImage}
							alt={t('choose.match.step.image.match.summary.alt') as string}
							data-testid="choose-match-step-match-summary-image"
						/>
					</div>
					<div className={styles.codeDiv}>
						<p data-testid="match-code-header" className={styles.descriptionHeader}>
							{t('choose.recommendation.match.code')}
						</p>
						<p>
							<span
								data-testid="match-code-svg-span"
								className={styles.svgSpan}
								onClick={(e) => {
									handleOnClick(e)
								}}
							>
								<img
									data-testid="match-code-svg-image"
									className={styles.svgImage}
									src={information}
									alt={'information'}
								/>
							</span>
							<span>
								<DNBPopover
									anchorEl={anchorEl as HTMLElement}
									closeButtonOnClick={() => setIsOpen(false)}
									hasArrow
									hasCloseButton
									open={isOpen}
									placement={'top-end'}
								>
									<Box sx={{ maxHeight: '150px' }}>
										<div className={styles.popoverTitle}>
											{t('choose.recommendation.match.code')}
										</div>
										<div>
											<p className={styles.popoverText}>{t('match.code.tooltip.text1')}</p>
											<p className={styles.popoverText}>{t('match.code.tooltip.text2')}</p>
											<p className={styles.popoverText}>{t('match.code.tooltip.text3')}</p>
											<ul>
												<li className={styles.popoverText}>{t('match.code.tooltip.text4')}</li>
												<li className={styles.popoverText}>{t('match.code.tooltip.text5')}</li>
												<li className={styles.popoverText}>{t('match.code.tooltip.text6')}</li>
												<li className={styles.popoverText}>{t('match.code.tooltip.text7')}</li>
											</ul>
										</div>
									</Box>
								</DNBPopover>
							</span>
						</p>
					</div>

					<p data-testid="match-code-recommended-text" className={styles.recommendedText}>
						({t('enrichment.approach.saved.template.recommended')})
					</p>
					<p data-testid="match-code-description" className={styles.description}>
						{t('choose.recommendation.matchcode.desc')}
					</p>
					<ul data-testid="match-code-description-points" className={styles.descriptionpoints}>
						<li className={styles.chooseMargin}>{t('choose.option')}</li>
						<li className={styles.descText}>{t('match.code.desc.text1')}</li>
						<li className={styles.descText}>{t('match.code.desc.text2')}</li>
						<li className={styles.descText}>{t('match.code.desc.text3')}</li>
					</ul>
					<div className={styles.buttonWrapper}>
						<div className={styles.startscratchbtn}>
							<DNBButton
								size="default"
								variant="primary"
								disabled={sourceEntityType === RESTRICTED_ENTITY_TYPE_TO_RECOMMENDATIONS}
								onClick={() => {
									if (
										projectWizardState.currentProject.matchRules.length > 1 ||
										isBaseRuleChanged()
									) {
										setShowModalChooseMatch(true)
										setMatchingSelected(MatchingApproach.START_SCRATCH_MR)
									} else {
										action2MatchingApproach(MatchingApproach.START_SCRATCH_MR)
									}
								}}
								data-testid={`choose-recommendation-match-button`}
							>
								{t('choose.match.step.from.scratch.button')}
							</DNBButton>
						</div>
					</div>
				</div>
				<div data-testid="matching-traditional-approach-container" className={styles.popupCont}>
					<div className={styles.imageWrapper}>
						<img
							src={confidenCodeImg}
							alt={t('choose.match.step.image.confidence.code.alt') as string}
							data-testid="choose-match-step-confidenc-code-image"
						/>
					</div>
					<div className={styles.codeDiv}>
						<p data-testid="matching-confidence-code-header" className={styles.descriptionHeader}>
							{t('choose.recommendation.match.cofidence.code')}
						</p>
						<p>
							<span
								data-testid="match-code-svg-span"
								className={styles.svgSpan}
								onClick={(e) => {
									handleOnClickConfidence(e)
								}}
							>
								<img
									data-testid="match-code-svg-image"
									className={styles.svgImage}
									src={information}
									alt={'information'}
								/>
							</span>
							<span>
								<DNBPopover
									anchorEl={anchorElConfidence as HTMLElement}
									closeButtonOnClick={() => setIsOpenConfidence(false)}
									hasArrow
									hasCloseButton
									open={isOpenConfidence}
									placement={'top-end'}
								>
									<Box sx={{ maxHeight: '150px' }}>
										<div className={styles.popoverTitle}>
											{t('choose.recommendation.match.cofidence.code')}
										</div>
										<div>
											<p className={styles.popoverText}>{t('match.confidence.tooltip.text1')}</p>
											<p className={styles.popoverText}>{t('match.confidence.tooltip.text2')}</p>
											<ul>
												<li className={styles.popoverText}>
													{t('match.confidence.tooltip.text3')}
												</li>
												<li className={styles.popoverText}>
													{t('match.confidence.tooltip.text4')}
												</li>
												<li className={styles.popoverText}>
													{t('match.confidence.tooltip.text5')}
												</li>
											</ul>
										</div>
									</Box>
								</DNBPopover>
							</span>
						</p>
					</div>
					<p data-testid="matching-confidence-code-description" className={styles.description}>
						{t('choose.recommendation.confidence.desc')}
					</p>
					<ul data-testid="match-code-description-points" className={styles.descriptionpoints}>
						<li className={styles.chooseMargin}>{t('choose.option')}</li>
						<li className={styles.descText}>{t('confidence.code.desc.text1')}</li>
						<li className={styles.descText}>{t('confidence.code.desc.text2')}</li>
						<li className={styles.descText}>{t('confidence.code.desc.text3')}</li>
					</ul>
					<div className={styles.buttonWrapper}>
						<div className={styles.buttonCont}>
							<DNBButton
								size="default"
								variant="primary"
								onClick={() => {
									if (
										projectWizardState.currentProject.matchRules.length > 1 ||
										isBaseRuleChanged()
									) {
										setShowModalChooseMatch(true)
										setMatchingSelected(MatchingApproach.CHOOSE_TEMPLATE)
									} else {
										action2MatchingApproach(MatchingApproach.CHOOSE_TEMPLATE)
									}
								}}
								data-testid={`choose-match-step-template-button`}
								disabled={templateApproachButtonDisabled}
							>
								{t('choose.match.step.choose.template.button')}
							</DNBButton>
						</div>
					</div>
					<div className={styles.buttonWrapper}>
						<div className={styles.buttonContScratch}>
							<DNBButton
								size="default"
								variant="primary"
								onClick={() => {
									if (
										projectWizardState.currentProject.matchRules.length > 1 ||
										isBaseRuleChanged()
									) {
										setShowModalChooseMatch(true)
										setMatchingSelected(MatchingApproach.START_SCRATCH)
									} else {
										action2MatchingApproach(MatchingApproach.START_SCRATCH)
									}
								}}
								data-testid={`choose-match-step-from-scratch-button`}
							>
								{t('choose.match.step.from.scratch.button')}
							</DNBButton>
						</div>
					</div>
				</div>
			</div>
			<AlternateFieldsWarningModal
				testId={'alternate-fields-warning-modal'}
				isOpen={isAlternateFieldsWarningModal}
				onClose={onCloseAlternateFieldsWarningModal}
				onProceed={onProceedWithTemplate ? proceedWithTemplate : proceedWithScratch}
				alternateFields={alternateFields}
			/>
		</div>
	)
}
