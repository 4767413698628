import cx from 'classnames'
import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import dnbAmpLogo from '../../../assets/images/dnb-amp-logo.svg'
import wordmarkLogo from '../../../assets/images/dnb-wordmark-logo.svg'
import { Upload } from '../../../types'
import { removeFileNameExtension } from '../../../utilities'
import styles from '../report.module.scss'

interface CoverProps {
	tenant: string | null
	upload: Upload | null
}

const getFormattedDate = (date: Date) => {
	const year = date.getFullYear().toString().slice(2, 4)
	const month = (1 + date.getMonth()).toString().padStart(2, '0')
	const day = date.getDate().toString().padStart(2, '0')

	return `${month}/${day}/${year}`
}

export const Cover = ({ tenant, upload }: CoverProps): ReactElement => {
	const { t } = useTranslation()

	const creationDate = new Date(upload?.uploadCreatedTime ?? 0)
	const currentDate = new Date()
	const privacyPolicyLink = 'https://www.dnb.com/about-us/data-transparency.html'
	const termsLink = 'https://www.dnb.com/utility-pages/terms-of-use.html'

	return (
		<div className={cx(styles.cover, styles.page)}>
			<div className={styles.titles}>
				<div className={styles.wordmarkLogoContainer}>
					<img
						alt="dun & bradstreet logo"
						aria-label="dun & bradstreet logo"
						className={styles.wordmarkLogo}
						src={wordmarkLogo}
					/>
				</div>
				<div className={styles.titleContainer}>
					<h1>{removeFileNameExtension(upload?.displayName ?? '')}</h1>
					<h2>{getFormattedDate(currentDate)}</h2>
				</div>
			</div>
			<div className={styles.reportDetails}>
				<div className={styles.details}>
					<p className={styles.detail}>{tenant}</p>
					<p className={styles.detail}>
						{t('report.page.cover.detail.1')} {getFormattedDate(creationDate)}
					</p>
				</div>
				<div className={styles.ampLogoContainer}>
					<img alt="dnb amp logo" aria-label="dnb amp logo" className={styles.ampLogo} src={dnbAmpLogo} />
				</div>
			</div>
			<div className={styles.footer}>
				<Trans i18nKey="report.page.cover.footer" values={{ year: currentDate.getFullYear().toString() }}>
					<span />
					<a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer" />
					<a href={termsLink} target="_blank" rel="noopener noreferrer" />
				</Trans>
			</div>
		</div>
	)
}
