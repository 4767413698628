import { DNBButton } from '@dnb-uux-design-system/react'
import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dnbAmpLogo from '../../assets/images/dnb-amp-logo.svg'
import { getLocalizedDateString } from '../../helpers'
import { UserDetail } from '../../types'
import styles from './template-tile-container.module.scss'

interface TemplateTileContainerProps {
	name: string
	createdBy: UserDetail
	date: number
	disabled?: boolean
	canRemove?: boolean

	functionApplyTemplate(templateId: string): void

	functionPreviewTemplate(templateId: string): void

	functionRemoveTemplate?(templateId: string, name: string): void

	testId: string
	templateId: string
	textPreview?: string
	textRemove?: string
	flipped?: boolean
	dnbOwned?: boolean
}

export const TemplateTileContainer = ({
	name,
	createdBy,
	date,
	disabled,
	canRemove,
	functionApplyTemplate,
	functionPreviewTemplate,
	functionRemoveTemplate,
	testId,
	templateId,
	children,
	textPreview,
	textRemove,
	flipped,
	dnbOwned
}: PropsWithChildren<TemplateTileContainerProps>): ReactElement => {
	const { t } = useTranslation()
	const templateTileElement = useRef<HTMLDivElement>(null)
	const removeLinkElement = useRef<HTMLDivElement>(null)
	const [cardFlipped, setCardFlipped] = useState(false)

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			if (templateTileElement.current !== null && !disabled) {
				const target = event && event.target ? (event.target as Element) : null
				const isCurrentElement = target && target.closest('#' + templateTileElement.current.id) !== null
				const hasRemoveLinkBeenClicked = target === removeLinkElement.current

				if (hasRemoveLinkBeenClicked) {
					setCardFlipped(false)
				} else {
					if (isCurrentElement) {
						if (!cardFlipped) {
							setCardFlipped(true)
						}
					} else {
						setCardFlipped(false)
					}
				}
			}
		}
		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
		/**
		 * 'cardFlipped' is not added as a dependency because it would create an infinite loop.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled])

	return (
		<div className={`${styles.templateTileContainer} ${disabled ? styles.disabledTile : ''}`}>
			<div
				id={testId}
				ref={templateTileElement}
				className={`${styles.card} ${cardFlipped ? styles.cardFlipped : styles.cardUnflip}`}
				data-testid={`${cardFlipped ? 'card-flipped' : 'card-unflipped'}`}
			>
				<div className={styles.enrichmentTemplateTileOptions}>
					<div className={styles.indicatorLeft} />
					<div className={styles.containerOptions}>
						<DNBButton
							size="default"
							variant="primary"
							onClick={() => {
								functionApplyTemplate(templateId)
							}}
							data-testid={'textApplyTile--' + testId}
						>
							{t('text.apply')}
						</DNBButton>

						<div
							className={`${styles.textLink} ${styles.textPreview}`}
							onClick={() => {
								functionPreviewTemplate(templateId)
							}}
							tabIndex={0}
							onKeyDown={(event) => {
								if (event.key === 'Enter') functionPreviewTemplate(templateId)
							}}
							data-testid={`previewLink-${testId}`}
						>
							{textPreview || t('text.preview.link')}
						</div>
						{canRemove && (
							<div
								className={`${styles.textLink} ${styles.textRemove}`}
								onClick={() => {
									if (functionRemoveTemplate) {
										functionRemoveTemplate(templateId, name)
									}
									setCardFlipped(false)
								}}
								ref={removeLinkElement}
								tabIndex={0}
								onKeyDown={(event) => {
									if (event.key === 'Enter' && functionRemoveTemplate) {
										functionRemoveTemplate(templateId, name)
										setCardFlipped(false)
									}
								}}
								data-testid={`removeLink-${testId}`}
							>
								{textRemove || t('text.remove.link')}
							</div>
						)}
					</div>
				</div>
				<div className={styles.templateTileContainerDetail}>
					<div
						data-testid="template-name-section"
						className={`${styles.commonSection} ${styles.templateNameSection}`}
					>
						{dnbOwned && (
							<div className={styles.logo}>
								<img alt="D&B logo" src={dnbAmpLogo} width="18px" />
							</div>
						)}
						<div data-testid="template-name" className={styles.templateName} title={name}>
							{name}
						</div>
					</div>
					<div className={`${styles.commonSection} ${styles.childrenContainer}`}>{children}</div>
					{!dnbOwned && (
						<div className={`${styles.commonSection} ${styles.createdBySection}`}>
							{createdBy.FirstName !== '' || createdBy.LastName !== '' ? (
								<div className={`${styles.commonInfo} ${styles.commonInfoFirstLine}`}>
									{createdBy.FirstName} {createdBy.LastName}
								</div>
							) : (
								<div className={`${styles.commonInfo} ${styles.commonInfoFirstLine}`}>
									{createdBy.id}
								</div>
							)}
							<div className={`${styles.commonInfo} ${styles.commonInfoSecondLine}`}>
								{getLocalizedDateString(date)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
