import { ColorBlueBrand, ColorBluePrimaryDark } from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { isEqual, sortBy } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { RenamingModal } from '../../../../components/renaming-modal/renaming-modal'
import { TemplateSavingModal } from '../../../../components/template-saving-modal/template-saving-modal'
import { Button, Grid, Icon, Input, Modal, Tab, TabPanel, Tabs } from '../../../../local-core-ui'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { addMatchRule, updateCurrentProjectAction, updateMatchRule } from '../../../../store/projectWizard/actions'
import {
	createOrUpdateMatchConfigTemplate,
	createOrUpdateMatchRules,
	deleteMatchRule
} from '../../../../store/projectWizard/thunks'
import { BASE_RULE, SPECIAL_RULE } from '../../../../store/projectWizard/types'
import { MatchRule, MatchRuleTemplate } from '../../../../types'
import { hoverRuleColorList, ruleColorList } from '../../color-list'
import { RuleItem } from '../rule-item-v2'
import styles from './match-step-v2.module.scss'

import { DNBButton } from '@dnb-uux-design-system/react'
import informationIcon from '../../../../assets/images/info.svg'
import { MatchYourDataHeader } from '../../../../components/match-your-data-header/match-your-data-header'
import { useMatchTemplate } from '../../../../queries/useMatchTemplate'
import { EntityType } from '../../../../types/sources/EntityType'
import { generateHashCode } from '../../../../utilities'
import { isMatchRulesReadyToValidate } from '../isMatchRulesReadyToValidate'

interface IMatchStepProps {
	readonly?: boolean
	onClickBackToLibrary?: () => void
	matchTitle?: string
	showGeographicalDistributionDialogBox?: boolean
	onGeographicalDistributionDialogBoxClose?: () => void
}

export function MatchStepV2({
	readonly = false,
	onClickBackToLibrary,
	matchTitle,
	showGeographicalDistributionDialogBox,
	onGeographicalDistributionDialogBoxClose
}: IMatchStepProps): ReactElement {
	const { t } = useTranslation()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const confidence = 6
	const initialMatchGradePattern = '[AB]{11}.{3}'
	const [modalProps, setShowModalProps] = useState({
		show: false,
		content: '',
		hide: 'hide',
		action: 'action',
		option: '',
		idx: 0,
		onlyHideButton: false
	})
	const [renameInput, setRenameInput] = useState('')
	const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false)
	const [ruleToRename, setRuleToRename] = useState(-1)
	const [tabRules, setTabRules] = useState<number>(readonly ? 0 : 1)
	const [addedNewTab, setAddedNewTab] = useState<boolean>(false)
	const [savingIsOpen, setSavingIsOpen] = useState<boolean>(false)
	const [saveAsTemplateButtonActive, setSaveAsTemplateButtonActive] = useState<boolean>(false)
	const [updateTemplateButtonActive, setUpdateTemplateButtonActive] = useState<boolean>(false)
	const [templateMatchRulesHash, setTemplateMatchRulesHash] = useState<string>('')
	const [matchRulesHash, setMatchRulesHash] = useState<string>('')

	const dispatch = useAppDispatch()
	const currentUser = session.user?.EmailAddress
	const matchConfigTemplate = projectWizardState.currentProject.currentMatchConfigTemplate
	const currentMatchRules = projectWizardState.currentProject.matchRules
	const queryClient = useQueryClient()

	const matchTemplateQuery = useMatchTemplate(
		projectWizardState.currentProject.currentMatchConfigTemplate?.templateId || ''
	)

	const onChangePartialMatch = (newValue: boolean) =>
		dispatch(updateCurrentProjectAction({ source: { enable_partial_match: newValue } }))

	const addNewRule = () => {
		const newMatchRule: MatchRule = {
			sourceId: projectWizardState.currentProject.source?.id,
			displayName: t('matching.step.default.new.rule.name', {
				i: projectWizardState.currentProject.matchRules.length + 1
			}) as string,
			ruleType: SPECIAL_RULE,
			matchKey: 'Country',
			allowedValues: [],
			acceptCriterion: {
				LowestConfidenceCode: 6,
				HighestConfidenceCode: 10
			}
		}
		dispatch(addMatchRule(newMatchRule))
		setAddedNewTab(true)
		window.scrollTo({ top: 200, behavior: 'smooth' })
	}

	const actionModal = () => {
		switch (modalProps.option) {
			case `${t('matching.step.modal.rename')}`:
				const updatedMatchRule = projectWizardState.currentProject.matchRules[modalProps.idx]
				updatedMatchRule.matchRule.displayName = renameInput
				dispatch(updateMatchRule(modalProps.idx, updatedMatchRule))
				break
			case `${t('matching.step.modal.reset')}`:
				const updatedResetMatchRule = projectWizardState.currentProject.matchRules[modalProps.idx]
				const isBaseRule = updatedResetMatchRule.matchRule.ruleType === BASE_RULE
				updatedResetMatchRule.matchRule.displayName = isBaseRule
					? (t('matching.step.base.rule') as string)
					: (t('matching.step.default.new.rule.name', { i: modalProps.idx + 1 }) as string)
				updatedResetMatchRule.matchRule.acceptCriterion.LowestConfidenceCode = confidence
				updatedResetMatchRule.matchRule.allowedValues = isBaseRule ? undefined : []
				updatedResetMatchRule.advancedSettingsCollapsed = true
				updatedResetMatchRule.matchQualityType = 'EASY'
				updatedResetMatchRule.matchRule.exclusionCriterion = []
				if (updatedResetMatchRule.matchRule.acceptCriterion.MatchGradePatterns) {
					delete updatedResetMatchRule.matchRule.acceptCriterion.MatchGradePatterns
				}
				dispatch(updateMatchRule(modalProps.idx, updatedResetMatchRule))
				break
			case `${t('matching.step.modal.delete')}`:
				dispatch(deleteMatchRule(modalProps.idx))
				break
			default:
				break
		}
		setShowModalProps({ ...modalProps, show: false })
	}

	const setConfidenceSliderValue = (confidenceSliderValue: number, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		updatedMatchRule.matchRule.acceptCriterion.LowestConfidenceCode = confidenceSliderValue
		dispatch(updateMatchRule(idx, updatedMatchRule))
	}

	const setMatchGradePatterns = (newMatchGradePatters: Array<string>, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		updatedMatchRule.matchRule.acceptCriterion.MatchGradePatterns = newMatchGradePatters
		dispatch(updateMatchRule(idx, updatedMatchRule))
	}

	const setMatchType = (selectedMatchType: string, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		if (updatedMatchRule.matchQualityType !== selectedMatchType) {
			updatedMatchRule.matchQualityType = selectedMatchType
			if (selectedMatchType === 'EASY') {
				updatedMatchRule.matchRule.acceptCriterion.MatchGradePatterns = [initialMatchGradePattern]
			} else {
				updatedMatchRule.matchRule.acceptCriterion.MatchGradePatterns = []
			}
			dispatch(updateMatchRule(idx, updatedMatchRule))
		}
	}

	const setExclusionCriterion = (newExclusionCriteria: Array<string>, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		updatedMatchRule.matchRule.exclusionCriterion = newExclusionCriteria
		dispatch(updateMatchRule(idx, updatedMatchRule))
	}

	const setAllowedCountries = (newAllowedCountries: Array<string>, idx: number) => {
		const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
		if (
			!isEqual(sortBy(updatedMatchRule), sortBy(updatedMatchRule.matchRule.allowedValues)) &&
			updatedMatchRule.matchRule.ruleType !== BASE_RULE
		) {
			updatedMatchRule.matchRule.allowedValues = newAllowedCountries
			dispatch(updateMatchRule(idx, updatedMatchRule))
		}
	}

	const getDisabledCountries = (exceptionIndex: number): Array<string> => {
		let disabledCountries: Array<string> = []
		if (exceptionIndex !== 0) {
			projectWizardState.currentProject.matchRules.forEach((matchRuleInfo, index) => {
				if (index !== exceptionIndex && matchRuleInfo.matchRule.allowedValues) {
					disabledCountries = disabledCountries.concat(matchRuleInfo.matchRule.allowedValues)
				}
			})
		}
		return disabledCountries
	}

	const onRuleNameChange = (index: number) => {
		setRuleToRename(index - 1)
		setIsRenameModalOpen(true)
	}

	const handleRuleRename = (newName: string) => {
		if (newName !== '') {
			const index = ruleToRename
			if (newName.length > 0) {
				const updatedMatchRule = projectWizardState.currentProject.matchRules[index]
				projectWizardState.currentProject.matchRules[index].matchRule.displayName = newName
				dispatch(updateMatchRule(index, updatedMatchRule))
				setRuleToRename(-1)
			}
		}
		setIsRenameModalOpen(false)
	}

	const onTemplateSave = async () => {
		await dispatch(createOrUpdateMatchRules())
			.then(() => {
				setSavingIsOpen(true)
			})
			.catch((err) => {
				throw err
			})
	}

	const onTemplateUpdate = async () => {
		await dispatch(createOrUpdateMatchRules())
			.then(() => {
				updateMatchConfigTemplate()
			})
			.catch((err) => {
				throw err
			})
	}

	const saveMatchConfigTemplate = (templateName: string) => {
		dispatch(createOrUpdateMatchConfigTemplate(templateName))
			.then(() => {
				setSavingIsOpen(false)
				queryClient.invalidateQueries('getMatchTemplates')
				queryClient.invalidateQueries([
					'getMatchTemplates',
					projectWizardState.currentProject.currentMatchConfigTemplate?.templateId
				])
			})
			.catch((e) => console.error(e))
	}

	const updateMatchConfigTemplate = () => {
		dispatch(createOrUpdateMatchConfigTemplate())
			.then(() => {
				queryClient.invalidateQueries('getMatchTemplates')
				queryClient.invalidateQueries([
					'getMatchTemplates',
					projectWizardState.currentProject.currentMatchConfigTemplate?.templateId
				])
			})
			.catch((e) => console.error(e))
	}

	const computeHash = (matchRule: MatchRule | MatchRuleTemplate): number => {
		const keysToCompare = [
			'displayName',
			'state',
			'ruleType',
			'matchKey',
			'allowedValues',
			'acceptCriterion',
			'exclusionCriterion'
		]

		const values = keysToCompare.map((key) => {
			const value = Object.values(matchRule)[Object.keys(matchRule).findIndex((objectKey) => objectKey === key)]
			return JSON.stringify(value)
		})
		return generateHashCode(values.join('|'))
	}

	useEffect(() => {
		if (matchTemplateQuery.isSuccess && matchTemplateQuery.data) {
			const hashes = matchTemplateQuery.data.matchRuleTemplates
				.filter((matchRuleTemplate: MatchRuleTemplate) => matchRuleTemplate.state === 'ACTIVE')
				.map((matchRuleTemplate: MatchRuleTemplate) => computeHash(matchRuleTemplate))
			setTemplateMatchRulesHash(
				hashes
					.filter((hash: number) => hash !== undefined)
					.sort()
					.join('|')
			)
		}
		/**
		 * We only want to run this effect when isFetching flag from matchTemplateQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchTemplateQuery.isFetching])

	const updateDefaultRuleNames = () => {
		let idx = 1
		while (idx < currentMatchRules.length) {
			const updatedMatchRule = projectWizardState.currentProject.matchRules[idx]
			if (updatedMatchRule.matchRule?.displayName?.startsWith(t('matching.step.default.new.rule.name'))) {
				projectWizardState.currentProject.matchRules[idx].matchRule.displayName = t(
					'matching.step.default.new.rule.name',
					{ i: idx + 1 }
				)
				dispatch(updateMatchRule(idx, updatedMatchRule))
			}
			idx++
		}
	}

	useEffect(() => {
		const lastTab = projectWizardState.currentProject.matchRules.length
		const lastTabDeleted = tabRules > lastTab
		if (lastTabDeleted) {
			setTabRules(lastTab)
		} else if (addedNewTab) {
			setTabRules(lastTab)
			setAddedNewTab(false)
		}
		// We need this code to get executed only when the matchRules array has been updated
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectWizardState.currentProject.matchRules.length])

	/**
	 * TODO: eslint recommends adding "projectWizardState.currentProject.matchRules" as a dependency to the useEffect
	 * below to avoid an infinite chain of updates, at the moment it is left as it was originally due to reverting
	 * changes in dependencies but it remains to be reviewed.
	 */
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setMatchRulesHash(
			projectWizardState.currentProject.matchRules
				.map((matchRuleTemplate) => computeHash(matchRuleTemplate.matchRule))
				.sort()
				.join('|')
		)
	})

	useEffect(() => {
		const rulesHaveChanged = matchConfigTemplate === undefined || matchRulesHash !== templateMatchRulesHash
		setSaveAsTemplateButtonActive(rulesHaveChanged)
		setUpdateTemplateButtonActive(
			!!matchConfigTemplate?.templateId && matchConfigTemplate?.createdBy === currentUser && rulesHaveChanged
		)
		/**
		 * We only want to run this effect when matchRulesHash or templateMatchRulesHash changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchRulesHash, templateMatchRulesHash])

	useEffect(() => {
		if (modalProps.option === `${t('matching.step.modal.delete')}`) {
			updateDefaultRuleNames()
		}
		/**
		 * We only want to run this effect when the modalProps changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalProps])

	const onDeleteRule = (idx: number) => {
		if (idx > 1) {
			setShowModalProps({
				show: true,
				content: `${t('matching.step.modal.delete.confirmation')} ${
					projectWizardState.currentProject.matchRules[idx - 1].matchRule.displayName
				} \u003F`,
				hide: t('matching.step.modal.cancel'),
				action: `${t('matching.step.modal.delete')} ${
					projectWizardState.currentProject.matchRules[idx - 1].matchRule.displayName
				}`,
				option: t('matching.step.modal.delete'),
				idx: idx - 1,
				onlyHideButton: false
			})
		}
	}
	return (
		<Grid testId="container-matching" container>
			<Grid testId="matching-panel">
				<div className={styles.matchingPanel}>
					<TemplateSavingModal
						isOpen={savingIsOpen}
						onClose={() => setSavingIsOpen(false)}
						templateExist={
							matchConfigTemplate !== undefined &&
							matchConfigTemplate.templateId !== undefined &&
							matchConfigTemplate.createdBy === currentUser
						}
						onSave={saveMatchConfigTemplate}
						testId="save-match-template-modal"
						title={t('matching.template.modal.title')}
						inputLabel={t('matching.template.modal.input.label')}
						inputHint={t('matching.template.modal.input.hint')}
						btnText={t('matching.template.modal.save')}
						updateDescription={t('matching.template.modal.update.description')}
						templateName={matchConfigTemplate?.templateName}
					/>
					{onClickBackToLibrary ? (
						<div
							data-testid="back-to-library"
							className={styles.backToLibraryContainer}
							onClick={onClickBackToLibrary}
						>
							<span className={styles.icon}>
								<Icon
									testId="arrow-back-matching-step"
									type="arrow-back"
									color={ColorBluePrimaryDark}
								/>
							</span>
							<div className={styles.text}>{t('enriching.step.button.backToLibrary')}</div>
						</div>
					) : undefined}
					{readonly && matchTitle ? (
						<div data-testid="match-template-title" className={styles.matchTemplateTitle}>
							{matchTitle}
						</div>
					) : undefined}
					<div data-testid="delete-match-rule-modal-container">
						<Modal
							open={modalProps.show}
							onClose={() =>
								setShowModalProps({
									...modalProps,
									show: false
								})
							}
							testId="RenameMatchRuleModal"
						>
							{modalProps.content !== '' ? (
								modalProps.content
							) : (
								<div>
									<p>{t('matching.step.modal.rename.header')}</p>
									<Input
										id="input"
										hint={t('matching.step.modal.rename.placeholder')}
										helperText={''}
										onChangeFunction={setRenameInput}
										value={renameInput}
										label={t('matching.step.modal.rename.label')}
										testId="match-input-rename-input"
									/>
								</div>
							)}
							<div className={styles.matchResetButtonsContainer}>
								<Button
									size="large"
									type="primary"
									text={modalProps.action}
									onClick={actionModal}
									testId="match-input-rename-btn"
								/>
								{modalProps.hide && (
									<Button
										size="medium"
										type="secondary"
										text={modalProps.hide}
										onClick={() =>
											setShowModalProps({
												...modalProps,
												show: false
											})
										}
										testId="match-input-rename-cancel-btn"
									/>
								)}
							</div>
						</Modal>
					</div>
					<RenamingModal
						open={isRenameModalOpen}
						label={t('matching.step.modal.rename.label')}
						hint={t('matching.step.modal.rename.placeholder')}
						actionLabel={t('matching.step.modal.save.changes')}
						onRename={handleRuleRename}
						testId="match-input-rename-modal"
					/>
					<div className={styles.panelHeaderContainer}>
						{!readonly && (
							<div className={styles.stepTitleWrapper}>
								<h1 data-testid="matching-step-title-rules-creation">{t('file.matching.line.1')}</h1>
							</div>
						)}
						{!readonly && saveAsTemplateButtonActive && isMatchRulesReadyToValidate(currentMatchRules) && (
							<div className={styles.saveTemplateButtonWrapper}>
								<DNBButton
									size="default"
									variant="secondary"
									onClick={() => onTemplateSave()}
									data-testid="save-template-button"
								>
									{t('matching.step.button.save.template')}
								</DNBButton>
							</div>
						)}
					</div>
					{matchConfigTemplate !== undefined && matchConfigTemplate.templateName && !readonly ? (
						<div className={styles.templateUpdateContainer}>
							{matchConfigTemplate.templateName}
							{!readonly &&
								updateTemplateButtonActive &&
								isMatchRulesReadyToValidate(currentMatchRules) && (
									<a data-testid="update-template" onClick={() => onTemplateUpdate()}>
										{t('matching.step.update.template')}
									</a>
								)}
						</div>
					) : undefined}
					<div className={styles.matchingTabContainer}>
						<div className={styles.stepInformationContainer}>
							{tabRules ? (
								<>
									{!onClickBackToLibrary && (
										<MatchYourDataHeader
											sourceName={projectWizardState.currentProject.source.name}
											isPartialMatchEnabled={
												projectWizardState.currentProject.source.entityType ===
												EntityType.CONTACTS
											}
											partialMatch={
												projectWizardState.currentProject.source?.enable_partial_match
											}
											onChange={onChangePartialMatch}
											testId={'match-your-data'}
										/>
									)}
								</>
							) : (
								<div className={styles.stepInformationTextContainer}>
									<p data-testid="step-information-no-rules-line-1">{t('file.matching.line.2')}</p>
									<p data-testid="step-information-no-rules-line-2-test-id">
										{t('file.matching.line.3')}
									</p>
								</div>
							)}
							{projectWizardState.currentProject.source.enableAme && (
								<div className={styles.ameInformationContainer}>
									<img
										className={styles.informationIcon}
										src={informationIcon}
										alt={'information-icon'}
									/>
									<span
										className={styles.ameInformationTextWrapper}
										data-testid="ame-information-test-id"
									>
										<b data-testid="ame-information-bold-test-id">
											{t('file.matching.ame.information.line1')}
										</b>
										{t('file.matching.ame.information.line2')}
									</span>
								</div>
							)}
						</div>
						{/* this should be refactored but currently the actions are tied into the index order of the tabs */}
						<div className={styles.rulesTabContainer}>
							{projectWizardState.currentProject.matchRules.length > 0 && (
								<>
									<Tabs
										type="default"
										minTabWidth={185.5}
										key="tabsRules"
										span
										wrap
										maxVisibleTabs={5}
										onEditTab={readonly ? undefined : onRuleNameChange}
										onDeleteTab={readonly || tabRules === 1 ? undefined : onDeleteRule}
										value={tabRules}
										onChange={(idx) => setTabRules(idx)}
										testId="tc-matching-rule"
									>
										{!readonly && (
											<div className={styles.newMatchRule}>
												<Tab
													id={'container-add-button'}
													key={'container button'}
													aria-controls={''}
													selectable={false}
												>
													<Button
														text={t('matching.step.button.text')}
														onClick={() => addNewRule()}
														testId={'add-rule-btn'}
														iconType={'plus'}
														aria-controls={`panelNewRule`}
														iconColor={ColorBlueBrand}
													/>
												</Tab>
											</div>
										)}
										{projectWizardState.currentProject.matchRules.map((value, index) => {
											const colorIdx = index % ruleColorList.length
											const color = ruleColorList[colorIdx]
											const hoverColor = hoverRuleColorList[colorIdx]
											const tabColors = {
												defaultColor: color,
												hoverColor: hoverColor
											}
											const newIdx = index + 1
											return (
												<Tab
													label={value.matchRule.displayName as string}
													key={`tab ${newIdx}`}
													tabColor={tabColors}
													id={`tabRule-${newIdx}`}
													aria-controls={`panelRule-${newIdx}`}
												/>
											)
										})}
									</Tabs>
									{projectWizardState.currentProject.matchRules.map((value, index) => {
										const colorIdx = index % ruleColorList.length
										const color = ruleColorList[colorIdx]
										const newIdx = readonly ? index : index + 1
										return (
											<TabPanel
												key={`tabPanel-${newIdx}`}
												id={`panelRule-${newIdx}`}
												value={tabRules}
												index={newIdx}
												aria-labelledby={`tabRule-${newIdx}`}
												testId="container-matching"
											>
												<RuleItem
													value={value}
													index={index}
													key={`rule-${index}`}
													setConfidenceSliderValue={setConfidenceSliderValue}
													updateMatchGradePatterns={setMatchGradePatterns}
													updateMatchType={setMatchType}
													updateExclusionCriterion={setExclusionCriterion}
													updateAllowedCountries={setAllowedCountries}
													disabledCountries={getDisabledCountries(index)}
													setSelectedTab={() => setTabRules(newIdx)}
													color={color}
													testId={'rule-item-' + index}
													readonly={readonly}
													initialMatchRule={
														projectWizardState.currentProject.initialMatchRules[index]
													}
													showGeographicalDistributionDialogBox={
														showGeographicalDistributionDialogBox
													}
													onGeographicalDistributionDialogBoxClose={
														onGeographicalDistributionDialogBoxClose
													}
												/>
											</TabPanel>
										)
									})}
								</>
							)}
						</div>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}
