import cx from 'classnames'
import { camelCase, isEmpty, isEqual } from 'lodash-es'
import { KeyboardEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import { getParentDepth } from '../helpers/getParentDepth'
import Icon from '../icon/icon'
import { ZDepthDropdown } from '../shared/tokens.json'
import ToolTip from '../tooltip/tooltip'
import styles from './dropdown.module.scss'
import { OptionList } from './optionList'

export interface DropdownOptions {
	label: string
	value: string
	disabled?: boolean
}

export interface IInputProps {
	id: string
	hint: string
	onChangeFunction(selection: string): void
	label: string
	options: Array<DropdownOptions>
	size?: 'mini' | 'small' | 'medium' | 'large' | 'fluid'
	disabled?: boolean
	typeAhead?: boolean
	isRequired?: boolean
	requiredIndicator?: 'asterisk' | 'star'
	labelPosition?: 'left' | 'top'
	selected?: string
	canUnselect?: boolean
	unselectText?: string
	errorMessage?: string
	isValid?: boolean
	visibleItems?: number
	showCheckIcon?: boolean
	insideForm?: boolean
	onBlurFunction?(selection: string): void
	dropdownOverflow?: 'scroll' | 'show'
	color?: string
	testId: string
	dropdownContainerClassName?: string
	dropdownListClassName?: string
	warningIndicator?: boolean
	isDynamicRequired?: boolean
}

export interface Coords {
	top: number
	right: number
	bottom: number
	left: number
}

const getCoords = (element: HTMLElement | null): Coords => {
	if (element) {
		const box = element.getBoundingClientRect()
		return {
			top: box.top + window.scrollY,
			right: box.right + window.scrollX,
			bottom: box.bottom + window.scrollY,
			left: box.left + window.scrollX
		}
	} else {
		return {
			top: window.scrollY,
			right: window.scrollX,
			bottom: window.scrollY,
			left: window.scrollX
		}
	}
}

export const Dropdown = ({
	id,
	hint,
	onChangeFunction,
	label,
	options,
	size = 'small',
	disabled = false,
	typeAhead = false,
	isRequired = false,
	requiredIndicator = 'asterisk',
	labelPosition = 'top',
	selected: selectedProp,
	canUnselect = false,
	unselectText,
	errorMessage,
	isValid = false,
	visibleItems = 6,
	showCheckIcon = true,
	insideForm = false,
	onBlurFunction,
	dropdownOverflow = 'scroll',
	color,
	testId,
	dropdownContainerClassName,
	dropdownListClassName,
	warningIndicator = false,
	isDynamicRequired = false
}: IInputProps): ReactElement => {
	type selectionOption = { value: string; index: number }
	const initialSelection = {
		value: '',
		index: -1
	}
	const myId = useMemo(() => Math.floor(Math.random() * 1000 + 1).toString(), [])

	const [showList, setShowList] = useState<boolean>(false)
	const [selectedOption, setSelectedOption] = useState<selectionOption>(initialSelection)
	const [dropdownOptions, setDropdownOptions] = useState<Array<DropdownOptions>>(options)
	const [activeElement, setActiveElement] = useState<number>(0)
	const [tooltip, setTooltip] = useState<string>()

	const topParentRef = useRef<HTMLDivElement | null>(null)
	const dropdownEl = useRef(null)
	const inputEl = useRef<HTMLInputElement | null>(null)
	const buttonEl = useRef<HTMLButtonElement | null>(null)

	const liRef = useRef<HTMLLIElement | null>(null)
	const labelRef = useRef<HTMLLabelElement | null>(null)

	const validationIcon: { type?: 'alert-big' | 'check-circle'; color?: string } = errorMessage
		? {
				type: 'alert-big',
				color: 'ColorOrangeBurnt'
		  }
		: isValid
		? { type: 'check-circle', color: 'ColorScaleDarkGreen' }
		: {}

	const selectOption = (option: DropdownOptions, index: number) => {
		let currentSelected
		if (option.label === unselectText) {
			currentSelected = initialSelection
		} else {
			currentSelected = { value: option.label, index }
		}

		setSelectedOption(currentSelected)
		inputEl.current?.focus()
		setShowList(false)
		onChangeFunction(option.value)
		if (onBlurFunction) {
			onBlurFunction(option.value)
		}
	}

	const typeAheadChange = (value: string) => {
		const newOptions = options.filter((option) => option.label.toLowerCase().indexOf(value.toLowerCase()) > -1)
		setSelectedOption({ value, index: 0 })
		setActiveElement(-1)
		setDropdownOptions(newOptions)
		if (!showList) {
			setShowList(true)
		}
	}

	const getNextItem = (currentElement: number): number => {
		return dropdownOptions.findIndex((dropOpt, index) => index > currentElement && !dropOpt.disabled)
	}

	const getPreviousItem = (currentElement: number): number => {
		const reverseOptions = [...dropdownOptions].reverse()
		const newIndex = reverseOptions.findIndex(
			(dropOpt, index) => index > Math.abs(currentElement - options.length + 1) && !dropOpt.disabled
		)
		return newIndex < 0 ? newIndex : Math.abs(newIndex - options.length + 1)
	}

	const handleKeyOnOptionList = (event: KeyboardEvent, idx: number, liRef: Array<HTMLLIElement | null>) => {
		if (liRef[0] !== null) {
			if (event.key === 'ArrowDown') {
				const nextItem = getNextItem(idx)
				if (nextItem >= 0) {
					event.preventDefault()
					liRef[nextItem]?.focus()
				}
			} else if (event.key === 'ArrowUp') {
				const previousItem = getPreviousItem(idx)
				if (previousItem >= 0) {
					event.preventDefault()
					liRef[previousItem]?.focus()
				} else if (typeAhead) {
					inputEl.current?.focus()
					setActiveElement(-1)
				}
			} else if (event.key === 'Escape') {
				setShowList(false)
			} else if (event.key !== 'Tab' && event.key !== 'Shift' && typeAhead) {
				inputEl.current?.focus()
			}
			if (event.key === 'Tab' && !event.shiftKey && idx === dropdownOptions.length - 1) {
				setShowList(false)
			}
			if (typeAhead && event.key === 'Enter') {
				const optionLabel = liRef[idx]?.textContent || ''
				const currentSelectedOption = dropdownOptions.find((option) => option.label === optionLabel)
				if (currentSelectedOption) selectOption(currentSelectedOption, idx)
			}
		}
	}

	const handleEnterOnOptionList = (event: KeyboardEvent, idx: number, liRef: Array<HTMLLIElement | null>): void => {
		if (liRef[0] !== null && event.key === 'Enter') {
			const optionLabel = liRef[idx]?.textContent || ''
			const currentSelectedOption = dropdownOptions.find((option) => option.label === optionLabel)
			if (currentSelectedOption?.disabled) {
				return
			}
			if (currentSelectedOption) selectOption(currentSelectedOption, idx)
		}
	}

	const toggleShowList = () => {
		if (canUnselect && unselectText) {
			if (!dropdownOptions.some((option) => option.label === unselectText)) {
				dropdownOptions.unshift({ label: unselectText, value: '' })
				setDropdownOptions(dropdownOptions)
			}
		} else {
			setDropdownOptions(options)
		}
		setShowList(!showList)
	}

	useEffect(() => {
		const optionIndex = options.findIndex((option) => isEqual(selectedProp, option.value))
		if (optionIndex >= 0) {
			setSelectedOption({ value: options[optionIndex].label, index: optionIndex })
		} else {
			setSelectedOption(initialSelection)
		}
	}, [selectedProp])

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
			event.preventDefault()
			if (!showList) {
				setShowList(true)
			}
			if (event.key === 'ArrowDown' && showList && liRef.current !== null) {
				liRef.current.focus()
			}
		} else if (showList && (event.key === 'Escape' || event.key === 'Tab')) {
			setShowList(false)
		}
	}

	const getLeftIcon = () => {
		const iconVisible = !isEmpty(validationIcon)
		const iconType = (iconVisible ? validationIcon.type : 'check-circle') || 'check-circle'
		if (labelPosition === 'left') {
			if (isRequired && requiredIndicator === 'star') {
				return (
					<div
						data-testid={`dropdown-icon-left-container-${testId}`}
						className={cx(styles.dropdownIconLeft, styles.iconVisible)}
					>
						{warningIndicator && (
							<div className={styles.positionIconAlert} aria-label={'warning'}>
								<Icon
									testId={`dropdown-icon-left-${testId}`}
									type={'warning'}
									size="mini"
									color={'ColorRed'}
								/>
							</div>
						)}
						{isDynamicRequired ? (
							selectedOption.value === '' ? (
								<Icon
									testId={`dropdown-icon-left-${testId}`}
									type={'star'}
									size="mini"
									color={'ColorGrayDarker'}
								/>
							) : (
								<Icon
									testId={`dropdown-icon-left-${testId}`}
									type={'star-solid'}
									size="mini"
									color={'ColorGrayDarker'}
								/>
							)
						) : (
							<Icon
								testId={`dropdown-icon-left-${testId}`}
								type={'star-solid'}
								size="mini"
								color={'ColorGrayDarker'}
							/>
						)}
					</div>
				)
			} else {
				if (size === 'fluid') {
					return (
						<div
							className={cx(
								styles.dropdownIconLeft,
								iconVisible ? styles.iconVisible : styles.iconInvisible
							)}
							data-testid={`dropdown-container-icon-fluid-${testId}`}
						>
							{errorMessage ? (
								<ToolTip
									text={errorMessage}
									effect="solid"
									position="left"
									testId={testId + '-tooltip-icon'}
								>
									<Icon
										testId={`dropdown-error-icon-${testId}`}
										type={iconType}
										size="mini"
										color={validationIcon.color}
									/>
								</ToolTip>
							) : (
								<Icon
									testId={`dropdown-validation-icon-${testId}`}
									type={iconType}
									size="mini"
									color={validationIcon.color}
								/>
							)}
						</div>
					)
				} else {
					if (!isEmpty(validationIcon)) {
						return (
							<div
								data-testid={`dropdown-container-tooltip-${testId}`}
								className={styles.dropdownIconLeft}
							>
								{errorMessage ? (
									<ToolTip
										text={errorMessage}
										effect="solid"
										position="left"
										testId={testId + '-tooltip-icon'}
									>
										<Icon
											type={validationIcon.type || 'check-circle'}
											size="mini"
											color={validationIcon.color}
											testId={`dropdown-icon-tooltip${testId}`}
										/>
									</ToolTip>
								) : (
									<Icon
										type={validationIcon.type || 'check-circle'}
										size="mini"
										color={validationIcon.color}
										testId={`dropdown-icon-general-${testId}`}
									/>
								)}
							</div>
						)
					}
				}
			}
		}
	}

	useEffect(() => {
		setDropdownOptions(options)
	}, [JSON.stringify(options)])

	useEffect(() => {
		setTooltip(
			labelRef.current && labelRef.current?.scrollWidth > labelRef.current?.offsetWidth ? label : undefined
		)
	}, [label])

	const dropdownCoords = getCoords(dropdownEl.current)

	return (
		<>
			<div
				data-testid={`dropdown-container-general-${testId}`}
				className={styles[camelCase(`dropdown-label-${labelPosition}`)]}
				ref={topParentRef}
			>
				<div
					data-testid={`dropdown-label-tooltip-${testId}`}
					className={cx(styles[camelCase(`label-wrapper-${size}`)], styles.labelContainer)}
				>
					<ToolTip
						text={tooltip}
						effect="solid"
						position={labelPosition === 'left' ? 'right' : 'bottom'}
						testId={testId + '-tooltip-label'}
					>
						<label
							ref={labelRef}
							style={{ color }}
							className={styles.labelInput}
							htmlFor={'dropdown-component-' + id}
							data-testid={`dropdown-label-${testId}`}
						>
							{label}
						</label>
					</ToolTip>
					{isRequired && requiredIndicator === 'asterisk' && (
						<div data-testid={`dropdown-required-${testId}`} className={styles.requiredSymbol}>
							&nbsp;*
						</div>
					)}
				</div>
				<div
					data-testid={`dropdown-wrapper-${testId}`}
					className={size === 'fluid' ? cx(styles.dropdownWrapper, styles.fluid) : styles.dropdownWrapper}
				>
					{getLeftIcon()}
					<div
						id={id}
						className={
							size === 'fluid' ? cx(styles.dropdownComponent, styles.fluid) : styles.dropdownComponent
						}
						ref={dropdownEl}
						data-testid={`dropdown-component-${testId}`}
					>
						<div
							id={'dropdown-component-' + id}
							className={cx(
								styles.dropdown,
								styles[size],
								{
									[styles.dropdownDisabled]: disabled,
									[styles.borderError]: !isValid && insideForm
								},
								dropdownContainerClassName
							)}
							role="select"
							aria-expanded={showList}
							aria-haspopup="listbox"
							aria-controls={'dropdown-list-' + id}
							data-testid={`dropdown-displayed-list-${testId}`}
						>
							<input
								className={cx(styles.inputValue, styles.dropdownInput, {
									[styles.typeaheadDisable]: !typeAhead
								})}
								tabIndex={typeAhead ? 0 : -1}
								ref={inputEl}
								placeholder={hint}
								value={selectedOption.value}
								onChange={(event) => {
									typeAhead && typeAheadChange(event.target.value)
								}}
								onClick={toggleShowList}
								onKeyDown={(event) => handleKeyDown(event)}
								disabled={disabled}
								role={typeAhead ? 'searchbox' : 'button'}
								autoComplete={typeAhead ? 'on' : 'off'}
								aria-autocomplete={typeAhead ? 'list' : 'none'}
								data-testid={testId + '-body'}
							/>
							<button
								className={styles.arrowButton}
								ref={buttonEl}
								onClick={toggleShowList}
								onKeyDown={(event) => handleKeyDown(event)}
								disabled={disabled}
								data-testid={testId + '-arrow'}
							>
								<div data-testid={`dropdown-arrow-${testId}`} className={styles.arrow} />
							</button>
						</div>
					</div>
					{!isEmpty(validationIcon) && labelPosition === 'top' && (
						<div
							data-testid={`dropdown-right-icon-container-${testId}`}
							className={styles.dropdownIconRight}
						>
							{errorMessage ? (
								<ToolTip
									text={errorMessage}
									effect="solid"
									position="right"
									testId={testId + '-tooltip-validation'}
								>
									<Icon
										type={validationIcon.type || 'check-circle'}
										size="mini"
										color={validationIcon.color}
										testId={`dropdown-right-error-icon-${testId}`}
									/>
								</ToolTip>
							) : (
								((validationIcon.type === 'check-circle' && showCheckIcon) ||
									validationIcon.type != 'check-circle') && (
									<Icon
										type={validationIcon.type || 'check-circle'}
										size="mini"
										color={validationIcon.color}
										testId={`dropdown-right-validation-icon-${testId}`}
									/>
								)
							)}
						</div>
					)}
				</div>
			</div>
			<OptionList
				options={dropdownOptions}
				testId={`${testId}-options`}
				className={dropdownListClassName}
				id={`${myId}-optionList`}
				show={showList}
				onHide={() => setShowList(false)}
				visibleItems={visibleItems}
				size={size}
				overflow={dropdownOverflow}
				onSelect={selectOption}
				onHandleKey={handleKeyOnOptionList}
				onHandleEnter={handleEnterOnOptionList}
				parentTop={dropdownCoords.top}
				parentLeft={dropdownCoords.left}
				zIndex={getParentDepth(topParentRef.current, ZDepthDropdown)}
				ref={liRef}
			/>
		</>
	)
}
export default Dropdown
