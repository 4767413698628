import { AxiosInstance, AxiosResponse } from 'axios'

export interface VersionResponse {
	version: string
}

export const getSFPackageVersion = async (
	apiClient: AxiosInstance,
	systemType?: string,
	orgId?: string,
	versionSF?: string
): Promise<VersionResponse> => {
	if (versionSF !== undefined) {
		return { version: versionSF }
	} else if (systemType && orgId) {
		const url = `/pls/integration/${systemType}/organization/${orgId}/getPackageVersion`
		return apiClient.get(url).then((response: AxiosResponse<VersionResponse>) => response.data)
	} else return { version: '' }
}
