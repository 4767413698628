import cx from 'classnames'
import { isEmpty } from 'lodash-es'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import deleteIcon from '../../assets/images/DeleteIcon.png'
import alternateElementsRequired from '../../project/steps/mapping-v2/alternate-elements-required.json'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { setAlternateInfoAction, updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { MapFieldDefinition } from '../../types'
import { handlerCounterByGroupMapping } from '../../types/sources/HandlerCounterByGroupMapping'
import { DropdownCollection, DropdownCollectionRow } from '../dropdown-collection/dropdown-collection'
import { CollapsableDropdownCollectionHeader } from './collapsable-dropdown-collection-header'
import styles from './collapsable-dropdown-collection.module.scss'

export type DropdownCollectionDescription = JSX.Element | string | undefined

export interface ICollapsableDropdownCollectionProps {
	title: string
	onChangeFunction(rows: Array<DropdownCollectionRow>): void
	description?: DropdownCollectionDescription
	unselectText?: string
	rows: Array<DropdownCollectionRow>
	initialStateCollapsed?: boolean
	testId: string
	collectionId: string
	alternativeFields?: Record<string, string>
	disabled?: boolean
	additionalChecksCount?: number
	additionalChecksSatisfied?: number
	isAlternateCompanyExpanded?: boolean
	isAlternateAddressExpanded?: boolean
	haveAlternateRegistrationNumber?: boolean
	enableAlternateFieldsRecomm: boolean
	companyInfo: Array<MapFieldDefinition>
	showWarningField?: boolean
	additionalConfigGroup?: JSX.Element | undefined
}

export const CollapsableDropdownCollection = ({
	title,
	onChangeFunction,
	unselectText = 'remove selection',
	rows,
	initialStateCollapsed,
	testId,
	collectionId,
	description,
	alternativeFields = undefined,
	disabled = false,
	additionalChecksCount = 0,
	additionalChecksSatisfied = 0,
	isAlternateCompanyExpanded = false,
	isAlternateAddressExpanded = false,
	haveAlternateRegistrationNumber = false,
	enableAlternateFieldsRecomm,
	companyInfo,
	showWarningField = false,
	additionalConfigGroup
}: ICollapsableDropdownCollectionProps): ReactElement => {
	const { t } = useTranslation()
	const collapsed = initialStateCollapsed !== undefined ? initialStateCollapsed : true
	const [isCollapsed, setIsCollapsed] = useState(collapsed)
	const projectWizardSelector = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(projectWizardSelector)
	const fieldDefinitionsRecordsMap =
		projectWizardState?.currentProject?.mappingInfo?.mapping?.currentFieldDefinitionsRecord
			.fieldDefinitionsRecordsMap
	const [isAddressExpanded, setIsAddressExpanded] = useState<boolean>(isAlternateAddressExpanded)
	const [isCompanyExpanded, setIsCompanyExpanded] = useState<boolean>(isAlternateCompanyExpanded)
	const [isAltRegNumExpanded, setIsAltRegNumExpanded] = useState<boolean>(haveAlternateRegistrationNumber)
	const [someFieldEdited, setSomeFieldEdited] = useState(false)
	const [totalRequired, setTotalRequired] = useState(0)
	const [fieldsRequiredSatisfied, setFieldsRequiredSatisfied] = useState(0)
	const dispatch = useAppDispatch()
	let alternateAddressInfo = []
	let alternateCountryInfo = []
	const validateAlternateCountry = () => {
		alternateAddressInfo = companyInfo
			.filter((row: MapFieldDefinition) => {
				return row.fieldName?.toLowerCase().includes('alternate') && row.columnName !== undefined
			})
			.filter((row) => {
				return !row.fieldName?.toLowerCase().includes('company')
			})
		alternateCountryInfo = companyInfo
			.filter((row) => {
				return row.fieldName?.toLowerCase().includes('alternate') && row.columnName !== undefined
			})
			.filter((row) => {
				return row.fieldName?.toLowerCase().includes('country')
			})
		return alternateCountryInfo.length > 0 ? false : alternateAddressInfo.length > 0
	}
	const showAlternateCountryAlert = validateAlternateCountry()
	const toggleCollapsed = () => {
		setIsCollapsed(!isCollapsed)
	}

	const countingAlternatives = (
		dataRows: Array<DropdownCollectionRow>,
		countRequired: number,
		countFieldsSatisfied: number
	) => {
		if (alternativeFields) {
			dataRows.forEach((row, index) => {
				if (Object.keys(alternativeFields).includes(row.id)) {
					const alternativeKey = Object.entries(alternativeFields).find((value) => value[0] === row.id)
					if (alternativeKey !== undefined) {
						const positionAlternative = dataRows
							.map((row) => {
								return row.id
							})
							.indexOf(alternativeKey[1])
						const alternativeField = dataRows[positionAlternative]
						if (index < positionAlternative) {
							countRequired--
						}
					}
				}
			})
		}
		setTotalRequired(countRequired + additionalChecksCount)
		setFieldsRequiredSatisfied(countFieldsSatisfied + additionalChecksSatisfied)
	}

	const getDataFields = (dataRows: Array<DropdownCollectionRow>) => {
		let countRequired = 0
		let countFieldsSatisfied = 0
		let someEdited = false
		dataRows.forEach((row) => {
			if (row.isRequired || (alternateElementsRequired.includes(row.id) && row.selected)) {
				countRequired++
				if (row.selected) {
					countFieldsSatisfied++
					someEdited = true
				}
			} else if (row.selected) {
				someEdited = true
			}
		})
		countingAlternatives(dataRows, countRequired, countFieldsSatisfied)
		setSomeFieldEdited(someEdited)
	}

	const hash = [...rows.map((row) => row.selected)].join()
	const companyRows = rows.filter((r) => r.id.includes('CompanyName'))
	const phoneRows = rows.filter((r) => r.id.includes('PhoneNumber'))
	const regNumberRows = rows.filter((r) => r.id.includes('RegistrationNumber'))
	const otherRows = rows.reduce(
		(previousValue: Array<DropdownCollectionRow>, currentValue: DropdownCollectionRow) => {
			if (currentValue !== undefined && !regNumberRows.some((x) => x.id === currentValue.id)) {
				return previousValue.concat(currentValue)
			}
			return previousValue
		},
		[] as Array<DropdownCollectionRow>
	)

	const toggleCompanyInfo = () => {
		isCompanyExpanded ? clearAlternateCompanyMap() : mapAlternateCompany()
		dispatch(
			setAlternateInfoAction({
				alternateCompanyExpanded: !isCompanyExpanded,
				alternateAdrsExpanded: isAddressExpanded,
				alternateRegNumExpanded: isAltRegNumExpanded
			})
		)
		setIsCompanyExpanded(!isCompanyExpanded)
	}

	const toggleRegNum = () => {
		isAltRegNumExpanded ? clearAlternateRegNumMap() : mapAlternateRegNum()
		dispatch(
			setAlternateInfoAction({
				alternateCompanyExpanded: isCompanyExpanded,
				alternateAdrsExpanded: isAddressExpanded,
				alternateRegNumExpanded: !isAltRegNumExpanded
			})
		)
		setIsAltRegNumExpanded(!isAltRegNumExpanded)
	}

	const mapAlternateCompany = () => {
		const AlternatesAutomap = JSON.parse(localStorage.getItem('AlternatesAutomap'))
		const companyInfo = fieldDefinitionsRecordsMap?.companyInformation
		!isEmpty(AlternatesAutomap)
		{
			fieldDefinitionsRecordsMap?.companyInformation.forEach((row) => {
				const autoMappedColumn = AlternatesAutomap[row.fieldName || '']
				const isMapped = companyInfo.filter((row) => {
					return row.columnName === autoMappedColumn
				})
				isMapped.length === 0 && autoMappedColumn && row.fieldName?.toLowerCase().includes('company')
					? (row.columnName = autoMappedColumn)
					: ''
			})
		}
	}

	const mapAlternateRegNum = () => {
		const AlternatesAutomap = JSON.parse(localStorage.getItem('AlternatesAutomap'))
		const registrationNumber = fieldDefinitionsRecordsMap?.registrationNumber
		!isEmpty(AlternatesAutomap)
		{
			fieldDefinitionsRecordsMap?.registrationNumber.forEach((row) => {
				const autoMappedColumn = AlternatesAutomap[row.fieldName || '']
				const isMapped = registrationNumber.filter((row) => {
					return row.columnName === autoMappedColumn
				})
				isMapped.length === 0 && autoMappedColumn && row.fieldName?.toLowerCase().includes('registrationnumber')
					? (row.columnName = autoMappedColumn)
					: ''
			})
		}
	}

	const mapAlternateAddress = () => {
		const AlternatesAutomap = JSON.parse(localStorage.getItem('AlternatesAutomap'))
		const companyInfo = fieldDefinitionsRecordsMap?.companyInformation
		!isEmpty(AlternatesAutomap)
		{
			fieldDefinitionsRecordsMap?.companyInformation.forEach((row) => {
				const autoMappedColumn = AlternatesAutomap[row.fieldName || '']
				const isMapped = companyInfo.filter((row) => {
					return row.columnName === autoMappedColumn
				})
				isMapped.length === 0 && autoMappedColumn && !row.fieldName?.toLowerCase().includes('company')
					? (row.columnName = autoMappedColumn)
					: ''
			})
		}
	}

	const clearAlternateCompanyMap = () => {
		const AlternatesAutomap = JSON.parse(localStorage.getItem('AlternatesAutomap'))
		fieldDefinitionsRecordsMap?.companyInformation.forEach((row) => {
			if (row.fieldName === 'Alternate_CompanyName') {
				row.columnName = undefined
				delete AlternatesAutomap[row.fieldName]
				localStorage.setItem('AlternatesAutomap', JSON.stringify(AlternatesAutomap))
			}
		})
	}

	const clearAlternateRegNumMap = () => {
		const AlternatesAutomap = JSON.parse(localStorage.getItem('AlternatesAutomap'))
		fieldDefinitionsRecordsMap?.registrationNumber.forEach((row) => {
			if (row.fieldName === 'Alternate_RegistrationNumber') {
				row.columnName = undefined
				delete AlternatesAutomap[row.fieldName]
				localStorage.setItem('AlternatesAutomap', JSON.stringify(AlternatesAutomap))
			}
		})
	}

	const clearAlternateAdrsMap = () => {
		fieldDefinitionsRecordsMap?.companyInformation.forEach((row) => {
			row.fieldName?.toLowerCase().includes('alternate') && !row.fieldName?.toLowerCase().includes('company')
				? (row.columnName = undefined)
				: ''
		})
	}

	useEffect(() => {
		const groupsCounter: handlerCounterByGroupMapping | undefined =
			projectWizardState?.currentProject.mappingInfo.counterGroupsMapping || undefined
		if (groupsCounter) {
			groupsCounter[`${collectionId}`] = {
				fieldsRequiredFull: fieldsRequiredSatisfied,
				totalRequired: totalRequired
			}
			dispatch(
				updateCurrentProjectAction({
					mappingInfo: {
						...projectWizardState.currentProject.mappingInfo,
						counterGroupsMapping: groupsCounter
					}
				})
			)
		}
	}, [fieldsRequiredSatisfied, totalRequired])

	useEffect(() => {
		getDataFields(rows)
		/**
		 * getDataFields is not added as a dependency because it would cause the other dependencies to change with
		 * each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash, additionalChecksSatisfied, isAddressExpanded, isAltRegNumExpanded])

	return (
		<div className={styles.collapsableDropdownCollection}>
			<CollapsableDropdownCollectionHeader
				title={title}
				collapsed={isCollapsed}
				onToggleCollapsed={toggleCollapsed}
				totalRequired={totalRequired}
				someFieldEdited={someFieldEdited}
				satisfiedRequirements={fieldsRequiredSatisfied}
				testId={testId}
			/>
			{!isCollapsed && (
				<>
					{description && (
						<div
							className={cx(styles.collapsableCollectionDescription, {
								[styles.descriptionStringType]: description && typeof description === 'string'
							})}
						>
							{description}
						</div>
					)}
					<div
						className={cx(styles.collapsableRowSection, {
							[styles.collectionEdited]: someFieldEdited
						})}
					>
						{companyRows.length > 1 ? (
							<>
								{/*Alternate simple*/}
								<DropdownCollection
									collectionId={collectionId}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
										getDataFields(rowsChanged)
										onChangeFunction(rowsChanged)
									}}
									rows={isCompanyExpanded ? companyRows : companyRows.slice(0, 1)}
									unselectText={unselectText}
									testId={testId + '-cddc'}
									alternativeFields={alternativeFields}
									disabled={disabled}
									isAlternateExpanded={isCompanyExpanded}
									onCollapse={toggleCompanyInfo}
									showAdditionalInfo={enableAlternateFieldsRecomm}
									showWarningIcon={showWarningField}
								/>
								<DropdownCollection
									collectionId={collectionId}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
										getDataFields(rowsChanged)
										onChangeFunction(rowsChanged)
									}}
									rows={phoneRows}
									unselectText={unselectText}
									testId={testId + '-cddc'}
									alternativeFields={alternativeFields}
									disabled={disabled}
									showAdditionalInfo={enableAlternateFieldsRecomm}
									showWarningIcon={showWarningField}
								/>
								<div className={styles.headerText}>
									{t('file.mapping.matching.header.address')}{' '}
									{enableAlternateFieldsRecomm && !isAddressExpanded && (
										<span
											onClick={() => {
												dispatch(
													setAlternateInfoAction({
														alternateCompanyExpanded: isCompanyExpanded,
														alternateAdrsExpanded: !isAddressExpanded,
														alternateRegNumExpanded: isAltRegNumExpanded
													})
												)
												mapAlternateAddress()
												setIsAddressExpanded(!isAddressExpanded)
											}}
											className={styles.plusIcon}
										>
											+
										</span>
									)}
								</div>
								<div className={styles.containerSeprator}>
									{/*Collection original with title*/}
									<DropdownCollection
										collectionId={collectionId}
										onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
											getDataFields(rowsChanged)
											onChangeFunction(rowsChanged)
										}}
										rows={rows.filter((row) => {
											return !(
												row.id.toLowerCase().includes('alternate') ||
												row.id.toLowerCase().includes('company') ||
												row.id.toLowerCase().includes('phone')
											)
										})}
										unselectText={unselectText}
										testId={testId + '-cddc'}
										alternativeFields={alternativeFields}
										disabled={disabled}
										showAdditionalInfo={enableAlternateFieldsRecomm}
										showWarningIcon={showWarningField}
									/>
								</div>
								{isAddressExpanded && enableAlternateFieldsRecomm && (
									<>
										{/*Collection Alternate with Title and Alert*/}
										<div className={styles.containerSeprator}>
											<div className={styles.headerText}>
												<span>{t('file.mapping.matching.header.alternate.address')}</span>
												<span
													onClick={() => {
														dispatch(
															setAlternateInfoAction({
																alternateCompanyExpanded: isCompanyExpanded,
																alternateAdrsExpanded: !isAddressExpanded,
																alternateRegNumExpanded: isAltRegNumExpanded
															})
														)
														clearAlternateAdrsMap()
														localStorage.setItem('AlternatesAutomap', JSON.stringify({}))
														setIsAddressExpanded(!isAddressExpanded)
													}}
													className={styles.plusIcon}
												>
													<img className={styles.deleteIcon} src={deleteIcon} alt={''} />
												</span>
											</div>
											{showAlternateCountryAlert && (
												<div className={styles.warningAlert}>
													<div className={styles.warningIcon}>
														<span></span>
													</div>
													<span>{t('file.mapping.alternate.warning.alert')}</span>
												</div>
											)}
											<div className={styles.containerSeprator}>
												<DropdownCollection
													collectionId={collectionId}
													onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
														getDataFields(rowsChanged)
														onChangeFunction(rowsChanged)
													}}
													rows={rows.filter((row) => {
														return (
															row.id.toLowerCase().includes('alternate') &&
															!(
																row.id.toLowerCase().includes('company') ||
																row.id.toLowerCase().includes('phone')
															)
														)
													})}
													unselectText={unselectText}
													testId={testId + '-cddc'}
													alternativeFields={alternativeFields}
													disabled={disabled}
													showAdditionalInfo={enableAlternateFieldsRecomm}
													showAlert={showAlternateCountryAlert ? 'Alternate_Country' : ''}
													showWarningIcon={showWarningField}
												/>
											</div>
										</div>
									</>
								)}
							</>
						) : regNumberRows.length ? (
							<>
								{/*'Alternate simple*/}
								<DropdownCollection
									collectionId={collectionId}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
										getDataFields(rowsChanged)
										onChangeFunction(rowsChanged)
									}}
									rows={isAltRegNumExpanded ? regNumberRows : regNumberRows.slice(0, 1)}
									unselectText={unselectText}
									testId={testId + '-cddc'}
									alternativeFields={alternativeFields}
									disabled={disabled}
									isAlternateExpanded={isAltRegNumExpanded}
									onCollapse={toggleRegNum}
									showAdditionalInfo={enableAlternateFieldsRecomm}
									showWarningIcon={showWarningField}
								/>
								<DropdownCollection
									collectionId={collectionId}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
										getDataFields(rowsChanged)
										onChangeFunction(rowsChanged)
									}}
									rows={otherRows || []}
									unselectText={unselectText}
									testId={testId + '-cddc'}
									alternativeFields={alternativeFields}
									disabled={disabled}
									showAdditionalInfo={enableAlternateFieldsRecomm}
									showWarningIcon={showWarningField}
								/>
							</>
						) : (
							<>
								{/*{'Collection without alternative and optional "mandatory" fields}*/}
								<DropdownCollection
									collectionId={collectionId}
									onChangeFunction={(rowsChanged: Array<DropdownCollectionRow>) => {
										getDataFields(rowsChanged)
										onChangeFunction(rowsChanged)
									}}
									rows={rows}
									unselectText={unselectText}
									testId={testId + '-cddc'}
									alternativeFields={alternativeFields}
									disabled={disabled}
									showAdditionalInfo={enableAlternateFieldsRecomm}
									showAlert={showAlternateCountryAlert ? 'Alternate_Country' : ''}
									showWarningIcon={showWarningField}
								/>
							</>
						)}
					</div>
					{additionalConfigGroup ? additionalConfigGroup : null}
				</>
			)}
		</div>
	)
}
