import { AxiosInstance } from 'axios'
import { unescape } from 'lodash-es'
import { Dispatch } from 'react'
import { ElementUIFacade } from '../components/data-block-searcher/ElementUIFacade'
import { EnrichmentTemplateFacade } from '../components/enrichment-template-picker/enrichment-template-picker'
import tradeUpElements from '../project/steps/enriching/select-your-data/tradeup-elements.json'
import { getDataBlocksElements } from '../queries/api/getDataBlocksElements'
import { getDataBlocksEntitlements } from '../queries/api/getDataBlocksEntitlements'
import { updateCurrentProjectAction } from '../store/projectWizard/actions'
import { EnrichingBlock } from '../store/projectWizard/types'
import {
	Block,
	Domain,
	Element,
	ElementDetail,
	EnrichmentTemplate,
	EnrichmentTemplateList,
	LevelDetail,
	PurposeOfUse,
	UserDetail
} from '../types'
import { EntityType } from '../types/sources/EntityType'

export const requiredFamilyTreeElements = [
	'corporatelinkage_parent_duns',
	'corporatelinkage_parent_primaryname',
	'corporatelinkage_headqtr_primaryname',
	'corporatelinkage_headqtr_duns'
]

export const filterElementsByEntitlements = (
	apiClient: AxiosInstance,
	dispatch: Dispatch<unknown>,
	selectedElements: Array<string>,
	purposeOfUse: PurposeOfUse,
	enrichingLayout?: EnrichingBlock[],
	entityType?: EntityType
): Promise<ElementDetail[]> => {
	return new Promise((resolve, reject) => {
		getDataBlocksEntitlements(apiClient, purposeOfUse, entityType, true)
			.then((entitlements) => {
				const elementDetailList = getCurrentEntitlement(purposeOfUse, entitlements)
				if (selectedElements.length > 0) {
					dispatch(
						updateCurrentProjectAction({
							enrichingLayout: mappingElementsToStructureForBlock(
								elementDetailList,
								selectedElements,
								enrichingLayout
							)
						})
					)
				}
				resolve(elementDetailList)
			})
			.catch((e) => {
				console.error(e)
				reject([])
			})
	})
}

export const getEnrichmentLayoutFacadeByTemplates = async (
	apiClient: AxiosInstance,
	enrichmentTemplateList: EnrichmentTemplateList,
	userList: Array<UserDetail>,
	projectPurposeOfUse: PurposeOfUse,
	entityType?: EntityType
): Promise<Array<EnrichmentTemplateFacade>> => {
	const completeBlocks = await getDataBlocksElements(apiClient)

	return getDataBlocksEntitlements(apiClient, projectPurposeOfUse, entityType, true)
		.then((entitlements) => {
			const enrichmentArray: Array<EnrichmentTemplateFacade> = []
			enrichmentTemplateList?.forEach((template: EnrichmentTemplate) => {
				const userDetail = getUserDetail(userList, template.createdBy)
				const purposeOfUse = { domain: template.domain, recordType: template.recordType }
				//For instances where the template has no defined purpose of use, we use the Project's Purpose of use instead
				const elementDetailList =
					!template.domain && projectPurposeOfUse
						? getCurrentEntitlement(projectPurposeOfUse, entitlements)
						: getCurrentEntitlement(purposeOfUse, entitlements)
				const dataBlocks = mappingElementsToStructureForBlock(completeBlocks, template.elements)
				const notEntitledElements = getNotEntitledElements(dataBlocks, template.elements)
				const flattenNotEntitledElements = completeBlocks
					? flattenElements(mappingElementsToStructureForBlock(completeBlocks, notEntitledElements))
					: []
				const enrichmentTemplate: EnrichmentTemplateFacade = {
					name: template.templateName,
					purposeOfUse: purposeOfUse,
					dataBlocks: dataBlocks,
					notEntitledDataBlocks: flattenNotEntitledElements,
					//TODO remove the undefined validation once the BE sends the createTime
					createTime: template.createTime == undefined ? 0 : template.createTime,
					updateTime: template.updateTime,
					//TODO replace the undefined validation once product decides what to do when no user is found
					createdBy:
						userDetail == undefined
							? {
									id: template.createdBy,
									AccessLevel: 'THIRD_PARTY_USER',
									FirstName: '',
									LastName: '',
									Title: '',
									Email: '',
									Username: ''
							  }
							: userDetail,
					templateId: template.templateId,
					generateJson: template.generateJSON || false,
					tradeUp: template.tradeUp ? template.tradeUp : undefined
				}
				enrichmentArray.push(enrichmentTemplate)
			})
			return enrichmentArray
		})
		.catch((e) => {
			console.error(e)
			return []
		})
}

export const getCurrentEntitlement = (purposeOfUse: PurposeOfUse, entitlement: Array<Domain>): ElementDetail[] => {
	let availableElements: ElementDetail[] = []
	if (
		purposeOfUse.domain &&
		purposeOfUse.domain.trim().length > 0 &&
		purposeOfUse.recordType &&
		purposeOfUse.recordType.trim().length > 0
	) {
		const currentEntitlement = entitlement.find((currentDomain) => currentDomain.domain === purposeOfUse.domain)
		if (currentEntitlement) {
			const currentRecordType = currentEntitlement.recordTypes[purposeOfUse.recordType]
			if (currentRecordType) {
				const availableDataBlocks = currentRecordType
				availableElements = availableDataBlocks.map((block: Block) => {
					return {
						blockId: block.blockId,
						levels: block.levels
					} as ElementDetail
				})
				return availableElements
			}
		}
	}
	return availableElements
}

export const removeBaseInfoEnrichingBlock = (
	blocks: Array<EnrichingBlock>,
	isTradeUpEnable?: boolean
): Array<EnrichingBlock> => {
	if (isTradeUpEnable) {
		const elementsTradeUp = (tradeUpElements as EnrichingBlock[])[0].elements
		blocks = blocks.filter((block) => {
			return block.elements.filter((element) => elementsTradeUp.includes(element))
		})
	}
	// else {
	// 	blocks = blocks.filter((block: EnrichingBlock) => block.blockId !== 'baseinfo')
	// }
	return blocks
}

export const mappingElementsToStructureForBlock = (
	blocks: ElementDetail[],
	elements: Array<string>,
	enrichingLayout?: EnrichingBlock[]
) => {
	const newEnrichingLayout: Array<EnrichingBlock> = []
	blocks.forEach((block: ElementDetail) => {
		const elementsForBlock =
			block?.levels.reduce(
				(uniqueLevel: LevelDetail, levelDetail: LevelDetail) => {
					const levelNumber = levelDetail.level.displayName.replace('Level ', '').replace('level ', '')
					uniqueLevel.elements = uniqueLevel.elements.concat(
						levelDetail.elements.map((element: ElementUIFacade) => {
							element.level = levelNumber
							element.description = unescape(element.description)
							return element
						})
					)
					return uniqueLevel
				},
				{
					level: { displayName: 'Level 1', block: 'initial', levelId: 'L1' },
					elements: [],
					description: 'accumulator'
				}
			).elements || []
		const newBlock = {
			blockId: block.blockId,
			elements: filterSelectedElements(elementsForBlock, elements)
		}
		if (enrichingLayout && newBlock.blockId === 'entityresolution') {
			if (enrichingLayout[0] && enrichingLayout[0].elements)
				newBlock.elements.push(...enrichingLayout[0].elements)
		}
		if (newBlock.elements.length > 0) {
			newEnrichingLayout.push(newBlock)
		}
	})
	return newEnrichingLayout
}

export const getNotEntitledElements = (dataBlocks: Array<EnrichingBlock>, elements: Array<string>) => {
	return elements.filter((element) => {
		const resultingDataBlocks = dataBlocks.filter((dataBlock) => {
			return (
				dataBlock.elements.filter((dataBlockElement) => {
					return dataBlockElement.elementId === element
				}).length > 0
			)
		})

		return resultingDataBlocks.length === 0
	})
}

export const flattenElements = (blocks: Array<EnrichingBlock>): Element[] => {
	return blocks.reduce((elements, block): Element[] => {
		return elements.concat(
			block.elements.map((element: ElementUIFacade) => {
				element.blockId = block.blockId
				return element
			})
		)
	}, [] as Element[])
}

const filterSelectedElements = (elementsForBlock: Element[], selectedElementIDs: Array<string>) => {
	let elementsSelectedInBlock: Element[] = []
	let selectedElements: Array<Element> = []
	selectedElementIDs.forEach((elementSelected: string) => {
		elementsSelectedInBlock = elementsForBlock.filter((element) => {
			if (element.elementId === elementSelected) {
				return element.elementId
			}
		})
		selectedElements = selectedElements.concat(elementsSelectedInBlock)
	})
	return selectedElements
}

const getUserDetail = (userList: Array<UserDetail>, userEmail: string) => {
	return userList.find((user) => {
		return user.Email === userEmail
	})
}
