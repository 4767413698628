import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { getSFPackageVersion, VersionResponse } from './api/getSFPackageVersion'

export const useSFPackageVersion = (
	systemType?: string,
	orgId?: string,
	versionSF?: string
): UseQueryResult<VersionResponse> => {
	const apiClient = useApi()
	const queryKey = ['getSFPackageVersion']
	const queryFunction = () => getSFPackageVersion(apiClient, systemType, orgId, versionSF)

	return useQuery(queryKey, queryFunction)
}
