import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownOptions } from '../../local-core-ui'
import { EasyMatchStringSetup } from './easy-match-string-setup/easy-match-string-setup'
import { ManualMatchStringSetup } from './manual-match-string-setup/manual-match-string-setup'
import styles from './match-quality-section.module.scss'

interface MatchQualitySectionI {
	matchType: string
	matchGradePatterns: Array<string>
	updateMatchGradePatterns: (updatedMatchGradePatterns: Array<string>) => void
	updateMatchType: (selectedMatchType: string) => void
	testId: string
	readonly?: boolean
}

export const MatchQualitySection = ({
	matchType,
	matchGradePatterns,
	updateMatchGradePatterns,
	updateMatchType,
	testId,
	readonly = false
}: MatchQualitySectionI): ReactElement => {
	const EASY = 'EASY'
	const MANUAL = 'MANUAL'
	const { t } = useTranslation()

	const dropdownOptions: DropdownOptions[] = [
		{ label: t('match.quality.section.easy'), value: EASY },
		{ label: t('match.quality.section.manual'), value: MANUAL }
	]
	const getmatchQualityMode = () => {
		if (matchType === MANUAL) return MANUAL
		if (matchGradePatterns.length === 1) {
			//this check is put in to prevent easy mode from being selected when string contains F or Z (which is only allowed in manual mode)
			return matchGradePatterns[0].includes('F') || matchGradePatterns[0].includes('Z') ? MANUAL : EASY
		} else if (matchGradePatterns.length === 0) {
			return EASY
		} else {
			return matchGradePatterns.length > 1 ? MANUAL : EASY
		}
	}

	return (
		<div className={styles.matchQualitySection}>
			{!readonly && (
				<div className={styles.matchQualityDropdown}>
					<Dropdown
						id="match-quality-type"
						hint=""
						onChangeFunction={updateMatchType}
						label=""
						options={dropdownOptions}
						selected={matchType}
						size="small"
						testId={testId + '-dd-match-quality-type'}
					/>
				</div>
			)}
			{getmatchQualityMode() === EASY ? (
				<EasyMatchStringSetup
					matchStrings={matchGradePatterns}
					onChange={(matchString) => updateMatchGradePatterns([matchString])}
					testId={testId + '-emss'}
					readonly={readonly}
				/>
			) : (
				<ManualMatchStringSetup
					matchStrings={matchGradePatterns}
					updateMatchStrings={updateMatchGradePatterns}
					testId={testId + '-mmss'}
					readonly={readonly}
				/>
			)}
		</div>
	)
}
