import { useTranslation } from 'react-i18next'
import { ReportDonutChart } from '../../../../../components/report-half-donut-chart/report-donut-chart'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { abbreviateNumber } from '../../../../../helpers'
import { DataReport } from '../../../../../types'
import { EnrichedDataList } from '../../../types/EnrichedDataList'
import { chartColorsMap } from '../../chartColorsMap'
import styles from './match-drivers.module.scss'

interface MatchDriversProps {
	contactMatchReport: DataReport['matchToContactReport'] | null
}

const rowHeight = '2rem'
const NO_MATCH_CODE = 'No Match'

const MatchDrivers = ({ contactMatchReport }: MatchDriversProps) => {
	const { t } = useTranslation()
	const matchDrivers = contactMatchReport ? [...contactMatchReport.matchDrivers] : []
	const matchedCount = contactMatchReport ? contactMatchReport.matched : 0
	const precision = matchedCount > 1000 ? 1 : 0
	const abbreviateMatchedCount = abbreviateNumber(matchedCount, precision)
	const displayMatchDrivers = contactMatchReport && matchDrivers.length > 0
	let chartData: EnrichedDataList[] = []
	const tableRows: RowDef[] = []

	const tableColumns: ColDef[] = [
		{
			label: t('report.page.contactMatchSummary.tile.matchDrivers.table.column1') as string,
			field: 'driver'
		},
		{
			label: t('report.page.contactMatchSummary.tile.matchDrivers.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.contactMatchSummary.tile.matchDrivers.table.column3') as string,
			field: 'percentage'
		}
	]

	const total = matchDrivers.reduce((acc, current) => {
		acc += current.count
		return acc
	}, 0)

	chartData = [...matchDrivers]
		.sort((a, b) => b.rate - a.rate)
		.map(({ matchDriver, count, rate }, idx) => {
			let colorKey = chartColorsMap.get(`category-${idx + 1}`) ?? ''
			const percentage = (count * 100) / total

			if (matchDriver === NO_MATCH_CODE) {
				colorKey = chartColorsMap.get('uncategory-3') ?? ''
			}

			tableRows.push([
				<ChartKey
					key={`key-${idx}`}
					label={t(`label.graph.${matchDriver.split(' ').join('')}`)}
					color={colorKey}
				/>,
				new Intl.NumberFormat().format(count),
				<span key={`key-${idx}`} className={styles.boldText}>
					{Math.round(percentage) + '%'}
				</span>
			])

			return {
				field: matchDriver,
				color: colorKey,
				count,
				rate: Math.round(rate)
			}
		})

	return (
		<ReportTile title={t('report.page.contactMatchSummary.tile.matchDrivers.title') as string}>
			{displayMatchDrivers ? (
				<div className={styles.matchDriversContainer}>
					<ReportDonutChart
						data={chartData}
						size={200}
						statLabel={t('report.page.contactMatchSummary.tile.matchDrivers.chart.stat') as string}
						statValue={abbreviateMatchedCount}
					/>
					<ReportTableWrapper>
						<ReportTable columns={tableColumns} rows={tableRows} rowHeight={rowHeight} hideBorders />
					</ReportTableWrapper>
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { MatchDrivers }
