import { useTranslation } from 'react-i18next'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { PieChart } from '../../../../../local-core-ui'
import { DataReport } from '../../../../../types'
import { chartColorsMap } from '../../chartColorsMap'
import styles from './email-type.module.scss'

interface EmailTypeProps {
	emailTypeReport: DataReport['emailTypeReport'] | null
}

const rowHeight = '2rem'

const EmailType = ({ emailTypeReport }: EmailTypeProps) => {
	const { t } = useTranslation()
	const emailTypeList = emailTypeReport ? [...emailTypeReport.emailTypeList] : []
	const chartData: Record<string, number> = {}
	const chartColors: Record<string, string> = {}

	const emailTypeColumnDef: ColDef[] = [
		{
			label: t('report.page.emailVerification.tile.emailType.table.column1') as string,
			field: 'business'
		},
		{
			label: t('report.page.emailVerification.tile.emailType.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.emailVerification.tile.emailType.table.column3') as string,
			field: 'percentage'
		}
	]

	const tableRows: RowDef[] = emailTypeList
		.sort((a, b) => b.rate - a.rate)
		.map(({ description, count, total }, idx) => {
			const colorKey = description === 'Unknown' ? 'uncategory-1' : `category-${idx + 1}`
			const percentage = (count * 100) / total

			chartData[description] = count
			chartColors[description] = chartColorsMap.get(colorKey) ?? ''

			return [
				<ChartKey
					key={`key-${idx}`}
					label={t(`label.graph.${description.split(' ').join('')}`) as string}
					color={chartColors[description]}
				/>,
				new Intl.NumberFormat().format(count),
				percentage.toFixed(0).toLocaleString() + '%'
			]
		})

	return (
		<ReportTile
			className={styles.emailTypeTile}
			title={t('report.page.emailVerification.tile.emailType.title') as string}
		>
			{emailTypeReport ? (
				<div className={styles.emailTypeContainer}>
					<div className={styles.pieChartContainer}>
						<PieChart
							diameter={200}
							colors={chartColors}
							data={chartData}
							describeWith={'none'}
							showKeys={false}
							showStrokes
						/>
					</div>
					<ReportTableWrapper>
						<ReportTable rowHeight={rowHeight} columns={emailTypeColumnDef} rows={tableRows} hideBorders />
					</ReportTableWrapper>
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { EmailType }
