import { FC, useEffect, useRef, useState } from 'react'
import Flag from '../flags/flag'
import Icon from '../icon/icon'
import colorTokens from '../shared/token-colors.json'
import { GDMTooltip } from './gdm-tooltip'
import styles from './geographical-distribution-map.module.scss'

interface IDistributionData {
	countryCode: string
	total: number
	percent: number
}

export interface IGDMKeyProps {
	data?: Array<IDistributionData>
	color: string
	translationFunction(i18nKey: string): string
	testId: string
}

export const GDMKey: FC<IGDMKeyProps> = ({ data = [], color, translationFunction, testId }: IGDMKeyProps) => {
	const body = useRef<HTMLDivElement>(null)

	const scrollDistance = 2
	const scrollUp = () => {
		body.current.scrollTop -= scrollDistance
	}
	const scrollDown = () => {
		body.current.scrollTop += scrollDistance
	}

	const [scrollPositionTop, setScrollPositionTop] = useState(true)
	const [scrollPositionBottom, setScrollPositionBottom] = useState(true)
	const [tooltipData, setTooltipData] = useState<IDistributionData>({
		countryCode: '',
		total: 0,
		percent: 0
	})
	const [tooltipVisible, setTooltipVisible] = useState(false)
	const [isMouseDown, setMouseDown] = useState<boolean>(false)
	const [isUpButtonDisabled, setUpButtonDisabled] = useState<boolean>(true)
	const [isDownButtonDisabled, setDownButtonDisabled] = useState<boolean>(false)
	const [scrollDirection, setScrollDirection] = useState('')

	const updateAndShowTooltip = (newData: IDistributionData) => {
		setTooltipData(newData)
		setTooltipVisible(true)
	}

	const updateScrollPosition = () => {
		setScrollPositionTop(body.current?.scrollTop === 0)
		setScrollPositionBottom(body.current?.scrollTop + 1 >= body.current?.scrollHeight - body.current?.clientHeight)
	}

	useEffect(() => {
		const scroll = () => {
			if (scrollDirection === 'up') {
				scrollUp()
			} else if (scrollDirection === 'down') {
				scrollDown()
			}
		}

		if (isMouseDown) {
			const interval = setInterval(scroll, 8)
			return () => clearInterval(interval)
		}
	}, [isMouseDown])

	useEffect(() => {
		updateScrollPosition()
	}, [body])

	useEffect(() => {
		setDownButtonDisabled(scrollPositionBottom)
		setUpButtonDisabled(scrollPositionTop)
		if ((scrollPositionBottom && scrollDirection === 'down') || (scrollPositionTop && scrollDirection === 'up')) {
			setMouseDown(false)
		}
	}, [scrollDirection, scrollPositionTop, scrollPositionBottom])

	return (
		<div data-testid={`gdm-key-container-${testId}`} className={styles.gdmKey}>
			<div data-testid={`gdm-key-flexed-${testId}`} className={styles.flexed} />
			{!(scrollPositionBottom && scrollPositionTop) ? (
				<button
					className={styles.arrowButtonContainer}
					onClick={() => scrollUp()}
					onMouseDown={() => {
						setScrollDirection('up')
						setMouseDown(true)
					}}
					onMouseUp={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					onMouseLeave={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					disabled={isUpButtonDisabled}
					data-testid={testId + '-up'}
				>
					<span data-testid={`gdm-icon-wrapper-${testId}`} className={styles.iconWrapper}>
						<Icon
							type="arrow-up"
							size={'mini'}
							color={`${isUpButtonDisabled ? colorTokens.ColorGray : ''} ${
								isMouseDown && scrollDirection === 'up' ? colorTokens.ColorBlueBrand : ''
							}`}
							testId={`gdm-arrow-up-${testId}`}
						/>
					</span>
				</button>
			) : (
				''
			)}
			<div
				data-testid={`gdm-table-scroll-${testId}`}
				className={styles.keyBody}
				ref={body}
				onScroll={updateScrollPosition}
			>
				{data
					.sort((a, b) => (a.percent <= b.percent ? 1 : -1))
					.map((country: IDistributionData) => {
						return (
							<div
								key={country.countryCode}
								className={styles.countryData}
								onMouseOver={() => {
									updateAndShowTooltip(country)
								}}
								onMouseOut={() => {
									setTooltipVisible(false)
								}}
								onFocus={() => {
									updateAndShowTooltip(country)
								}}
								onBlur={() => {
									setTooltipVisible(false)
								}}
								tabIndex={0}
								style={{ color: color }}
								data-testid={testId + '-' + country.countryCode}
							>
								<div data-testid={`gdm-flag-container-${testId}`} className={styles.flagContainer}>
									<Flag testId={`gdm-key-country-${testId}`} countryCode={country.countryCode} />
								</div>
								{country.percent > 0.001 || country.percent === 0
									? Math.floor(country.percent * 1000) / 10
									: '<0.1'}
								%
							</div>
						)
					})}
			</div>
			{!(scrollPositionBottom && scrollPositionTop) ? (
				<button
					className={styles.arrowButtonContainer}
					onClick={scrollDown}
					onMouseDown={() => {
						setScrollDirection('down')
						setMouseDown(true)
					}}
					onMouseUp={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					onMouseLeave={() => {
						setScrollDirection('')
						setMouseDown(false)
					}}
					disabled={isDownButtonDisabled}
					data-testid={testId + '-down'}
				>
					<span data-testid={`gdm-icon-wrapper-${testId}`} className={styles.iconWrapper}>
						<Icon
							type="arrow-down"
							size={'mini'}
							color={`${isDownButtonDisabled ? colorTokens.ColorGray : ''} ${
								isMouseDown && scrollDirection === 'down' ? colorTokens.ColorBlueBrand : ''
							}`}
							testId={`gdm-key-arrow-down-${testId}`}
						/>
					</span>
				</button>
			) : (
				''
			)}
			<div data-testid={`gdm-key-flexed-second-${testId}`} className={styles.flexed} />
			<GDMTooltip
				testId={`gdm-tooltip-${testId}`}
				data={tooltipData}
				visible={tooltipVisible}
				translationFunction={translationFunction}
			/>
		</div>
	)
}
