import { useQuery, UseQueryResult } from 'react-query'
import { useApi } from '../hooks'
import { ConnectionStatusResponse, getConnectionStatus } from './api/getC4SActiveConnection'

export const useConnectionStatus = (orgId?: string, enabled?: boolean): UseQueryResult<ConnectionStatusResponse> => {
	const apiClient = useApi()
	const queryKey = ['getC4SActiveConnection']
	const queryFunction = () => getConnectionStatus(apiClient, orgId)

	return useQuery(queryKey, queryFunction, {
		enabled: enabled
	})
}
