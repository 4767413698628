/**
 * @class Label
 */

import * as icomoon from '@dnb-dcp/icons/src/selection.json'
import cx from 'classnames'
import { camelCase } from 'lodash-es'
import { FC } from 'react'
import { createTokenList, getValueToken } from '../design-tokens/utils'
import * as tokens from '../shared/token-colors.json'
import styles from './icon.module.scss'

export type IconType =
	| 'download'
	| 'globe'
	| 'plus'
	| 'person'
	| 'radio-selector'
	| 'star-solid'
	| 'star'
	| 'trash'
	| 'copy'
	| 'arrow-back'
	| 'arrow-next'
	| 'three-dots'
	| 'laptop'
	| 'alert-big'
	| 'check-circle'
	| 'bell'
	| 'search'
	| 'avatar'
	| 'pencil'
	| 'x'
	| 'chevron-left'
	| 'chevron-right'
	| 'arrow-up'
	| 'arrow-down'
	| 'empty-dot'
	| 'full-dot'
	| 'arrow-sort-up'
	| 'arrow-sort-down'
	| 'radio-btn-active'
	| 'radio-btn-inactive'
	| 'warning'
	| 'icon-green-check'
	| 'fingerprint'
	| 'location'
	| 'match'
	| 'phone'
	| 'www'
	| 'chevron-bold'
	| 'paper-clip'
	| 'high-priority-bg'
	| 'medium-priority-bg'
	| 'low-priority-bg'
	| 'high-priority-circle'
	| 'medium-priority-circle'
	| 'low-priority-circle'
	| 'high-priority-chevron'
	| 'medium-priority-chevron'
	| 'low-priority-chevron'
	| 'multi-cards-bg'
	| 'multi-cards'
	| 'business'
	| 'email'
	| 'accepted'
	| 'denied'
	| 'group-letters'
	| 'account_balance'
	| 'send'
	| 'arrows-reverse'
	| 'img-blocks'
	| 'card-view'
	| 'table-view'
	| 'multi-solid'
	| 'eye'
	| 'external-link'
	| 'simple-check'
	| 'lock'
	| 'notification'
	| 'filter'
	| 'view-tile'
	| 'view-list'
	| 'link'
	| 'link-off'
	| 'reload'
	| 'emptywarning'
	| 'information'

interface IIconProps {
	type: IconType
	size?: 'mini' | 'small' | 'medium' | 'large'
	title?: string
	color?: string
	testId?: string
}

const getIconDef = (name: string, size: string) => {
	const selectedIcon = icomoon.icons.find((icon) => {
		return icon.properties.name === `${size}-${name}` || icon.properties.name === name
	})
	return selectedIcon.icon
}

const mergeStyles = (def, fill) => {
	if (!fill) return def
	const newDef = { ...def }
	newDef.fill = fill
	return newDef
}

interface PathProps {
	def: any
	type: IconType
	fill: string
	testId?: string
}

const Paths: FC<PathProps> = ({ def, type, fill, testId }) => {
	if (def.paths.length === 1) {
		return (
			<path data-testid={`path-${testId}`} d={def.paths[0]} key={type} style={mergeStyles(def.attrs[0], fill)} />
		)
	} else {
		return (
			<g data-testid={`g-${testId}`}>
				{def.paths.map((path, indx) => (
					<path
						data-testid={`path-g-${testId}`}
						d={path}
						key={type + indx}
						style={mergeStyles(def.attrs[indx], fill)}
					/>
				))}
			</g>
		)
	}
}
export const Icon: FC<IIconProps> = ({ type, size = 'small', title, color = undefined, testId }) => {
	const colorsTokenList = createTokenList(tokens)
	return (
		<svg
			className={cx(styles.icon, styles[camelCase(`icon-${size}`)])}
			viewBox={`0 0 ${icomoon.height} ${icomoon.height}`}
			data-testid={`svg-${testId}`}
		>
			{title ? <title>{title}</title> : null}
			<Paths
				testId={testId}
				def={getIconDef(type, size)}
				type={type}
				fill={getValueToken(colorsTokenList, color)}
			/>
		</svg>
	)
}

export default Icon
