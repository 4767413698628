import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadTypeCard } from '../../../../components/upload-type-card/upload-type-card'
import { useWizardContext } from '../../../../components/wizardComposer/hooks/useWizardContext'
import { useFeatures } from '../../../../hooks/useEntitlements'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'
import { updateCurrentProjectAction } from '../../../../store/projectWizard/actions'
import { EntityType } from '../../../../types/sources/EntityType'
import { SelectCrmSource } from './select-crm-source/select-crm-source'
import styles from './selectInput.module.scss'
import { UploadFile } from './upload-file/upload-file'

interface SelectInputProps {
	title?: string
	testId: string
	selectedDelimiter?: string
	acceptsApiSources?: boolean
}

export enum InputType {
	API = 'API',
	FILE = 'FILE',
	CRM = 'CRM',
	Salesforce = 'Salesforce',
	Empty = ''
}

export const SelectInput: FC<SelectInputProps> = ({ title, testId, selectedDelimiter }) => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const disabled =
		projectWizardState.currentProject.source.id !== undefined ||
		projectWizardState.currentProject.fileInfo?.uploadInfo?.file_import_id !== undefined
	const enableApi = useFeatures(['EnableAPI'])
	const enableContactApi = useFeatures(['EnableContactAPI'])
	const sourceTypeContact = projectWizardState.currentProject.source.entityType === EntityType.CONTACTS
	const { wizardContext } = useWizardContext()
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])

	const enableCRM =
		projectWizardState.currentProject.thirdPartyIntegration?.crmSource ||
		(wizardContext.isC4S && wizardContext.salesforcePlatform)

	const isBeta = useFeatures(['APIBetaBanner'])

	const getSelectedInputFromSource = () => {
		const isCRM = projectWizardState.currentProject.thirdPartyIntegration !== undefined
		if (isCRM) return enableC4SAuthMgmt ? InputType.Salesforce : InputType.CRM
		else {
			if (projectWizardState.currentProject.source.isFile) return InputType.FILE
			else if (projectWizardState.currentProject.source.isApi) return InputType.API
			return enableCRM || enableApi ? InputType.Empty : InputType.FILE
		}
	}

	const [selectedInput, setSelectedInput] = useState<InputType>(getSelectedInputFromSource())

	const onInputChange = (newInput: InputType) => {
		const crmPiece = newInput !== InputType.CRM ? { thirdPartyIntegration: { crmSource: undefined } } : {}
		const contactMatchPiece = sourceTypeContact
			? { enable_partial_match: newInput === InputType.CRM || newInput === InputType.Salesforce }
			: { enable_partial_match: undefined }

		setSelectedInput(newInput)
		dispatch(
			updateCurrentProjectAction({
				source: {
					isFile: newInput === InputType.FILE,
					isApi: enableApi && !(newInput === InputType.CRM || newInput === InputType.Salesforce),
					...contactMatchPiece
				},
				...crmPiece
			})
		)
	}

	return (
		<div data-testid="upload-chooser-page-container" className={styles.uploadChooserPageContainer}>
			{title ? (
				<h1 data-testid="source-project-title" className={styles.selectFileText} title={title}>
					{title}
				</h1>
			) : (
				<h1 data-testid="source-project-header">{t('setup.source.select.input.source')}</h1>
			)}
			{(enableApi || enableCRM) && (
				<>
					<div className={`${styles.helpLine} ${styles.checkboxesHeader}`}>
						{t('upload.chooser.checkboxes.header')}
					</div>
					<div className={styles.checkboxContainer}>
						{enableApi && ((sourceTypeContact && enableContactApi) || !sourceTypeContact) && (
							<div className={styles.checkboxWrapper}>
								<UploadTypeCard
									type={InputType.API}
									checked={selectedInput === InputType.API}
									isBeta={isBeta}
									disabled={disabled}
									onChange={() => onInputChange(InputType.API)}
									group="uploadTypeCard"
								/>
							</div>
						)}
						{enableC4SAuthMgmt && wizardContext.isC4S ? (
							<div className={styles.checkboxWrapper}>
								<UploadTypeCard
									type={InputType.Salesforce}
									plusApi={enableApi}
									checked={selectedInput === InputType.Salesforce}
									isBeta={false}
									disabled={disabled}
									onChange={() => onInputChange(InputType.Salesforce)}
									group="uploadTypeCard"
								/>
							</div>
						) : (
							enableCRM && (
								<div className={styles.checkboxWrapper}>
									<UploadTypeCard
										type={InputType.CRM}
										plusApi={enableApi}
										checked={selectedInput === InputType.CRM}
										isBeta={false}
										disabled={disabled}
										onChange={() => onInputChange(InputType.CRM)}
										group="uploadTypeCard"
									/>
								</div>
							)
						)}
						<div className={styles.checkboxWrapper}>
							<UploadTypeCard
								type={InputType.FILE}
								plusApi={enableApi}
								checked={selectedInput === InputType.FILE}
								isBeta={false}
								onChange={() => onInputChange(InputType.FILE)}
								group="uploadTypeCard"
								disabled={disabled}
							/>
						</div>
					</div>
				</>
			)}
			{selectedInput === InputType.FILE && <UploadFile testId={testId} selectedDelimiter={selectedDelimiter} />}
			{(selectedInput === InputType.CRM || selectedInput === InputType.Salesforce) && <SelectCrmSource />}
		</div>
	)
}
