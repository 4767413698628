import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MatchTemplatePicker } from '../../../../components/match-template-picker/match-template-picker'
import { MatchingApproachTile } from '../../../../components/matching-approach-tile/matching-approach-tile'
import { useApi } from '../../../../hooks'
import { Button, Modal } from '../../../../local-core-ui'
import { useMatchTemplates } from '../../../../queries/useMatchTemplates'
import { RootState, TDispatch, useAppDispatch, useAppSelector } from '../../../../store'
import {
	clearCurrentMatchConfigTemplate,
	setMatchingApproach,
	updateCurrentProjectAction
} from '../../../../store/projectWizard/actions'
import { createOrUpdateMatchRules } from '../../../../store/projectWizard/thunks'
import { MatchingApproach, MatchStyle } from '../../../../types'
import { deletePreviousMatchRules } from '../deletePreviousMatchRules'
import styles from './choose-match.module.scss'

export interface ChooseMatchProps {
	onSelect?: () => void
}

export const ChooseMatch: FC<ChooseMatchProps> = ({ onSelect }) => {
	const { t } = useTranslation()

	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const dispatch: TDispatch = useAppDispatch()
	const apiClient = useApi()
	const matchTemplatesQuery = useMatchTemplates()

	const [showMatchTemplate, setShowMatchTemplate] = useState(false)
	const [showModalChooseMatch, setShowModalChooseMatch] = useState<boolean>(false)
	const [matchingSelected, setMatchingSelected] = useState<MatchingApproach | undefined>(undefined)

	const [templateApproachButtonDisabled, setTemplateApproachButtonDisabled] = useState<boolean>(true)
	const matchingApproach = projectWizardState.currentProject.matchingApproach

	const resetMatchRules = () => {
		if (matchingApproach !== MatchingApproach.CHOOSE_TEMPLATE) {
			dispatch(clearCurrentMatchConfigTemplate())
			if (projectWizardState.currentProject.source.id)
				deletePreviousMatchRules(apiClient, projectWizardState.currentProject.source.id)
		}
		clearMatchRules()
		if (onSelect) onSelect()
	}

	const clearMatchRules = () => {
		const baseRuleIdx = projectWizardState.currentProject.matchRules.findIndex(
			(matchRule) => matchRule.matchRule.ruleType === 'BASE_RULE'
		)
		const rulesCopy = JSON.parse(JSON.stringify(projectWizardState.currentProject.matchRules))
		const baseRule = rulesCopy[baseRuleIdx]
		rulesCopy[0].matchRule = {
			...rulesCopy[0].matchRule,
			displayName: t('matching.step.base.rule') as string,
			acceptCriterion: {
				LowestConfidenceCode: 6,
				HighestConfidenceCode: 10
			},
			allowedValues: [],
			exclusionCriterion: [],
			ruleType: 'BASE_RULE',
			matchStyle:
				matchingSelected === MatchingApproach.START_SCRATCH_MR
					? MatchStyle.MATCH_RECOMMENDATION
					: MatchStyle.MATCH_QUALITY
		}
		if (matchingSelected !== MatchingApproach.START_SCRATCH_MR) {
			delete rulesCopy[0].matchRule.precedenceCriterion
			delete rulesCopy[0].matchRule.precedenceProfile
			delete rulesCopy[0].matchRule.stewardshipProfile
			delete rulesCopy[0].matchRule.passProfile
		}
		if (rulesCopy[0].matchRule.acceptCriterion.MatchGradePatterns) {
			delete rulesCopy[0].matchRule.acceptCriterion.MatchGradePatterns
		}
		rulesCopy[0] = { ...rulesCopy[0], advancedSettingsCollapsed: true }
		if (baseRule) {
			dispatch(
				updateCurrentProjectAction({
					matchRules: [baseRule]
				})
			)
		}
		if (matchingSelected !== MatchingApproach.START_SCRATCH_MR) dispatch(createOrUpdateMatchRules())
	}

	const action2MatchingApproach = (matching: MatchingApproach | undefined) => {
		if (matching === MatchingApproach.CHOOSE_TEMPLATE) {
			setShowMatchTemplate(true)
		} else if (matching === MatchingApproach.START_SCRATCH) {
			resetMatchRules()
			dispatch(setMatchingApproach(MatchingApproach.START_SCRATCH))
		}
	}

	const isBaseRuleChanged = () => {
		if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId) {
			return true
		} else {
			const baseRule = projectWizardState.currentProject.matchRules[0]
			return (
				(baseRule.matchRule.versionId !== undefined && baseRule.matchRule.versionId > 1) ||
				baseRule?.advancedSettingsCollapsed === false ||
				projectWizardState.currentProject.matchRules.length > 1 ||
				baseRule?.matchRule.displayName !== 'Global Rule' ||
				baseRule?.matchRule.acceptCriterion.LowestConfidenceCode !== 6 ||
				baseRule?.matchRule.exclusionCriterion !== undefined ||
				baseRule?.matchRule.acceptCriterion.MatchGradePatterns !== undefined
			)
		}
	}

	useEffect(() => {
		dispatch(setMatchingApproach(undefined))
	}, [dispatch])

	useEffect(() => {
		if (matchTemplatesQuery.data) setTemplateApproachButtonDisabled(matchTemplatesQuery.data.length === 0)
		/**
		 * We only want to run this effect when the flag isSuccess from matchTemplatesQuery changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchTemplatesQuery.isSuccess])

	return (
		<div>
			<Modal
				open={showModalChooseMatch}
				showButtonClose={true}
				onClose={() => setShowModalChooseMatch(false)}
				isContainer={false}
				testId="choose-match-modal"
			>
				<div data-testid="choose-matching-description" className={styles.descriptionChoose}>
					{t('modal.choose.matching')}
				</div>
				<div className={styles.containerButtons}>
					<Button
						text={t('user.tile.button.yes')}
						type="primary"
						size="small"
						onClick={() => {
							action2MatchingApproach(matchingSelected)
							setShowModalChooseMatch(false)
						}}
						testId={'choose-match-ok'}
					/>
					<Button
						text={t('matching.step.modal.cancel')}
						type="primary"
						size="small"
						onClick={() => {
							setShowModalChooseMatch(false)
							if (onSelect) onSelect()
						}}
						testId={'choose-match-no'}
					/>
				</div>
			</Modal>

			{showMatchTemplate ? (
				<MatchTemplatePicker
					open={showMatchTemplate}
					onClose={() => {
						setShowMatchTemplate(false)
					}}
					onSelect={() => {
						if (projectWizardState.currentProject.source.id)
							deletePreviousMatchRules(apiClient, projectWizardState.currentProject.source.id)
						setShowMatchTemplate(false)
						dispatch(setMatchingApproach(MatchingApproach.CHOOSE_TEMPLATE))
						if (onSelect) onSelect()
					}}
					testId="temporal-match-picker"
					sourceId={projectWizardState.currentProject.source.id}
					onTemplatesClear={() => {
						setShowMatchTemplate(false)
						setTemplateApproachButtonDisabled(true)
					}}
				/>
			) : undefined}
			<h2 data-testid="matching-header" className={styles.title}>
				{t('choose.match.step.title')}
			</h2>
			<p data-testid="matching-header-description" className={styles.description}>
				{t('choose.match.step.span')}
			</p>
			<MatchingApproachTile
				type={MatchingApproach.CHOOSE_TEMPLATE}
				onClick={() => {
					if (projectWizardState.currentProject.matchRules.length > 1 || isBaseRuleChanged()) {
						setShowModalChooseMatch(true)
						setMatchingSelected(MatchingApproach.CHOOSE_TEMPLATE)
					} else {
						action2MatchingApproach(MatchingApproach.CHOOSE_TEMPLATE)
					}
				}}
				isButtonDisabled={templateApproachButtonDisabled}
			/>
			<MatchingApproachTile
				type={MatchingApproach.START_SCRATCH}
				onClick={() => {
					if (projectWizardState.currentProject.matchRules.length > 1 || isBaseRuleChanged()) {
						setShowModalChooseMatch(true)
						setMatchingSelected(MatchingApproach.START_SCRATCH)
					} else {
						action2MatchingApproach(MatchingApproach.START_SCRATCH)
					}
				}}
			/>
		</div>
	)
}
