import { AxiosInstance, AxiosResponse } from 'axios'
import { SnowCloud, SnowCloudRegion, SnowRegion } from '../../components/snowflake-form/snowflake-form'
import { ConnectionStatus } from './getC4SActiveConnection'
import { System } from './getConnections'

interface AuthenticationSummary {
	status?: ConnectionStatus
	connectionName?: string
	userName?: string
	instanceUrl?: string
	organization?: string
	cloud?: SnowCloud
	region?: SnowRegion
	cloudRegion?: SnowCloudRegion
	connectionType?: System
	domainName?: string
	isSandbox?: boolean
}

export interface ConnectionDetail {
	name: string
	displayName?: string
	system?: System
	status?: ConnectionStatus
	created?: number
	updated?: number
	user?: string
	authenticationSummary: AuthenticationSummary
}

export const getConnection = async (apiClient: AxiosInstance, connectionName: string): Promise<ConnectionDetail> => {
	const url = `/pls/integration/connection/${connectionName}`
	return apiClient.get(url).then((response: AxiosResponse<ConnectionDetail>) => response.data)
}
