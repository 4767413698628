import { useTranslation } from 'react-i18next'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import {
	ChartKey,
	ColDef,
	ReportTable,
	ReportTableWrapper,
	RowDef
} from '../../../../../components/report-table/report-table'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { PieChart } from '../../../../../local-core-ui'
import { DataReport } from '../../../../../types'
import { chartColorsMap } from '../../chartColorsMap'
import styles from './email.quality.module.scss'

interface EmailQualityProps {
	emailQualityReport: DataReport['emailQualityReport'] | null
}

const INDETERMINATE_LABEL = 'Indeterminate (unknown)'
const rowHeight = '2rem'

const EmailQuality = ({ emailQualityReport }: EmailQualityProps) => {
	const { t } = useTranslation()
	const emailQualityList = emailQualityReport ? [...emailQualityReport.emailQualityList] : []
	const chartData: Record<string, number> = {}
	const chartColors: Record<string, string> = {}

	const emailQualityColumnDef: ColDef[] = [
		{
			label: t('report.page.emailVerification.tile.emailQuality.table.column1') as string,
			field: 'score'
		},
		{
			label: t('report.page.emailVerification.tile.emailQuality.table.column2') as string,
			field: 'count'
		},
		{
			label: t('report.page.emailVerification.tile.emailQuality.table.column3') as string,
			field: 'percentage'
		}
	]

	const tableRows: RowDef[] = emailQualityList
		.sort((a, b) => b.rate - a.rate)
		.map(({ description, count, total }, idx) => {
			const colorKey = description === INDETERMINATE_LABEL ? 'uncategory-1' : `category-${idx + 1}`
			const percentage = (count * 100) / total

			chartData[description] = count
			chartColors[description] = chartColorsMap.get(colorKey) ?? ''

			return [
				<ChartKey key={`key-${idx}`} label={description} color={chartColors[description]} />,
				new Intl.NumberFormat().format(count),
				percentage.toFixed(0).toLocaleString() + '%'
			]
		})

	return (
		<ReportTile title={t('report.page.emailVerification.tile.emailQuality.title') as string}>
			{emailQualityReport ? (
				<div className={styles.emailQualityContainer}>
					<div className={styles.pieChartContainer}>
						<PieChart
							diameter={200}
							colors={chartColors}
							data={chartData}
							describeWith={'none'}
							showKeys={false}
							showStrokes
						/>
					</div>
					<ReportTableWrapper>
						<ReportTable
							rowHeight={rowHeight}
							columns={emailQualityColumnDef}
							rows={tableRows}
							hideBorders
						/>
					</ReportTableWrapper>
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { EmailQuality }
