import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '../../local-core-ui/dropdown/dropdown'
import { Icon } from '../../local-core-ui/icon/icon'
import { SearchButtonNInput } from '../search-button-n-input/search-button-n-input'
import styles from './connections-filter-bar.module.scss'

export enum SortType {
	Name = 'name',
	Created = 'created',
	Updated = 'updated'
}

export interface ConnectionFilters {
	searchFilter: string
	sortBy: SortType
	sortByLabel?: string
	sortAscending: boolean
	view: 'tile' | 'list'
}

interface ConnectionsFilerBarProps {
	onChangeFilter?(filters: ConnectionFilters): void
	initialFilters: ConnectionFilters
}

export const ConnectionsFilterBar = ({ onChangeFilter, initialFilters }: ConnectionsFilerBarProps): ReactElement => {
	const { t } = useTranslation()
	const firstRender = useRef(true)
	const [connectionFilter, setConnectionFilter] = useState<ConnectionFilters>(initialFilters)
	const [searchFilter, setSearchFilter] = useState('')

	const dropdownOptions = [
		{
			label: t('dashboard.connections.sort.name'),
			value: SortType.Name
		},
		{
			label: t('dashboard.connections.sort.created'),
			value: SortType.Created
		},
		{
			label: t('dashboard.connections.sort.updated'),
			value: SortType.Updated
		}
	]

	const handleSortBy = (sort: ConnectionFilters['sortBy']) => {
		let updateProjectSort = connectionFilter
		updateProjectSort = {
			...updateProjectSort,
			sortByLabel: dropdownOptions.find(({ value }) => value === sort)?.label,
			sortBy: sort
		}
		setConnectionFilter(updateProjectSort)
	}

	const handleSearchFilter = (value: ConnectionFilters['searchFilter']) => {
		setConnectionFilter({ ...connectionFilter, searchFilter: value })
		setSearchFilter(value)
	}

	useEffect(() => {
		if (onChangeFilter && !firstRender.current) {
			onChangeFilter(connectionFilter)
		} else {
			firstRender.current = false
		}
		/**
		 * We only want to run this effect when connectionFilter changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionFilter])

	return (
		<div className={styles.leftSide}>
			<div className={styles.dropdownContainer}>
				<Dropdown
					id={'sort-projects'}
					label={''}
					hint={t('search.label.sortBy')}
					onChangeFunction={handleSortBy}
					options={dropdownOptions}
					selected={dropdownOptions.find(({ label }) => label === connectionFilter.sortByLabel)?.value}
					testId={'dropdown-sort'}
					size={'fluid'}
				/>
			</div>
			<div
				className={styles.sortIconContainer}
				onClick={() =>
					setConnectionFilter({ ...connectionFilter, sortAscending: !connectionFilter.sortAscending })
				}
			>
				<Icon
					testId="arrows-project-filter-bar"
					type={connectionFilter.sortAscending ? 'arrow-sort-down' : 'arrow-sort-up'}
					size={'mini'}
				/>
			</div>
			<SearchButtonNInput
				value={searchFilter}
				onChangeFilter={handleSearchFilter}
				hint={t('dashboard.connections.search.hint')}
				type={1}
				testId={'connections-search-bar'}
			/>
		</div>
	)
}
