import { AxiosResponse } from 'axios'
import { isEmpty } from 'lodash-es'
import { mapFE2BEDelimiter } from '../../../helpers/mapFE2BEDelimiter'
import { MappingSchema } from '../../../types'
import { ApiErrorState, setApiErrorAction } from '../../apiError'
import { AppThunk, getApiClient4Thunks, TDispatch, TGetState } from '../../index'
import { setAlternateInfoAction, updateCurrentProjectAction } from '../actions'
import { changeFieldTypesToText } from './changeFieldTypesToText'

export const getV2Mapping =
	(passDelimiterInfo = false): AppThunk<Promise<void>> =>
	(dispatch: TDispatch, getState: TGetState): Promise<void> => {
		const fileImportId = getState().projectWizard.currentProject.fileInfo?.uploadInfo?.file_import_id
		const fieldMap =
			getState().projectWizard.currentProject.mappingInfo.mapping.currentFieldDefinitionsRecord
				.fieldDefinitionsRecordsMap
		const sourceId = getState().projectWizard.currentProject.source.id
		const entityType = getState().projectWizard.currentProject.source.entityType
		const fileInfo = getState().projectWizard.currentProject.fileInfo
		const delimiterParam =
			passDelimiterInfo && mapFE2BEDelimiter(fileInfo?.delimiter)
				? `&delimiter=${mapFE2BEDelimiter(fileInfo?.delimiter)}`
				: ''

		const apiClient = getApiClient4Thunks(dispatch)

		const getAlternatesAutomap = (data: MappingSchema) => {
			const companyInformation =
				data?.currentFieldDefinitionsRecord?.fieldDefinitionsRecordsMap?.companyInformation
			const alternateAutoMapsFRomAPI = companyInformation.filter((row) => {
				return row.fieldName?.toLowerCase().includes('alternate')
			})
			let alternateCompanyExpanded = false
			let alternateAddressExpanded = false
			const AlternatesAutomap = alternateAutoMapsFRomAPI.reduce((accObj, row) => {
				const { fieldName } = row
				return { ...accObj, [fieldName]: row.columnName }
			}, {})
			localStorage.setItem('AlternatesAutomap', JSON.stringify(AlternatesAutomap))
			const existingMap = Object.keys(data.existingFieldDefinitionsMap)
			if (existingMap.length) {
				const alternateCompanyMap = existingMap.filter((column) => {
					return column.toLowerCase().includes('alternate')
				})
				alternateCompanyMap.length
					? (alternateCompanyExpanded = true)
					: companyInformation &&
					  companyInformation
							.filter((row) => {
								return (
									row.fieldName?.toLowerCase().includes('alternate') &&
									row.fieldName?.toLowerCase().includes('company')
								)
							})
							.forEach((row) => {
								row.columnName = undefined
								row.inCurrentImport = false
							})

				const alternateAdrsMap = existingMap.filter((column) => {
					return column.toLowerCase().includes('alternate') && !column.toLowerCase().includes('company')
				})
				alternateAdrsMap.length
					? (alternateAddressExpanded = true)
					: companyInformation &&
					  companyInformation
							.filter((row) => {
								return (
									row.fieldName?.toLowerCase().includes('alternate') &&
									!row.fieldName?.toLowerCase().includes('company')
								)
							})
							.forEach((row) => {
								row.columnName = undefined
								row.inCurrentImport = false
							})
			} else {
				companyInformation &&
					companyInformation
						.filter((row) => {
							return row.fieldName?.toLowerCase().includes('alternate')
						})
						.forEach((row) => {
							row.columnName = undefined
							row.inCurrentImport = false
						})
			}
			const alternateRegNumExpanded =
				existingMap.filter((column) => {
					return column.includes('Alternate_RegistrationNumber')
				}).length > 0

			data.currentFieldDefinitionsRecord.fieldDefinitionsRecordsMap.companyInformation = companyInformation
			dispatch(
				setAlternateInfoAction({
					alternateCompanyExpanded: alternateCompanyExpanded,
					alternateAdrsExpanded: alternateAddressExpanded,
					alternateRegNumExpanded: alternateRegNumExpanded
				})
			)
			return data
		}

		let url = `/pls/sources/mappings?entityType=${entityType}${sourceId ? '&sourceId=' + sourceId : ''}${
			fileImportId ? '&fileImportId=' + fileImportId : ''
		}${delimiterParam}`
		//Integration scenario (Salesforce) uses extra URL parameters
		const { platform, orgid } = getState().session
		const thirdPartyIntegration = getState().projectWizard.currentProject.thirdPartyIntegration
		if (thirdPartyIntegration) {
			const sourceType = thirdPartyIntegration ? thirdPartyIntegration.crmSource : ''

			url += `&systemType=${platform}&systemId=${orgid}&documentType=${sourceType}`
		}
		if (fileImportId || sourceId) {
			return apiClient
				.get(url)
				.then((response: AxiosResponse<MappingSchema>) => {
					if (fileImportId && isEmpty(fieldMap)) changeFieldTypesToText(response.data)
					const mappingUpdated = getAlternatesAutomap(response.data)
					const mapping: MappingSchema = response.data
					dispatch(
						updateCurrentProjectAction({
							mappingInfo: {
								counterGroupsMapping:
									getState().projectWizard.currentProject.mappingInfo.counterGroupsMapping,
								mapping: mapping
							}
						})
					)
				})
				.catch((e) => {
					const errorData: ApiErrorState = {
						errorCode: e.response?.status,
						message: e.response?.data.UIAction.message,
						additionalInfo: 'erroneous.source.additional.info'
					}
					dispatch(setApiErrorAction(errorData))
					console.error(e)
				})
		} else return Promise.resolve()
	}
