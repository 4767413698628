import { Upload, UploadType } from '../../types'
import { Stat } from './upload-tile-stats'

type Languages = (key: string) => string

const getStats = (upload: Upload, importData: number) => {
	if (
		(upload?.type === UploadType.IMPORT || upload?.type === UploadType.PP_MATCH_ENRICH) &&
		upload?.uploadStats?.match?.matched
	) {
		return upload.uploadStats.match.matched.toLocaleString()
	} else if (upload?.type === UploadType.REFRESH && upload?.uploadStats?.refresh?.enriched) {
		return upload.uploadStats.refresh.enriched.toLocaleString()
	} else if (
		(upload?.type === UploadType.ENRICH_ONLY || upload.type === UploadType.UPLOAD_REFRESH) &&
		upload?.uploadStats?.enrichOnly?.enriched
	) {
		return upload.uploadStats.enrichOnly.enriched.toLocaleString()
	}
	return importData ? '0' : '- -'
}

const getLabel = (upload: Upload, t: Languages) =>
	upload?.type === UploadType.REFRESH ||
	upload?.type === UploadType.ENRICH_ONLY ||
	upload.type === UploadType.UPLOAD_REFRESH
		? t('upload.tile.label.updated')
		: t('upload.tile.label.matched')

export const extractStatsFromUpload = (upload: Upload, t: Languages): Array<Stat> => {
	return upload?.isApiAggregation
		? [
				{
					label: t('project.status.submitted'),
					data: upload.uploadStats?.import.submitted
						? upload.uploadStats.import.submitted.toLocaleString()
						: '- -',
					emphasizeData: true
				},
				{
					label: getLabel(upload, t),
					data: getStats(upload, upload.uploadStats?.import.submitted),
					emphasizeData: true
				}
		  ]
		: [
				{
					label: t('upload.tile.uploadedBy'),
					data: upload.createdBy,
					emphasizeData: true
				},
				{
					label: t('project.status.submitted'),
					data: upload.uploadStats?.import.successfullyIngested
						? upload.uploadStats.import.successfullyIngested.toLocaleString()
						: '- -',
					emphasizeData: true
				},
				{
					label: getLabel(upload, t),
					data: getStats(upload, upload.uploadStats?.import.successfullyIngested),
					emphasizeData: true
				}
		  ]
}
