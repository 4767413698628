import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../../components/spinner/spinner'
import { Tile } from '../../components/tile/tile'
import { Divider, Grid, ProgressBar } from '../../local-core-ui'
import { useRumUsage } from '../../queries/useRumUsage'
import { Meters, RumUsage } from '../../types'
import scss from './dashboard-rumUsage.module.scss'

export const DashboardRumUsage = (): ReactElement => {
	const { t } = useTranslation()
	const usageQuery = useRumUsage()
	const MAX_BAR_WIDTH = 1025
	const [barWidth, setBarWidth] = useState(0)
	const [element, setElement] = useState<HTMLDivElement | null>(null)

	const refCallback = useCallback((node: HTMLDivElement) => {
		if (node) {
			setElement(node)
		}
	}, [])

	useEffect(() => {
		const observerTarget = element

		const resizeObserver = new ResizeObserver((entries) => {
			const targetWidth = entries[0].contentRect.width

			if (targetWidth < MAX_BAR_WIDTH) {
				setBarWidth(targetWidth - 18)
			} else {
				setBarWidth(MAX_BAR_WIDTH)
			}
		})

		if (observerTarget) {
			resizeObserver.observe(observerTarget)
		}

		return () => {
			if (observerTarget) {
				resizeObserver.unobserve(observerTarget)
			}
		}
	}, [element])

	return (
		<div className={scss.dashboardRumUsage}>
			<Tile testId="dashboard-rum-usage-tile" className={scss.dashboardRumUsageTile}>
				<h1 data-testid="dashboard-rumUsage-title" className={scss.dashboardRumUsageTitle}>
					{t('dashboard.rumUsage.title')}
				</h1>
				<p className={scss.dashboardRumUsageDescription}>{t('dashboard.rumUsage.description')}</p>
				{/*TODO add placeholder for contract terms here*/}
				{usageQuery.data?.length ? (
					<div>
						{usageQuery.data.map((usage: RumUsage) => {
							return (
								<div key={usage.name}>
									<h2 key={'header-' + usage.name} className={scss.dashboardRumUsageName}>
										{usage.name}
									</h2>
									{usage.meters.map((meter: Meters) => {
										return (
											<Tile
												testId="dashboard-rum-meters-tile"
												key={'dashboard-rumUsage-meters-tile-' + meter.name}
												className={scss.dashboardRumUsageMetersTile}
											>
												<Grid testId="rum-container" container>
													<Grid testId="rum-name" lg={9} md={9} sm={9}>
														<div className={scss.dashboardRumUsageMetersName}>
															{meter.name}
														</div>
														<div
															className={scss.dashboardRumUsageMetersBar}
															ref={refCallback}
														>
															<ProgressBar
																id="dashboardRumUsageProgressBar"
																value={meter.coverageUsed}
																total={meter.totalCoverage}
																svgWidth={barWidth}
																svgHeight={30}
																colors={{
																	barColor: '$color-slate-light',
																	containerColor: '$color-blue-primary'
																}}
																displayPercentage={true}
																precision={0}
															/>
														</div>
													</Grid>
													<Grid testId="rum-tile" lg={3} md={3} sm={3}>
														<Tile
															key={'meters-' + meter.name}
															className={scss.dashboardRumUsageMetersDataTile}
															testId="dashboard-rum-data-tile"
														>
															<Grid testId="rum-container-labels" container>
																<Grid testId="rum-total" sm={8}>
																	<div className={scss.dashboardRumUsageMetersLabel}>
																		{t('dashboard.rumUsage.total')}
																	</div>
																</Grid>
																<Grid testId="rum-coverage" sm={4}>
																	<div className={scss.dashboardRumUsageMetersData}>
																		{meter.totalCoverage}
																	</div>
																</Grid>
															</Grid>
															<Grid testId="rum-used-container" container>
																<Grid testId="rum-used-label" sm={8}>
																	<div className={scss.dashboardRumUsageMetersLabel}>
																		{t('dashboard.rumUsage.used')}
																	</div>
																</Grid>
																<Grid testId="rum-coverage-used" sm={4}>
																	<div className={scss.dashboardRumUsageMetersData}>
																		{meter.coverageUsed}
																	</div>
																</Grid>
															</Grid>
															<Divider size="small" color="color-jet-black" />
															<Grid testId="rum-container-remaining" container>
																<Grid testId="rum-label-remaining" sm={8}>
																	<div className={scss.dashboardRumUsageMetersLabel}>
																		{t('dashboard.rumUsage.remaining')}
																	</div>
																</Grid>
																<Grid testId="rum-coverage-remaining" sm={4}>
																	<div className={scss.dashboardRumUsageMetersData}>
																		{meter.coverageRemaining}
																	</div>
																</Grid>
															</Grid>
															<Grid testId="rum-container-overage" container>
																<Grid testId="rum-first-overage" sm={8}>
																	<div>
																		{meter.overage > 0 ? (
																			<div
																				className={
																					scss.dashboardRumUsageMetersLabelOverage
																				}
																			>
																				{t('dashboard.rumUsage.overage')}
																			</div>
																		) : (
																			<div
																				className={
																					scss.dashboardRumUsageMetersLabel
																				}
																			>
																				{t('dashboard.rumUsage.overage')}
																			</div>
																		)}
																	</div>
																</Grid>
																<Grid testId="rum-second-overage" sm={4}>
																	<div>
																		{meter.overage > 0 ? (
																			<div
																				className={
																					scss.dashboardRumUsageMetersOverage
																				}
																			>
																				{meter.overage}
																			</div>
																		) : (
																			<div
																				className={
																					scss.dashboardRumUsageMetersData
																				}
																			>
																				{meter.overage}
																			</div>
																		)}
																	</div>
																</Grid>
															</Grid>
														</Tile>
													</Grid>
												</Grid>
											</Tile>
										)
									})}
								</div>
							)
						})}
					</div>
				) : usageQuery.isFetching ? (
					<div className={scss.loadingUsersSpinner}>
						<Spinner />
					</div>
				) : null}
			</Tile>
		</div>
	)
}
