import { Button, Divider } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColorGrayDark } from '../../../../design-tokens/build/shared/token-colors.json'
import { getLocalizedDateString } from '../../helpers'
import { Icon } from '../../local-core-ui'
import { System } from '../../queries/api/getConnections'
import { useConnectionSources } from '../../queries/useConnectionSources'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { Spinner } from '../spinner/spinner'
import styles from './connection-source.module.scss'
interface Props {
	connectionName: string
	platform?: System
}
type SortOrder = 'asc' | 'desc'

export const ConnectionSources = ({ connectionName, platform }: Props) => {
	const { t } = useTranslation()
	const sourcesData = useConnectionSources(connectionName || '')
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const { data, isLoading } = sourcesData

	const startIndex = (currentPage - 1) * itemsPerPage
	const endIndex = startIndex + itemsPerPage
	const sourceList = data
	// const displayedItems = sourceList?.slice(startIndex, endIndex)

	const [sortColumn, setSortColumn] = useState<string>('name')
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc')

	const handleItemsPerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setItemsPerPage(parseInt(event.target.value, 10))
		setCurrentPage(1)
	}

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1)
		}
	}

	const handleNextPage = () => {
		if (data && currentPage * itemsPerPage < data.length) {
			setCurrentPage(currentPage + 1)
		}
	}

	if (isLoading) {
		return (
			<div>
				<Spinner />
			</div>
		)
	}

	const handleSort = (column: string) => {
		if (sortColumn === column) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
		} else {
			setSortColumn(column)
			setSortOrder('asc')
		}
	}

	const sortedData = [...data].sort((a, b) => {
		if (sortColumn === 'creationDate') {
			return sortOrder === 'asc'
				? new Date(a.created).getTime() - new Date(b.created).getTime()
				: new Date(b.created).getTime() - new Date(a.created).getTime()
		}
		if (sortColumn === 'createdBy') {
			return sortOrder === 'asc' ? a.createdBy.localeCompare(b.createdBy) : b.createdBy.localeCompare(a.createdBy)
		}
		// Add other columns sorting logic if needed
		return sortOrder === 'asc'
			? a[sortColumn].localeCompare(b[sortColumn])
			: b[sortColumn].localeCompare(a[sortColumn])
	})

	const displayedItems = sortedData.slice(startIndex, endIndex)

	return (
		<>
			{!data || data.length === 0 ? (
				<Snackbar title={t('connection.no.sources')} type={SnackType.notification} isBanner />
			) : (
				<div className={styles.connectionsContainer}>
					<section className={styles.tableContainer}>
						<div className={styles.divider} />
						<div className={styles.tableHeader}>
							<div className={`${styles.nameCol} ${styles.col}`} onClick={() => handleSort('name')}>
								{t('connection.source.name')}
							</div>
							<div className={`${styles.typeCol} ${styles.col}`} onClick={() => handleSort('schemaTYpe')}>
								{t('connection.source.type')}
							</div>
							{platform === System.Salesforce && (
								<div className={`${styles.sfdcCol} ${styles.col}`} onClick={() => handleSort('object')}>
									{t('connection.source.object')}
								</div>
							)}
							<div
								className={`${styles.createCol} ${styles.col}`}
								onClick={() => handleSort('creationDate')}
							>
								{t('connection.source.creation.date')}
							</div>
							<div
								className={`${styles.createByCol} ${styles.col}`}
								onClick={() => handleSort('createdBy')}
							>
								{t('connection.source.created.by')}
							</div>
							<div className={`${styles.statusCol} ${styles.col}`}>{t('connection.source.status')}</div>
						</div>
						{displayedItems?.map((ConnectionSource, index) => (
							<div key={index}>
								{index === 0 ? <div className={styles.dividerG} /> : <Divider color={ColorGrayDark} />}

								<div className={styles.row}>
									<div className={`${styles.nameCol} ${styles.col}`}>{ConnectionSource.name}</div>
									<div className={`${styles.typeCol} ${styles.col}`}>
										{ConnectionSource.schemaTYpe === 'Contact' ? 'Contact' : 'Company'}
									</div>
									{platform === System.Salesforce && (
										<div className={`${styles.sfdcCol} ${styles.col}`}>
											{ConnectionSource.object}
										</div>
									)}
									<div className={`${styles.createCol} ${styles.col}`}>
										{getLocalizedDateString(ConnectionSource.created)}
									</div>
									<div className={`${styles.createByCol} ${styles.col}`}>
										{ConnectionSource.createdBy}
									</div>
									<div className={`${styles.statusCol} ${styles.col} `}>
										<div
											className={
												ConnectionSource.archived ? `${styles.archive}` : `${styles.active}`
											}
										>
											{ConnectionSource.archived ? 'Archived' : 'Active'}
										</div>
									</div>
								</div>
							</div>
						))}
						<div className={styles.divider} />
					</section>
					<div className={styles.paginationContainer}>
						<div className={styles.itemsPerPage}>
							<label htmlFor="itemsPerPage">{t('connection.source.items.per.page')}</label>
							<select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
								<option value={10}>10</option>
								<option value={20}>20</option>
								<option value={30}>30</option>
								<option value={40}>40</option>
								<option value={50}>50</option>
							</select>
							<label>{`${startIndex + 1}-${Math.min(endIndex, data.length)} ${t(
								'connection.source.of'
							)} ${data.length} items`}</label>
							<div className={styles.paginationControls}>
								<div className={styles.button}>
									<Button onClick={handlePrevPage} disabled={currentPage === 1}>
										<Icon testId="chevron-left-source" type="chevron-left" size="mini" />{' '}
									</Button>
								</div>
								<div className={styles.button}>
									<Button
										onClick={handleNextPage}
										disabled={currentPage * itemsPerPage >= data.length}
									>
										<Icon testId="chevron-right-source" type="chevron-right" size="mini" />{' '}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
