import { useTranslation } from 'react-i18next'
import { ReportProgressBar } from '../../../../../components/report-progress-bar/report-progress-bar'
import { ReportSnackbar } from '../../../../../components/report-snackbar/report-snackbar'
import { ReportTile } from '../../../../../components/report-tile/report-tile'
import { DataReport } from '../../../../../types'
import styles from './email-category.module.scss'

interface EmailCategoryProps {
	emailCategoryReport: DataReport['emailCategoryReport'] | null
}

const EmailCategory = ({ emailCategoryReport }: EmailCategoryProps) => {
	const { t } = useTranslation()
	const emailCategoryList = emailCategoryReport ? emailCategoryReport.emailCategoryList : []

	return (
		<ReportTile
			title={t('report.page.emailVerification.tile.emailCategory.title') as string}
			className={styles.emailCategoryTile}
		>
			{emailCategoryReport ? (
				<div className={styles.barChartsContainer}>
					{emailCategoryList.map(({ description, count }, idx) => (
						<div key={`email-category-element-${idx}`} className={styles.progressBarContainer}>
							<span className={styles.progressBarLabel}>{t(`label.graph.${description}`)}</span>
							<ReportProgressBar
								id={`email-category-bar-${idx}`}
								value={count}
								total={emailCategoryReport.total}
								variant={'meter'}
								width={286}
							/>
						</div>
					))}
				</div>
			) : (
				<ReportSnackbar />
			)}
		</ReportTile>
	)
}

export { EmailCategory }
