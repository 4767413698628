import { t } from 'i18next'
import { ReportTile } from '../../../../components/report-tile/report-tile'
import styles from '../../report.module.scss'
import { BaseReportSection } from '../../types/BaseReportSection'
import { MatchDrivers } from './match-drivers/match-drivers'
import { MatchResults } from './match-results/match-results'

type ContactMatchSummaryProps = BaseReportSection

const ContactMatchSummary = ({ uploadReport }: ContactMatchSummaryProps) => {
	const contactMatchReport = uploadReport ? uploadReport.matchToContactReport : null

	return (
		<ReportTile
			className={styles.contactMatchSummary}
			title={t('report.page.contactMatchSummary.title') as string}
			subtitle={t('report.page.contactMatchSummary.subtitle') as string}
		>
			<MatchResults contactMatchReport={contactMatchReport} />
			<MatchDrivers contactMatchReport={contactMatchReport} />
		</ReportTile>
	)
}

export { ContactMatchSummary }
