import cx from 'classnames'
import { FC } from 'react'
import world from '../assets/data/world.json'
import Flag from '../flags/flag'
import styles from './geographical-distribution-map.module.scss'

interface IDistributionData {
	countryCode: string
	total: number
	percent: number
}

export interface IGDMTooltipProps {
	data: IDistributionData
	visible: boolean
	translationFunction(i18nKey: string): string
	testId?: string
}

export const GDMTooltip: FC<IGDMTooltipProps> = ({ data, visible, translationFunction, testId }: IGDMTooltipProps) => {
	const i18nKeyPrefix = 'geographical.distribution.map.tooltip.'
	const lblTotalRecords = translationFunction ? translationFunction(i18nKeyPrefix + 'total.records') : 'Total records'

	const getCountryTranslation = (countryCode): string => {
		let name = world.features.filter((c) => c.id2 === countryCode)[0]?.properties.name || countryCode
		if (translationFunction && translationFunction('country.' + countryCode.toLowerCase())) {
			name = translationFunction('country.' + countryCode.toLowerCase())
		}
		return name
	}

	return (
		<div
			data-testid={`gdm-tooltip-container-${testId}`}
			className={cx(styles.tooltip, { [styles.visible]: visible })}
		>
			<div data-testid={`gdm-tooltip-header-${testId}`} className={styles.head}>
				<div data-testid={`gdm-flag-container-${testId}`} className={styles.tooltipFlagContainer}>
					<Flag testId={`gdm-flag-${testId}`} countryCode={data.countryCode} />
				</div>
				{getCountryTranslation(data.countryCode)}
			</div>
			<table data-testid={`gdm-table-container-${testId}`} className={styles.countryDataTable}>
				<tbody data-testid={`gdm-table-tbody-${testId}`}>
					<tr data-testid={`gdm-table-tr-${testId}`}>
						<td data-testid={`gdm-table-td-total-${testId}`}>{lblTotalRecords}:</td>
						<td data-testid={`gdm-table-td-numeric-${testId}`} className={styles.numeric}>
							{data.total.toLocaleString()}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
