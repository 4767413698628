import cx from 'classnames'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadType } from '../../types'
import styles from './upload-tile.module.scss'

export type Stat = {
	label: string
	data: string
	emphasizeData?: boolean
}

interface UploadTileStatsProps {
	stats: Array<Stat>
	testId?: string
	type?: UploadType
}

export const UploadTileStats = ({ stats, testId, type }: UploadTileStatsProps): ReactElement => {
	const { t } = useTranslation()
	const hideMatched = type === UploadType.UPLOAD_REFRESH

	return (
		<>
			{stats.map((stat) => {
				if (hideMatched && stat.label === t('upload.tile.label.matched')) {
					return (
						<div
							data-testid={`upload-tile-container-stat-empty`}
							className={cx(styles.uploadTileStatContainer, styles.emptyStat)}
							key={`upload-tile-stat-empty`}
						/>
					)
				} else {
					return (
						<div
							data-testid={`upload-tile-container-${testId}-${stat.label}`}
							className={styles.uploadTileStatContainer}
							key={`upload-tile-stat-${stat.label}`}
						>
							<p
								data-testid={`upload-tile-label-${testId}-${stat.label}`}
								className={styles.uploadTileStatLabel}
							>
								{stat.label}
							</p>
							<p
								className={`${styles.uploadTileStatData} ${
									stat.emphasizeData ? styles.emphasizeData : ''
								}`}
								data-testid={`${testId}-upload-tile-stat-data-${stat.label
									.toLowerCase()
									.replaceAll(' ', '_')}`}
							>
								{stat.data}
							</p>
						</div>
					)
				}
			})}
		</>
	)
}
