import { ComplianceInsightsPage } from '../compliance-insights-page/compliance-insights-page'
import { IRouteProps } from '../components/switch-routes/switch-routes'
import { DashboardAllUsers } from './allUsers/dashboard-allUsers'
import { DashboardConnections } from './connections/dashboard-connections'
import { ViewMyDataBlocks } from './myDataBlocks/dashboard-myDataBlocks'
import { DashboardOverview } from './overview/dashboard-overview'
import { DashboardProjects } from './projects/dashboard-projects'
import { DashboardRumUsage } from './rumUsage/dashboard-rumUsage'
import { DashboardSettings } from './settings/dashboard-settings'
import { DashboardSteward } from './steward/dashboard-steward'

export const DashboardRoutes: IRouteProps[] = [
	{ path: '/dashboard/overview', label: 'dashboard.overview', component: DashboardOverview },
	{ path: '/dashboard/projects', label: 'dashboard.tab.projects', component: DashboardProjects },
	{
		path: '/connections',
		label: 'dashboard.connections.title',
		features: ['EnableConnectionManagement'],
		accessLevel: ['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'],
		component: DashboardConnections
	},
	{
		path: '/all-users',
		label: '',
		component: DashboardAllUsers,
		entitlement: 'PLS_Users',
		actions: ['MayEdit'],
		exact: true
	},
	{
		path: '/steward',
		label: 'dashboard.tab.steward',
		component: DashboardSteward,
		modules: ['Stewardship'],
		entitlement: 'Data_Stewardship',
		actions: ['MayEdit']
	},
	{ path: '/rum-usage', label: '', component: DashboardRumUsage },
	{ path: '/my-datablocks', label: '', component: ViewMyDataBlocks },
	{ path: '/settings', label: '', component: DashboardSettings },
	{ path: '/compliance-insights', label: '', features: ['InternationalContacts'], component: ComplianceInsightsPage }
]

export default DashboardRoutes
