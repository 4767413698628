import { DNBButton } from '@dnb-uux-design-system/react'
import { Download } from '@mui/icons-material'
import { ReactElement, Ref, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Tile } from '../components/tile/tile'
import styles from './compliance-insights-page.module.scss'

export function ComplianceInsightsPage(): ReactElement {
	const { t } = useTranslation()
	const compliancePDFEl: Ref<HTMLAnchorElement> = useRef(null)
	const compliancePDF = '/Professional Contact Data Compliance Insights.pdf'

	return (
		<div className={styles.complianceContainer}>
			<Tile testId={'compliance-insights-tile'} className={styles.complianceTile}>
				<h1 data-testid={'compliance-title'} className={styles.complianceTitle}>
					{t('title.contactsCompliance')}
				</h1>
				<div className={styles.containerDescription}>
					<p className={styles.complianceDescription}>{t('description.contactsCompliance')}</p>
					<a href={compliancePDF} ref={compliancePDFEl} download>
						<DNBButton
							size={'small'}
							variant="primary"
							data-testid="download-compliance"
							startIcon={<Download />}
						>
							{t('footer.buttons.download')}
						</DNBButton>
					</a>
				</div>
			</Tile>
		</div>
	)
}
