import { AxiosResponse } from 'axios'
import { Button, LoadingState, Modal } from '../../local-core-ui/'

import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useApi } from '../../hooks'
import { MatchStepV2 } from '../../project/steps/matching-v2/match-step/match-step-v2'
import { getMatchLayoutFacadeByTemplates } from '../../project/steps/matching-v2/template-picker/getMatchLayoutFacadeByTemplates'
import { useMatchTemplates } from '../../queries/useMatchTemplates'
import { useUsers } from '../../queries/useUsers'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import { clearCurrentMatchConfigTemplate, updateCurrentProjectAction } from '../../store/projectWizard/actions'
import { BASE_RULE, MatchRuleInfo } from '../../store/projectWizard/types'
import { GetMatchRulesResponse, MatchRule, UserDetail } from '../../types'
import { MatchTemplateTile } from '../match-template-tile/match-template-tile'
import { ActiveFilters, TemplateTilePicker } from '../template-tile-picker/template-tile-picker'
import styles from './match-template-picker.module.scss'

export interface MatchTemplateFacade {
	templateId: string
	name: string
	createdBy: UserDetail
	createTime: number
	updateTime?: number
	matchRules: MatchRule[]
}

interface MatchTemplatePickerProps {
	open: boolean
	onClose: () => void
	onSelect?: () => void
	testId: string
	sourceId?: string
	onTemplatesClear: () => void
}

type ModalContentType = 'template_preview' | 'remove_template_confirmation' | 'template_removed' | 'template_library'

export const MatchTemplatePicker = ({
	open,
	onClose,
	onSelect,
	testId,
	sourceId,
	onTemplatesClear
}: MatchTemplatePickerProps): ReactElement => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const [filteredTemplates, setFilteredTemplates] = useState<Array<MatchTemplateFacade>>([])
	const [activeFilters, setActiveFilters] = useState<ActiveFilters>()
	const [currentTemplate, setCurrentTemplate] = useState<string>()
	const [currentTemplateRules, setCurrentTemplateRules] = useState<Array<MatchRule>>()
	const [sourceBaseRule, setSourceBaseRule] = useState<MatchRuleInfo>()
	const [removeTemplateId, setRemoveTemplateId] = useState<string>()
	const [removeTemplateName, setRemoveTemplateName] = useState<string>()
	const [previewTemplate, setPreviewTemplate] = useState<{ id: string; name: string }>({ id: '', name: '' })
	const [matchTemplateList, setMatchTemplateList] = useState<Array<MatchTemplateFacade>>([])
	const [modalContent, setModalContent] = useState<ModalContentType>('template_library')
	const selectSession = (state: RootState) => state.session
	const session = useAppSelector(selectSession)
	const selectProjectWizard = (state: RootState) => state.projectWizard
	const projectWizardState = useAppSelector(selectProjectWizard)
	const apiClient = useApi()
	const currentUser = session.user?.EmailAddress
	const usersQuery = useUsers()
	const matchTemplatesQuery = useMatchTemplates()
	const queryClient = useQueryClient()

	const onChangeFilteredTemplates = (templateList: Array<MatchTemplateFacade>, filters: ActiveFilters) => {
		setFilteredTemplates(templateList)
		setActiveFilters(filters)
	}

	const getTemplateFromList = (templateId: string, templateList: Array<MatchTemplateFacade>) => {
		return templateList.find((filteredTemplate) => {
			return filteredTemplate.templateId === templateId
		})
	}

	const addMatchRuleAdditionalInfo = (matchRules: Array<MatchRule>): Array<MatchRuleInfo> => {
		return matchRules.map((matchRule) => {
			const matchRuleTemplateId = matchRule.matchRuleTemplateId || matchRule.matchRuleId
			if (matchRule.ruleType === BASE_RULE) matchRule.matchRuleId = sourceBaseRule?.matchRule.matchRuleId
			else matchRule.matchRuleId = undefined
			return {
				matchRuleTemplateId: matchRuleTemplateId,
				matchRule: matchRule,
				matchQualityType: 'EASY',
				advancedSettingsCollapsed: false
			}
		})
	}

	const getMatchTemplatesList = () => {
		if (matchTemplatesQuery.data) {
			getMatchLayoutFacadeByTemplates(
				matchTemplatesQuery.data,
				usersQuery.data || [],
				projectWizardState.currentProject.source.id ? projectWizardState.currentProject.source.id : ''
			).then((matchFacadeList) => {
				setMatchTemplateList(matchFacadeList)
			})
		}
	}

	const onRemoveTemplateConfirmation = () => {
		const url = `/pls/match-template/matchConfigTemplateId/${removeTemplateId}`
		apiClient.delete(url).then(() => {
			const idx = matchTemplateList.findIndex((template) => template.templateId === removeTemplateId)
			if (idx > -1) {
				setModalContent('template_removed')
			}
			if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId === removeTemplateId) {
				dispatch(clearCurrentMatchConfigTemplate())
				if (sourceBaseRule)
					dispatch(
						updateCurrentProjectAction({
							matchRules: [sourceBaseRule]
						})
					)
			}
			queryClient.invalidateQueries('getMatchTemplates')
			queryClient.invalidateQueries(['getMatchTemplates', removeTemplateId])
		})
	}

	const onClickBackToLibrary = () => {
		if (sourceBaseRule)
			dispatch(
				updateCurrentProjectAction({
					matchRules: [sourceBaseRule]
				})
			)
		setPreviewTemplate({ id: '', name: '' })
		setModalContent('template_library')
	}

	const onCloseTemplateLibraryOrPreview = () => {
		if (currentTemplate && currentTemplateRules) {
			const matchRuleAdditionalInfo = addMatchRuleAdditionalInfo(currentTemplateRules)
			dispatch(
				updateCurrentProjectAction({
					matchRules: matchRuleAdditionalInfo
				})
			)
		} else if (sourceBaseRule) {
			dispatch(
				updateCurrentProjectAction({
					matchRules: [sourceBaseRule]
				})
			)
		}
		setModalContent('template_library')
		setPreviewTemplate({ id: '', name: '' })
		onClose()
	}

	const onApplyTemplate = (templateId: string) => {
		const selectedElement = getTemplateFromList(templateId, filteredTemplates)
		if (selectedElement) {
			dispatch(
				updateCurrentProjectAction({
					currentMatchConfigTemplate: {
						templateId: selectedElement.templateId,
						templateName: selectedElement.name,
						createdBy: selectedElement.createdBy.Email
					},
					matchRules: addMatchRuleAdditionalInfo(selectedElement.matchRules)
				})
			)
			if (onSelect) onSelect()
		}
	}

	const onPreviewTemplate = (templateId: string) => {
		const selectedElement = getTemplateFromList(templateId, filteredTemplates)
		if (selectedElement) {
			dispatch(
				updateCurrentProjectAction({
					matchRules: addMatchRuleAdditionalInfo(selectedElement.matchRules)
				})
			)
		}
		setPreviewTemplate({ id: templateId, name: selectedElement ? selectedElement.name : '' })
		setModalContent('template_preview')
	}

	const getModalContent = () => {
		switch (modalContent) {
			case 'template_preview':
				return (
					<MatchStepV2
						readonly
						onClickBackToLibrary={onClickBackToLibrary}
						matchTitle={previewTemplate.name}
					/>
				)
			case 'remove_template_confirmation':
				return (
					<div className={styles.removeTemplateModal}>
						<div className={styles.text}>{t('match.template.remove')}</div>
						<div className={styles.buttonContainer}>
							<Button
								onClick={onRemoveTemplateConfirmation}
								text={t('match.template.remove.button')}
								testId={'confirm-remove-button'}
							/>
							<Button
								onClick={() => {
									setModalContent('template_library')
								}}
								text={t('confirmation.modal.cancel')}
								testId={'cancel-button'}
								type={'secondary'}
							/>
						</div>
					</div>
				)
			case 'template_removed':
				return (
					<div className={styles.successRemovedTemplateModal}>
						<div className={styles.text}>
							{t('match.template.removed', { templateName: removeTemplateName })}
						</div>
						<div className={styles.buttonContainer}>
							<Button
								onClick={() => {
									if (matchTemplateList.length === 0) {
										onTemplatesClear()
									}
									setModalContent('template_library')
								}}
								text={t('match.template.removed.button')}
								testId={'deleted'}
							/>
						</div>
					</div>
				)
			case 'template_library':
				return (
					<>
						{matchTemplatesQuery.isFetching && (
							<div className={'loading-state-wrapper'}>
								<LoadingState />
							</div>
						)}
						<TemplateTilePicker
							listTemplates={matchTemplateList}
							testId={testId + '-match-template-tile-picker'}
							title={t('match.template.picker.title')}
							onChangeFilteredTemplates={onChangeFilteredTemplates}
							filters={activeFilters}
							isLoading={matchTemplatesQuery.isFetching}
						>
							{filteredTemplates.map((template, idx) => (
								<div key={'match-template-tile-' + idx}>
									<MatchTemplateTile
										templateId={template.templateId}
										name={template.name}
										createdBy={template.createdBy}
										date={template.updateTime || template.createTime}
										matchRules={template.matchRules}
										isOwnerOrAdmin={
											template.createdBy.Email === currentUser ||
											template.createdBy.id === currentUser
										}
										testId={`match-template-tile-${idx}`}
										flipped={previewTemplate.id === template.templateId}
										functionApplyTemplate={(templateId: string) => onApplyTemplate(templateId)}
										functionPreviewTemplate={(templateId: string) => onPreviewTemplate(templateId)}
										functionRemoveTemplate={(templateId: string, templateName: string) => {
											setModalContent('remove_template_confirmation')
											setRemoveTemplateId(templateId)
											setRemoveTemplateName(templateName)
										}}
									/>
								</div>
							))}
						</TemplateTilePicker>
					</>
				)
			default:
				break
		}
	}

	useEffect(() => {
		if (matchTemplatesQuery.data) getMatchTemplatesList()
		/**
		 * function is not added as a dependency because it would cause the other dependencies to change with each render.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchTemplatesQuery.isFetching])

	useEffect(() => {
		let mounted = true
		if (matchTemplatesQuery.data) {
			const templateList = matchTemplatesQuery.data
			if (mounted)
				getMatchLayoutFacadeByTemplates(
					templateList,
					usersQuery.data || [],
					projectWizardState.currentProject.source.id ? projectWizardState.currentProject.source.id : ''
				).then((matchFacadeList) => {
					if (mounted) setMatchTemplateList(matchFacadeList)
				})
		}
		return () => {
			mounted = false
		}
		/**
		 * We only want to run this effect when the isSuccess flags in usersQuery or matchTemplatesQuery or
		 * the source id changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [matchTemplatesQuery.isSuccess, projectWizardState.currentProject.source.id, usersQuery.isSuccess])

	useEffect(() => {
		if (sourceId) {
			const url = `/pls/matchrules/sourceId/${sourceId}?includeArchived=false&includeInactive=false`
			apiClient.get(url).then((response: AxiosResponse<Array<GetMatchRulesResponse>>) => {
				const sourceRules = response.data
				const baseRule = sourceRules.find((rule) => rule.ruleType === BASE_RULE)
				if (baseRule) {
					setSourceBaseRule({
						matchRule: baseRule,
						matchQualityType: 'EASY',
						advancedSettingsCollapsed: false
					})
				}
			})
		}
		/**
		 * We only want to run this effect when the sourceId changes
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceId])

	useEffect(() => {
		if (projectWizardState.currentProject.currentMatchConfigTemplate?.templateId) {
			const storedTemplateId = projectWizardState.currentProject.currentMatchConfigTemplate.templateId
			const storedTemplate = getTemplateFromList(storedTemplateId, matchTemplateList)
			setCurrentTemplate(storedTemplateId)
			if (storedTemplate?.matchRules && storedTemplate.matchRules.length > 0) {
				setCurrentTemplateRules(storedTemplate.matchRules)
			}
		}
	}, [projectWizardState.currentProject.currentMatchConfigTemplate, matchTemplateList])

	return (
		<div className={styles.matchTemplatePickerModalContainer} data-testid="match-template-picker-modal-container">
			<Modal
				open={open}
				isContainer={true}
				onClose={onCloseTemplateLibraryOrPreview}
				showButtonClose={modalContent === 'template_library' || modalContent === 'template_preview'}
				testId="MatchTemplatePickerModal"
			>
				{getModalContent()}
			</Modal>
		</div>
	)
}
