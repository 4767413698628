import DesignTokens from '@dnb-dcp/design-tokens'
import {
	ColorBlueBrand,
	ColorBlueBrandDark,
	ColorBlueBrandLight,
	ColorBlueBrandLighter,
	ColorBlueSky,
	ColorBlueSkyLight,
	ColorBlueSkyMiddle,
	ColorFontBase,
	ColorFontDisabled,
	ColorGray,
	ColorGrayDark,
	ColorGrayDarker,
	ColorGrayLight,
	ColorGrayLighter,
	ColorGrayPowder,
	ColorHan,
	ColorHanLight,
	ColorHanLighter,
	ColorHanMiddle,
	ColorHanPowder,
	ColorIndian,
	ColorJetBlack,
	ColorLilac,
	ColorMulberry,
	ColorOrange,
	ColorOrangeBurnt,
	ColorOrangeBurntPowder,
	ColorOrangeLight,
	ColorOrangeLighter,
	ColorPinkLighter,
	ColorPurple,
	ColorScaleOrange
} from '@dnb-dcp/design-tokens/build/shared/token-colors.json'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
	Category1,
	Category140,
	Category2,
	Category240,
	Category3,
	Category340,
	Category4,
	Category440,
	Category5,
	Category540,
	Category6,
	Category640,
	Category7,
	Category740,
	Category8,
	Category840,
	NotificationsSuccess,
	Uncategory,
	Uncategory3
} from '../../assets/styles/token-colors-chart.json'
import { getLocalizedDateString, getLocalizedTimeString } from '../../helpers'
import { useAccessLevel, useFeatures, useModules, usePlatform } from '../../hooks/useEntitlements'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import {
	GeographicalDistributionMap,
	Icon,
	IconType,
	IDistributionData,
	PieChart,
	ToolTipInfo
} from '../../local-core-ui'
import { ConnectionStatus } from '../../queries/api/getC4SActiveConnection'
import { useDataReport } from '../../queries/useDataReport'
import { useMatchSummaryCodes } from '../../queries/useMatchSummaryCodes'
import { useProject } from '../../queries/useProject'
import { useSource } from '../../queries/useSource'
import { RootState, useAppSelector } from '../../store'
import { ContactMatchOverview } from '../contact-match-overview/contact-match-overview'
import { ConfidenceData, DunsMatchOverview } from '../duns-match-overview/duns-match-overview'
import { EmailVerificationOverview } from '../emailVerificationOverview/email-verification-overview'
import { InputPresence } from '../input-presence/input-presence'
import { LoadingProjectStatus } from '../loading-states/loading-project-status'
import { MatchRecommendationCharts } from '../match-recommendation-charts/match-recommendation-charts'
import { Snackbar, SnackType } from '../snackbar/snackbar'
import { SourceSettingsOnDemand } from '../source-settings-on-demand/source-settings-on-demand'
import { Tile } from '../tile/tile'
import styles from './dashboard-component.module.scss'

export interface EmailVerification {
	count: number
	description: string
	rate: number
	total?: number
}

interface DashboardComponentProps {
	primaryColor?: string
	secondaryColor?: string
	primaryScssColor?: string
	tertiaryScssColor?: string
	colorToken?: string
	level?: 'Tenant' | 'Project' | 'Source' | 'Upload'
	ownerId?: string
	projectId?: string
	mode?: 'tiles' | 'panels'
	hasUploads: boolean
	hasErrors: boolean
	testId: string
	showContactGraphs?: boolean
	EVforCurrentSrc?: boolean
}

interface TileOrPanelProps {
	className?: string
	mode?: 'tiles' | 'panels'
	children?: ReactNode
	testId?: string
}

export const TileOrPanel: FC<TileOrPanelProps> = ({ className, mode, children, testId }: TileOrPanelProps) => {
	return mode === 'panels' ? (
		<div data-testid={`widget-${testId}`} className={`${className} ${styles.widgetBody}`}>
			{children}
		</div>
	) : (
		<Tile data-testid={`tile-container-${testId}`} className={className}>
			<div data-testid={`tile-children-${testId}`} className={styles.widgetBody}>
				{children}
			</div>
		</Tile>
	)
}

export const DashboardComponent: FC<DashboardComponentProps> = ({
	primaryColor = ColorMulberry,
	secondaryColor = ColorPinkLighter,
	primaryScssColor = '$color-mulberry',
	tertiaryScssColor = '$color-mulberry-lightest',
	colorToken = 'ColorMulberry',
	level = 'Tenant',
	ownerId = 'anystring',
	projectId = 'anystring',
	mode = 'tiles',
	hasUploads,
	hasErrors,
	showContactGraphs,
	EVforCurrentSrc = false,
	testId
}: DashboardComponentProps) => {
	const { t } = useTranslation()
	const isC4S = useModules(['C4S'])
	const [useMockData] = useLocalStorage('useMockData', 'false')
	const selectThirdPartyIntegrations = (state: RootState) => state.thirdPartyIntegrations
	const thirdPartyState = useAppSelector(selectThirdPartyIntegrations)
	const query = useDataReport(level, ownerId, useMockData)
	const projectQuery = useProject(level === 'Project' ? ownerId : '')
	const sourceQuery = useSource(level === 'Source' ? ownerId : '')
	const isC4SPlatform = usePlatform('salesforce')
	const selectConnections = (state: RootState) => state.connection
	const connection = useAppSelector(selectConnections)
	const enableC4SAuthMgmt = useFeatures(['EnableC4SAuthMgmt'])
	const flagEmailVerification = useFeatures(['EnableEmailVerification'])
	const flagEmailVerificationC4S = useFeatures(['EnableEmailVerificationC4S'])
	const enableMatchRecommendation = useFeatures(['EnableMatchRecommendation'])
	const matchCodesQuery = useMatchSummaryCodes(true)
	const history = useHistory()
	const [hasC4SActiveConnection, setHasC4SActiveConnection] = useState(true)
	const isAdmin = useAccessLevel(['INTERNAL_ADMIN', 'EXTERNAL_ADMIN', 'SUPER_ADMIN'])
	const isSalesForce = sourceQuery.data?.integrationInfo?.integrationType === 'Salesforce'
	const isEnabledEmailVerification = isSalesForce
		? flagEmailVerification && flagEmailVerificationC4S
		: flagEmailVerification

	const isShowStateZero = isEnabledEmailVerification && !EVforCurrentSrc

	const chartColorsStateZero = [
		ColorJetBlack,
		ColorGrayDarker,
		ColorGrayDark,
		ColorGray,
		ColorGrayLight,
		ColorGrayLighter,
		ColorGrayPowder
	]

	const chartColorsPie =
		colorToken === 'ColorOrange'
			? [
					ColorOrange,
					ColorOrangeLight,
					ColorOrangeLighter,
					ColorOrangeBurntPowder,
					ColorScaleOrange,
					ColorOrangeBurnt,
					ColorIndian
			  ]
			: colorToken === 'ColorHan'
			? [ColorHan, ColorHanLight, ColorHanLighter, ColorHanPowder, ColorHanMiddle, ColorLilac, ColorPurple]
			: [
					ColorBlueBrand,
					ColorBlueBrandLight,
					ColorBlueBrandLighter,
					ColorBlueBrandDark,
					ColorBlueSky,
					ColorBlueSkyLight,
					ColorBlueSkyMiddle
			  ]

	const chartMRColors = [
		Category1,
		Category2,
		Category3,
		Category4,
		Category5,
		Category6,
		Category7,
		Category8,
		Category140,
		Category240,
		Category340,
		Category440,
		Category540,
		Category640,
		Category740,
		Category840,
		Uncategory,
		NotificationsSuccess,
		Uncategory3
	]

	const inputsInfo = {
		regularInputs: [
			{
				fieldName: 'CompanyName',
				typeIcon: 'business' as IconType,
				keyLabel: 'input.presence.business.name'
			},
			{
				fieldName: 'RegistrationNumber',
				typeIcon: 'fingerprint' as IconType,
				keyLabel: 'input.presence.nationalID'
			},
			{
				fieldName: 'DUNS',
				typeIcon: 'fingerprint' as IconType,
				keyLabel: 'input.presence.DUNS'
			},
			{
				fieldName: 'Country',
				typeIcon: 'globe' as IconType,
				keyLabel: 'input.presence.country'
			},
			{
				fieldName: 'State',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.state'
			},
			{
				fieldName: 'City',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.city'
			},
			{
				fieldName: 'Address_Street_1',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.address.line.1'
			},
			{
				fieldName: 'Address_Street_2',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.address.line.2'
			},
			{
				fieldName: 'Address_Street_3',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.address.line.3'
			},
			{
				fieldName: 'Address_Street_4',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.address.line.4'
			},
			{
				fieldName: 'PostalCode',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.postalCode'
			},
			{
				fieldName: 'Email',
				typeIcon: 'email' as IconType,
				keyLabel: 'input.presence.email'
			},
			{
				fieldName: 'Website',
				typeIcon: 'www' as IconType,
				keyLabel: 'input.presence.website'
			},
			{
				fieldName: 'PhoneNumber',
				typeIcon: 'phone' as IconType,
				keyLabel: 'input.presence.phone'
			},
			{
				fieldName: 'FirstName',
				typeIcon: 'avatar' as IconType,
				keyLabel: 'input.presence.firstName'
			},
			{
				fieldName: 'LastName',
				typeIcon: 'avatar' as IconType,
				keyLabel: 'input.presence.lastName'
			},
			{
				fieldName: 'ContactId',
				typeIcon: 'fingerprint' as IconType,
				keyLabel: 'input.presence.contactID'
			},
			{
				fieldName: 'GlobalContactKey',
				typeIcon: 'fingerprint' as IconType,
				keyLabel: 'input.presence.gck'
			}
		],
		additionalInputs: [
			{
				fieldName: 'Alternate_CompanyName',
				typeIcon: 'business' as IconType,
				keyLabel: 'input.presence.add.business.name'
			},
			{
				fieldName: 'Alternate_Country',
				typeIcon: 'globe' as IconType,
				keyLabel: 'input.presence.add.country'
			},
			{
				fieldName: 'Alternate_Address_Street_1',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.address.line.1'
			},
			{
				fieldName: 'Alternate_Address_Street_2',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.address.line.2'
			},
			{
				fieldName: 'Alternate_Address_Street_3',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.address.line.3'
			},
			{
				fieldName: 'add-address4',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.address.line.4'
			},
			{
				fieldName: 'Alternate_City',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.city'
			},
			{
				fieldName: 'Alternate_State',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.state'
			},
			{
				fieldName: 'Alternate_PostalCode',
				typeIcon: 'location' as IconType,
				keyLabel: 'input.presence.add.postalCode'
			},
			{
				fieldName: 'Alternate_RegistrationNumber',
				typeIcon: 'fingerprint' as IconType,
				keyLabel: 'input.presence.add.regNum'
			}
		]
	}

	const errorInDuplicationReport = useMemo(() => {
		return !(
			query.data?.duplicationReport &&
			query.data.duplicationReport.uniqueRecords !== undefined &&
			query.data.duplicationReport.duplicateRecords !== undefined
		)
	}, [query.data])

	const chartData = useMemo(() => {
		const cd = Object()
		cd[t('dashboard.unique')] = query.data?.duplicationReport?.uniqueRecords
		cd[t('dashboard.duplicates')] = query.data?.duplicationReport?.duplicateRecords
		return cd
	}, [query.data, t])

	const errorChartData = useMemo(() => {
		const cd = Object()
		cd[t('dashboard.unique.error')] = 0
		cd[t('dashboard.duplicates.error')] = 0
		return cd
	}, [query.data, t])

	const duplicationChartTooltip = useMemo(() => {
		const tooltipInfo: ToolTipInfo = {
			effect: 'solid',
			position: 'top',
			title: t('dashboard.match.records') as string,
			values: [
				{
					label: t('dashboard.total'),
					value: new Intl.NumberFormat().format(
						(query.data?.duplicationReport?.uniqueRecords || 0) +
							(query.data?.duplicationReport?.duplicateRecords || 0)
					)
				},
				{
					label: t('dashboard.duplicates'),
					value: new Intl.NumberFormat().format(query.data?.duplicationReport?.duplicateRecords || 0)
				}
			],
			testId: testId + '-dup-chart-tooltip'
		}
		return tooltipInfo
	}, [query.data, testId, t])

	const geoData = useMemo(() => {
		return query.data?.geoDistributionReport?.geoDistributionList
			.map((country) => {
				return {
					countryCode: country.geoCode,
					matched: country.count
				} as IDistributionData
			})
			.filter((entry) => entry.countryCode !== '' || entry.matched !== 0)
	}, [query.data])

	const emptyMap = (geoData: Array<IDistributionData> | undefined) => {
		return geoData === undefined || geoData.length === 0
	}

	const confidenceData = useMemo(() => {
		const cd = Array<ConfidenceData>(11)
		cd.fill({ rate: 0, value: 0 })
		query.data?.matchToDunsReport?.confidenceRateList.forEach(
			(c) => (cd[c.confidenceCode] = { rate: Math.round(c.rate), value: c.count })
		)
		const differenceWithHundred = cd.reduce(
			(prevRemaining, confidenceData) => prevRemaining - confidenceData.rate,
			100
		)

		if (differenceWithHundred > 0) {
			const smallestRate = query.data?.matchToDunsReport?.confidenceRateList.find(
				(confidenceData) => confidenceData.rate > 0 && Math.round(confidenceData.rate) === 0
			)
			if (smallestRate) cd[smallestRate.confidenceCode].rate += differenceWithHundred
		} else if (differenceWithHundred < 0) {
			const biggestRate = query.data?.matchToDunsReport?.confidenceRateList
				.sort((a, b) => b.rate - a.rate)
				.find((confidenceData) => Math.round(confidenceData.rate) > confidenceData.rate)
			if (biggestRate) cd[biggestRate.confidenceCode].rate += differenceWithHundred
		}
		return cd
	}, [query.data])

	const mediumConfidenceMinValue = 5
	const highConfidenceMinValue = 8

	const chartColors = Object()
	chartColors[t('dashboard.unique')] = primaryColor
	chartColors[t('dashboard.duplicates')] = secondaryColor

	const errorChartColors = Object()
	errorChartColors[t('dashboard.unique.error')] = ColorFontBase
	errorChartColors[t('dashboard.duplicates.error')] = ColorFontBase

	const hasStats = query.data && query.data.basicStats

	const getBusyText = useCallback(() => {
		if (level === 'Project') {
			if (projectQuery.isSuccess) {
				if (projectQuery.data?.sources.length === 0) {
					// Incomplete project setup: missing sources
					return t('dashboard.project.incomplete')
				} else if (
					!hasUploads &&
					!thirdPartyState.finishedProjects.includes(ownerId) &&
					!thirdPartyState.finishedSources.includes(ownerId) &&
					projectQuery.data?.sources.find((source) => source.isApi) === undefined
				) {
					// Incomplete project setup: has sources, but missing uploads and source is not APIBased
					// (C4S takes longer to show valid uploads, for this scenario we use the thirdPartyState instead)
					return t('dashboard.project.incomplete')
				} else if (hasErrors) {
					// Incomplete project setup: has sources, but missing uploads
					return t('dashboard.file.failed')
				}
			}
		} else if (level === 'Source') {
			if (sourceQuery.isSuccess) {
				if (sourceQuery.data?.isFile) {
					if (
						!hasUploads &&
						!thirdPartyState.finishedProjects.includes(ownerId) &&
						!thirdPartyState.finishedSources.includes(ownerId)
					) {
						// Incomplete project setup: has sources, but missing uploads and source is not APIBased
						// (C4S takes longer to show valid uploads, for this scenario we use the thirdPartyState instead)
						return t('dashboard.project.incomplete')
					} else if (hasErrors) {
						// Incomplete project setup: has sources, but missing uploads
						return t('dashboard.file.failed')
					}
				}
			}
		}

		return t('dashboard.file.processing')
		/**
		 * We only want to run this callback when level, ownerId, hasUploads, hasErrors or t changes.
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [level, ownerId, hasUploads, hasErrors, t])

	return query.isFetching ? (
		<div className={`${styles.dashboardComponent} ${styles.loading}`}>
			<LoadingProjectStatus />
		</div>
	) : (
		<div className={`${styles.dashboardComponent} ${styles[mode]} ${level.toLowerCase()}`}>
			<div className={styles.dashboardHeader}>
				{isC4S && level === 'Source' ? (
					<div className={styles.sourceSettingsContainer}>
						<SourceSettingsOnDemand sourceId={ownerId} projectId={projectId} />
					</div>
				) : (
					''
				)}
				{query.data && query.data.refreshTimestamp ? (
					<div data-testid="last-refreshed" className={styles.lastUpdated}>
						{t('dashboard.last.refreshed')}
						<span data-testid="the-date" className={styles.theDate}>
							&nbsp;
							{getLocalizedTimeString(query.data.refreshTimestamp)}
							&nbsp;
							{getLocalizedDateString(query.data.refreshTimestamp)}
						</span>
					</div>
				) : undefined}
				{isC4SPlatform &&
					isC4S &&
					enableC4SAuthMgmt &&
					(connection.currentConnectionStatus === ConnectionStatus.Failed ||
						connection.currentConnectionStatus === ConnectionStatus.Unknown) &&
					hasC4SActiveConnection && (
						<div className={styles.snackbarContainer}>
							<Snackbar
								title={t('connection.missing.non.admin')}
								type={SnackType.warning}
								message={t('connection.missing.non.admin.message') || ''}
								onClose={() => setHasC4SActiveConnection(false)}
								linkText={isAdmin ? t('connection.missing.see') || '' : undefined}
								onClickLink={isAdmin ? () => history.push('/connections') : undefined}
								showClose
								isBanner
							/>
						</div>
					)}
			</div>
			<div
				className={`${styles.dashboardComponentTable} ${hasStats ? '' : styles.blurry} ${
					hasErrors && !hasStats ? styles.errors : ''
				}`}
				data-testid="dashboard-component"
			>
				<div className={styles.topRow}>
					<TileOrPanel
						testId="input-presence-container-tile"
						className={`${styles.quadrant} ${styles.inputPresenceContainer}`}
						mode={mode}
					>
						<InputPresence
							submitted={query.data?.basicStats?.totalSubmitted || 0}
							absorbed={query.data?.basicStats?.successCnt || 0}
							inputPresenceList={query.data?.inputPresenceReport?.inputPresenceList || []}
							primaryScssColor={primaryScssColor}
							tertiaryScssColor={tertiaryScssColor}
							testId={testId + '-ip'}
							additionalInputs={inputsInfo.additionalInputs}
							regularInputs={inputsInfo.regularInputs}
							textToZero={t('bar.graph.text.value.zero') as string}
							showZero={false}
						/>
					</TileOrPanel>
					<TileOrPanel
						testId="geo-map-container-tile"
						className={`${styles.quadrant} ${styles.geoDistMapContainer}`}
						mode={mode}
					>
						<div className={styles.header}>
							<div className={styles.title}>{t('dashboard.geographical.distribution')}</div>
							<div className={styles.subtitle}>{t('dashboard.geographical.distribution.subtitle')}</div>
						</div>
						<div data-testid="map-container" className={styles.mapContainer}>
							<GeographicalDistributionMap
								data={geoData}
								translationFunction={t}
								color={colorToken}
								testId={testId + '-gdm'}
								zeroColor={
									emptyMap(geoData) ? DesignTokens.TokenColors.ColorMulberryLightest : undefined
								}
							/>
						</div>
					</TileOrPanel>
				</div>
				<div className={styles.bottomRow}>
					<TileOrPanel
						testId="duns-match-overview-container-tile"
						className={`${styles.quadrant} ${styles.dunsMatchOverviewContainer}`}
						mode={mode}
					>
						<DunsMatchOverview
							matchedCount={query.data?.matchToDunsReport?.matched || 0}
							unmatchedCount={query.data?.matchToDunsReport?.unmatched || 0}
							confidenceData={confidenceData}
							mediumConfidenceMinValue={mediumConfidenceMinValue}
							highConfidenceMinValue={highConfidenceMinValue}
							primaryColor={primaryColor}
							secondaryColor={secondaryColor}
							primaryScssColor={primaryScssColor}
							tertiaryScssColor={tertiaryScssColor}
							testId={testId + '-dmo'}
						/>
					</TileOrPanel>
					{errorInDuplicationReport ? (
						<TileOrPanel
							testId="pie-chart-container-tile-first"
							className={`${styles.quadrant} ${styles.pieChartContainer}`}
							mode={mode}
						>
							<div className={styles.header}>
								<span className={styles.title}>{t('dashboard.duplicate.vs.unique.businesses')}</span>
								<div className={styles.subtitle}>{t('dashboard.duplicate.vs.unique.subtitle')}</div>
							</div>
							<span data-testid="pie-chart-span" className={styles.pieChartSpan}>
								<PieChart
									colors={errorChartColors}
									data={errorChartData}
									diameter={125}
									describeWith="percentage"
									showLines={false}
									showStrokes={false}
									tooltipInfo={duplicationChartTooltip}
									zeroStateColor={ColorFontDisabled}
								/>
							</span>
							<div className={styles.errorContainer}>
								<div className={styles.iconCont}>
									<Icon
										testId="alert-big-dashboard-compo"
										type="alert-big"
										size="mini"
										color={ColorOrangeBurnt}
									/>
								</div>
								<span className={styles.subtitle}>{t('dashboard.error.not-loading.updated')}</span>
							</div>
						</TileOrPanel>
					) : (
						<TileOrPanel
							testId="pie-chart-container-tile-second"
							className={`${styles.quadrant} ${styles.pieChartContainer}`}
							mode={mode}
						>
							<div className={styles.header}>
								<span className={styles.title}>{t('dashboard.duplicate.vs.unique.businesses')}</span>
								<div className={styles.subtitle}>{t('dashboard.duplicate.vs.unique.subtitle')}</div>
							</div>
							<span data-testid="error-pie-chart-span" className={styles.pieChartSpan}>
								<PieChart
									colors={chartColors}
									data={chartData}
									diameter={125}
									describeWith="percentage"
									showLines={false}
									showStrokes={true}
									tooltipInfo={duplicationChartTooltip}
								/>
							</span>
						</TileOrPanel>
					)}
				</div>
				{enableMatchRecommendation &&
					((query.data?.matchSummaryCodeReport?.summaryCodeList &&
						query.data?.matchSummaryCodeReport?.summaryCodeList.length > 0) ||
						(query.data?.stewardshipValueReport?.stewardshipValueList &&
							query.data?.stewardshipValueReport?.stewardshipValueList.length > 0)) && (
						<div className={level !== 'Tenant' ? styles.bottomRow : ''}>
							<MatchRecommendationCharts
								matchCodes={matchCodesQuery.data || []}
								dataReport={query.data}
								colorsPie={chartColorsPie}
								colorsMR={chartMRColors}
								mode={mode}
								testId={testId + '-section'}
							/>
						</div>
					)}
				{level !== 'Tenant' && showContactGraphs && (
					<>
						<div className={`${styles.bottomRow} ${styles.divisorTop}`} data-testid={'container-cmo'}>
							<TileOrPanel testId="contact-container-tile" className={`${styles.quadrant}`} mode={mode}>
								<ContactMatchOverview
									matchedCount={query.data?.matchToContactReport?.matched || 0}
									unmatchedCount={query.data?.matchToContactReport?.unmatched || 0}
									matchDrivers={query.data?.matchToContactReport?.matchDrivers || []}
									primaryColor={primaryColor}
									secondaryColor={secondaryColor}
									colors={chartColorsPie}
									testId={testId + '-cmo'}
								/>
							</TileOrPanel>
							<TileOrPanel
								testId="pie-chart-container-tile-third"
								className={`${styles.quadrant} ${styles.pieChartContainer}`}
								mode={mode}
							></TileOrPanel>
						</div>
						{isEnabledEmailVerification &&
							(isShowStateZero ||
								(!isShowStateZero &&
									(query.data?.emailCategoryReport?.emailCategoryList ||
										query.data?.emailQualityReport?.emailQualityList ||
										query.data?.emailTypeReport?.emailTypeList))) && (
								<div className={`${styles.bottomRow}`} data-testid={'container-cev'}>
									<TileOrPanel className={`${styles.quadrant}`} mode={mode}>
										<EmailVerificationOverview
											colors={isShowStateZero ? chartColorsStateZero : chartColorsPie}
											emailCategoryData={
												query.data?.emailCategoryReport?.emailCategoryList
													? query.data?.emailCategoryReport
													: undefined
											}
											emailQualityList={query.data?.emailQualityReport?.emailQualityList || []}
											emailTypeList={query.data?.emailTypeReport?.emailTypeList || []}
											testId={testId + '-ev'}
											showStateZero={isShowStateZero}
										/>
									</TileOrPanel>
								</div>
							)}
					</>
				)}
			</div>
			{!hasStats && (
				<div className={styles.busyOverlay}>
					<div className={styles.warningIcon}>
						{hasErrors && <Icon testId="alert-big-warning-dashboard-compo" type="alert-big" />}
					</div>
					<div>{getBusyText()}</div>
				</div>
			)}
		</div>
	)
}
